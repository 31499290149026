import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerIcon } from './Loader.styled';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export const Loader = (props) => {
  return <SpinnerIcon size={props.size} icon={faCircleNotch} spin />;
};

Loader.propTypes = {
  size: PropTypes.string,
};

Loader.defaultProps = {
  size: '1x',
};
