import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../api/users';
import { setToken } from '../../services/auth';
import { setUser } from '../../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Box,
  Chip,
  Title,
  FormBox,
  InnerFormBox,
  TextFieldWrap,
  TextFieldWrapL,
  InputLabel,
  SignUpButton,
} from './SignUpForms.styled';
import './SignUpForms.css';
import { TextField, Form, ErrorText } from '../Register/Register.styled';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ConfirmEmailAddress } from './ConfirmEmailAddress';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

export const RegisterForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formSent, setFormSent] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [registeredEmail, setRegisteredEmail] = useState('');

  const EmailRegistration = () => {
    setOpenBackdrop(false);
    setFormSent(!formSent);
  };

  const [error, setError] = useState({
    firstName: [],
    lastName: [],
    username: [],
    email: [],
    password1: [],
    password2: [],
    businessName: [],
    nonFieldErrors: [],
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);

    try {
      const userToken = await registerUser({
        params: {
          firstName: event.target.firstName.value,
          lastName: event.target.lastName.value,
          username: event.target.username.value,
          email: event.target.email.value,
          password1: event.target.password.value,
          password2: event.target.password_confirmation.value,
          businessName: event.target.businessName.value,
        },
      });
      await setToken(userToken.key);
      setRegisteredEmail(event.target.email.value);
      await setTimeout(EmailRegistration, 4000);
    } catch (err) {
      setOpenBackdrop(false);
      if (err.response.status === 400) {
        const errData = err.response.data;
        setError({
          firstName: errData.firstName || [],
          lastName: errData.lastName || [],
          username: errData.username || [],
          email: errData.email || [],
          password1: errData.password1 || [],
          password2: errData.password2 || [],
          businessName: errData.businessName || [],
          nonFieldErrors: errData.nonFieldErrors || [],
        });
        return;
      }
      console.error(error);
    }
    dispatch(setUser({ token: 'xxx' }));
  };

  return (
    <>
      {!formSent ? (
        <Container>
          <Box>
            <Chip>{t('RegisterForm.chip.signUp')}</Chip>
            <Title>{t('RegisterForm.title')}</Title>
            <Form onSubmit={onSubmit}>
              <FormBox>
                <InnerFormBox>
                  <InputLabel>{t('User.firstName.label')}</InputLabel>
                  <TextFieldWrap>
                    <TextField
                      error={error.firstName.length}
                      helperText={error.firstName[0]}
                      type="text"
                      placeholder={t('User.firstName.label')}
                      name="firstName"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  </TextFieldWrap>
                </InnerFormBox>
                <InnerFormBox>
                  <InputLabel>{t('User.lastName.label')}</InputLabel>
                  <TextFieldWrap>
                    <TextField
                      error={error.lastName.length}
                      helperText={error.lastName[0]}
                      type="text"
                      placeholder={t('User.lastName.label')}
                      name="lastName"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  </TextFieldWrap>
                </InnerFormBox>
              </FormBox>

              <FormBox>
                <InnerFormBox>
                  <InputLabel>{t('User.email.label')}</InputLabel>
                  <TextFieldWrapL>
                    <TextField
                      error={error.email.length}
                      helperText={error.email[0]}
                      type="email"
                      placeholder={t('User.email.label')}
                      name="email"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  </TextFieldWrapL>
                </InnerFormBox>
              </FormBox>
              <FormBox>
                <InnerFormBox>
                  <InputLabel>{t('RegisterForm.businessName')}</InputLabel>
                  <TextFieldWrapL>
                    <TextField
                      error={error.businessName.length}
                      helperText={error.businessName[0]}
                      type="text"
                      placeholder={t('RegisterForm.businessName')}
                      name="businessName"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  </TextFieldWrapL>
                </InnerFormBox>
              </FormBox>

              <FormBox>
                <InnerFormBox>
                  <InputLabel>{t('User.username.label')}</InputLabel>
                  <TextFieldWrapL>
                    <TextField
                      error={error.username.length}
                      helperText={error.username[0]}
                      type="text"
                      placeholder={t('User.username.label')}
                      name="username"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  </TextFieldWrapL>
                </InnerFormBox>
              </FormBox>

              <FormBox>
                <InnerFormBox>
                  <InputLabel>{t('User.password.label')}</InputLabel>
                  <TextFieldWrap>
                    <TextField
                      error={error.password1.length}
                      helperText={error.password1[0]}
                      type="password"
                      placeholder={t('User.password.label')}
                      name="password"
                      variant="outlined"
                      required
                    />
                  </TextFieldWrap>
                </InnerFormBox>

                <InnerFormBox>
                  <InputLabel>{t('User.confirm_password.label')}</InputLabel>
                  <TextFieldWrap>
                    <TextField
                      error={error.password2.length}
                      helperText={error.password2[0]}
                      type="password"
                      placeholder={t('User.confirm_password.label')}
                      name="password_confirmation"
                      variant="outlined"
                      required
                    />
                  </TextFieldWrap>
                </InnerFormBox>
              </FormBox>
              <FormBox>
                <FormControlLabel
                  control={
                    <>
                      <Checkbox color="primary" required />
                      <span>
                        {t('RegisterForm.agree.partA')}&nbsp;
                        <a
                          href="https://shapya.com/terms/"
                          target="blank"
                          className="agreeTermsAnchor">
                          {t('RegisterForm.agree.partB')}
                        </a>
                      </span>
                    </>
                  }
                />
              </FormBox>
              {error.nonFieldErrors.length > 0 && (
                <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
              )}
              <br />
              <br />
              <SignUpButton>{t('RegisterForm.button.register')}</SignUpButton>
            </Form>
            <p className="alreadyAccount">
              {t('RegisterForm.alreadyAccount.text')}&nbsp;
              <a href="/" className="loginAnchor">
                {t('RegisterForm.alreadyAccount.login')}
              </a>
            </p>
          </Box>
        </Container>
      ) : null}
      {formSent ? (
        <ConfirmEmailAddress registeredEmail={registeredEmail} />
      ) : null}
      {openBackdrop ? (
        <Backdrop open className="backdropSignUp">
          <CircularProgress color="primary" size={60} thickness={7} />
        </Backdrop>
      ) : null}
    </>
  );
};
