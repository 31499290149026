import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './OrderConfirmCancel.styled';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  Divider,
  CircularProgress,
  LinearProgress,
  Grid,
} from '@material-ui/core';
import { cancelOrderRestaurant } from '../../api/orderProducts';
import { getToken } from '../../services/auth';
import { confirmOrderRestaurant } from '../../api/orderProducts';
import {
  AcceptOrderButton,
  ConfirmOrderCancelButton,
  ConfirmOrderAcceptButton,
  CancelOrderButton,
  CancelOrderCancelButton,
  CancelOrderAcceptButton,
} from './OrderConfirmCancel.styled';

export const OrderConfirmCancel = (props) => {
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openResConfirm, setOpenResConfirm] = useState(false);

  const [openCancel, setOpenCancel] = useState(false);
  const [openResCancel, setOpenResCancel] = useState(false);
  const [openCancelled, setOpenCancelled] = useState(false);

  const [openConfirmed, setOpenConfirmed] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const storeId = useSelector((state) => state.store.id);
  const [observationOrder, setObservationOrder] = useState('');

  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClickOpenResConfirm = () => {
    setOpenResConfirm(true);
  };

  const handleCloseResConfirm = () => {
    setOpenResConfirm(false);
  };

  const handleOpenConfirmed = () => {
    setOpenConfirmed(true);
  };

  const handleCloseConfirmed = () => {
    setOpenConfirmed(false);
  };

  const orderConfirmButtonRef = useRef();
  const orderConfirmText = useRef('');

  const handleConfirmOrder = async () => {
    orderConfirmButtonRef.current.disabled = 'true';
    orderConfirmButtonRef.current.style.opacity = '0.5';
    orderCancelButtonRef.current.disabled = 'true';
    orderCancelButtonRef.current.style.opacity = '0.5';
    confirmOrderRestaurant({
      id: storeId,
      token: getToken(),
      idOrderRest: props.id,
    });
    setOpenConfirm(false);
    handleClickOpenResConfirm();
    setTimeout(function () {
      handleCloseResConfirm();
    }, 4000);
    setTimeout(function () {
      handleOpenConfirmed();
    }, 4000);
    setTimeout(function () {
      handleCloseConfirmed();
      orderConfirmText.current.innerHTML = `${t('OrderConfirm.order')} <b>${
        props.reference
      }</b> ${t('OrderConfirm.successfullyAccepted')}`;
    }, 9000);
    setTimeout(function () {
      window.location.reload(false);
    }, 10000);
  };

  const handleChangeCancel = (event) => {
    setObservationOrder(event.target.value);
  };
  const handleClickOpenCancel = () => {
    setOpenCancel(true);
  };
  const handleCloseCancel = () => {
    setOpenCancel(false);
  };
  const handleClickOpenResCancel = () => {
    setOpenResCancel(true);
  };
  const handleCloseResCancel = () => {
    setOpenResCancel(false);
  };
  const handleOpenCancelled = () => {
    setOpenCancelled(true);
  };
  const handleCloseCancelled = () => {
    setOpenCancelled(false);
  };

  const orderCancelButtonRef = useRef();
  const orderCancelText = useRef('');

  const handleCancelOrder = async () => {
    await cancelOrderRestaurant({
      id: storeId,
      token: getToken(),
      idOrderRest: props.id,
      params: {
        observation: observationOrder,
      },
    });
    orderCancelButtonRef.current.disabled = 'true';
    orderCancelButtonRef.current.style.opacity = '0.5';
    orderConfirmButtonRef.current.disabled = 'true';
    orderConfirmButtonRef.current.style.opacity = '0.5';
    setOpenCancel(false);
    handleClickOpenResCancel();
    setTimeout(function () {
      handleCloseResCancel();
    }, 4000);
    setTimeout(function () {
      handleOpenCancelled();
    }, 4000);
    setTimeout(function () {
      handleCloseCancelled();
      orderCancelText.current.innerHTML = `${t('OrderCancel.order')} <b>${
        props.reference
      }</b> ${t('OrderCancel.successfullyCanceled')}`;
    }, 9000);
    setTimeout(function () {
      window.location.reload(false);
    }, 10000);
  };

  return (
    <div>
      <div className="orderConfirmCancelClass">
        <div className={classes.divCancelOrderButton}>
          <AcceptOrderButton
            onClick={handleClickOpenConfirm}
            ref={orderConfirmButtonRef}>
            {t('OrderConfirm.ConfirmOrderButton')}
          </AcceptOrderButton>
          <CancelOrderButton
            onClick={handleClickOpenCancel}
            ref={orderCancelButtonRef}>
            {t('OrderCancel.CancelOrderButton')}
          </CancelOrderButton>
        </div>

        <div
          ref={orderConfirmText}
          className={classes.orderConfirmTextDiv}></div>
        <Dialog
          fullScreen={fullScreen}
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle
            id="responsive-dialog-title"
            className={classes.orderConfirmDialogTitle}>
            {t('OrderConfirm.ConfirmOrderQuestion')}
            <b>{props.reference}</b>
            {'?'}
          </DialogTitle>
          <Divider />
          <DialogActions className={classes.orderConfirmDialogActions}>
            <ConfirmOrderCancelButton autoFocus onClick={handleCloseConfirm}>
              {t('OrderConfirm.CancelButton')}
            </ConfirmOrderCancelButton>
            <ConfirmOrderAcceptButton
              onClick={handleConfirmOrder}
              className={classes.cancelOrderAcceptButton}>
              {t('OrderConfirm.ConfirmButton')}
            </ConfirmOrderAcceptButton>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={openResConfirm}
          onClose={handleCloseResConfirm}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
          align="center">
          <DialogTitle id="responsive-dialog-title">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <CircularProgress className={classes.circularProgress} />
            </Grid>
            {t('OrderConfirm.AcceptingOrder')}
          </DialogTitle>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={openConfirmed}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
          align="center">
          <DialogTitle id="responsive-dialog-title">
            <p className={classes.dialogTitleLineOne}>
              {t('OrderConfirm.order')} <b>{props.reference}</b>
            </p>
            <p className={classes.dialogTitleLineTwo}>
              {t('OrderConfirm.successfullyAccepted')}
            </p>
            <Divider />
            <p className={classes.dialogTitleLineThree}>
              {t('OrderConfirm.redirected')}
            </p>
            <LinearProgress />
          </DialogTitle>
        </Dialog>

        <div ref={orderCancelText} className={classes.orderCancelTextDiv}></div>
        <Dialog
          fullScreen={fullScreen}
          open={openCancel}
          onClose={handleCloseCancel}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">
            {t('OrderCancel.dialog.title')}
            <b>{props.reference}</b>
            {'?'}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <div className={classes.orderCancelObservationTitle}>
                {t('OrderCancel.dialog.observation')}
              </div>
              <div className={classes.dialogContentDiv}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t('OrderCancel.dialog.selectLabel')}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={observationOrder}
                    label="Observation"
                    onChange={handleChangeCancel}>
                    <MenuItem
                      value={`${t('OrderCancel.dialog.selectNoIngredients')}`}>
                      {t('OrderCancel.dialog.selectNoIngredients')}
                    </MenuItem>
                    <MenuItem
                      value={`${t('OrderCancel.dialog.selectNotAttending')}`}>
                      {t('OrderCancel.dialog.selectNotAttending')}
                    </MenuItem>
                    <MenuItem
                      value={`${t('OrderCancel.dialog.selectNoDeliveries')}`}>
                      {t('OrderCancel.dialog.selectNoDeliveries')}
                    </MenuItem>
                    <MenuItem
                      value={`${t(
                        'OrderCancel.dialog.selectNotDishAvailable',
                      )}`}>
                      {t('OrderCancel.dialog.selectNotDishAvailable')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <CancelOrderCancelButton onClick={handleCloseCancel}>
              {t('OrderCancel.dialog.CancelButton')}
            </CancelOrderCancelButton>
            <CancelOrderAcceptButton onClick={handleCancelOrder}>
              {t('OrderCancel.dialog.AcceptButton')}
            </CancelOrderAcceptButton>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={openResCancel}
          onClose={handleCloseResCancel}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
          align="center">
          <DialogTitle id="responsive-dialog-title">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <CircularProgress className={classes.circularProgress} />
            </Grid>
            {t('OrderCancel.CancelingOrder')}
          </DialogTitle>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={openCancelled}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          aria-labelledby="responsive-dialog-title"
          align="center">
          <DialogTitle id="responsive-dialog-title">
            <p className={classes.dialogTitleLineOne}>
              {t('OrderCancel.order')} <b>{props.reference}</b>
            </p>
            <p className={classes.dialogTitleLineTwo}>
              {t('OrderCancel.successfullyCanceled')}
            </p>
            <Divider />
            <p className={classes.dialogTitleLineThree}>
              {t('OrderCancel.redirected')}
            </p>
            <LinearProgress />
          </DialogTitle>
        </Dialog>
      </div>
    </div>
  );
};

OrderConfirmCancel.propTypes = {
  reference: PropTypes.string,
  id: PropTypes.number,
};

OrderConfirmCancel.defaultProps = {
  reference: '',
};
