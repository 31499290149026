import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { InfoInventoryTable } from '../../components/Inventory/InfoInventoryTable';
import {
  InventoryWrapper,
  TableWrapper,
  Title,
  Divider,
} from './Inventory.styled';
import {
  getLastInventory,
  getSearchSkuInventory,
  getSearchProductInventory,
  getSearchCategoryInventory,
} from '../../api/inventories';
import { getToken, deleteToken } from '../../services/auth';
import { setInventories } from '../../features/inventory/inventorySlice';

export const Inventory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const storeId = useSelector((state) => state.store.id);
  const [showInventory, setShowInventory] = useState('all');
  const [productSkuParam, setProductSkuParam] = useState('');
  const [productNameParam, setProductNameParam] = useState('');
  const [categoryIdParam, setCategoryIdParam] = useState('');

  useEffect(() => getInventories(), []);

  const getInventories = async (page = 1) => {
    if (showInventory === 'all') {
      try {
        setLoading(true);
        let params = {
          token: getToken(),
          id: storeId,
        };

        if (page > 1) {
          params = { ...params, page };
        }

        const inventories = await getLastInventory(params);
        await dispatch(setInventories(inventories));
        await setLoading(false);
      } catch (error) {
        await setLoading(false);
        if (error.response.status === 401) {
          deleteToken();
          return;
        }
        console.error(error);
      }
    }

    if (showInventory === 'searchByCategoryId') {
      try {
        setLoading(true);
        let params = {
          token: getToken(),
          id: storeId,
          searchCategoryId: categoryIdParam,
        };

        if (page > 1) {
          params = { ...params, page };
        }

        const inventories = await getSearchCategoryInventory(params);
        await dispatch(setInventories(inventories));
        await setLoading(false);
      } catch (error) {
        await setLoading(false);
        if (error.response.status === 401) {
          deleteToken();
          return;
        }
        console.error(error);
      }
    }

    if (showInventory === 'searchBySku') {
      try {
        setLoading(true);
        let params = {
          token: getToken(),
          id: storeId,
          searchSku: productSkuParam,
        };

        if (page > 1) {
          params = { ...params, page };
        }

        const inventories = await getSearchSkuInventory(params);
        await dispatch(setInventories(inventories));
        await setLoading(false);
      } catch (error) {
        await setLoading(false);
        if (error.response.status === 401) {
          deleteToken();
          return;
        }
        console.error(error);
      }
    }

    if (showInventory === 'searchByProductName') {
      try {
        setLoading(true);
        let params = {
          token: getToken(),
          id: storeId,
          searchWord: productNameParam,
        };

        if (page > 1) {
          params = { ...params, page };
        }

        const inventories = await getSearchProductInventory(params);
        await dispatch(setInventories(inventories));
        await setLoading(false);
      } catch (error) {
        await setLoading(false);
        if (error.response.status === 401) {
          deleteToken();
          return;
        }
        console.error(error);
      }
    }
  };

  return (
    <ContentWrapper>
      <InventoryWrapper>
        <Title>{t('Inventory.title')}</Title>
        <Divider />
        <TableWrapper>
          <InfoInventoryTable
            loading={loading}
            pageChange={getInventories}
            showInventory={showInventory}
            setShowInventory={setShowInventory}
            productSkuParam={productSkuParam}
            setProductSkuParam={setProductSkuParam}
            productNameParam={productNameParam}
            setProductNameParam={setProductNameParam}
            categoryIdParam={categoryIdParam}
            setCategoryIdParam={setCategoryIdParam}
          />
        </TableWrapper>
      </InventoryWrapper>
    </ContentWrapper>
  );
};
