import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Box,
  Chip,
  TitleConfirmEmailAddress,
  SignUpButton,
} from './SignUpForms.styled';
import './SignUpForms.css';
import { Hidden } from '@material-ui/core';

export const ConfirmEmailAddress = (props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box>
        <Chip>{t('RegisterForm.chip.signUp')}</Chip>
        <TitleConfirmEmailAddress>
          {t('RegisterForm.confirmEmailAddress.title')}
        </TitleConfirmEmailAddress>
        <p className="confirmEmailAddressSubtitle">
          {t('RegisterForm.confirmEmailAddress.thanksMessage')}
        </p>
        <p className="confirmEmailAddressDescription">
          {t('RegisterForm.confirmEmailAddress.finishRegisterPartA')}
          <br />
          <b>{props.registeredEmail}</b>&nbsp;
          {t('RegisterForm.confirmEmailAddress.finishRegisterPartB')}
        </p>
        <a href="https://enterprise.shapya.com/">
          <SignUpButton>{t('RegisterForm.alreadyAccount.login')}</SignUpButton>
        </a>
        <p className="confirmEmailAddressNeedHelp">
          <a
            href="https://shapya.com/shapyaSolution/"
            target="blank"
            className="agreeTermsAnchor">
            ShapYa inc. | ShapYa Suite
          </a>
        </p>
        <Hidden xsUp>
          <p className="confirmEmailAddressNeedHelp">
            {t('RegisterForm.confirmEmailAddress.needHelp')}&nbsp;
            <a
              href="https://shapya.com/terms/"
              target="blank"
              className="agreeTermsAnchor">
              {t('RegisterForm.confirmEmailAddress.helpCenter')}
            </a>
          </p>
        </Hidden>
      </Box>
    </Container>
  );
};

ConfirmEmailAddress.propTypes = {
  registeredEmail: PropTypes.string,
};
