import styled from 'styled-components';
import MatetialDivider from '@material-ui/core/Divider';

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 27px;
  text-align: center;
  margin-bottom: 16px;
`;

export const Divider = styled(MatetialDivider)`
  width: 100%;
`;
