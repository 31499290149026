import styled from 'styled-components';
import { breakpoints } from '../../constants';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 32px;
  margin-top: 32px;
  justify-items: center;
  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${breakpoints.laptopL} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
