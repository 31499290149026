import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import {
  Content,
  Figure,
  Image,
  Bottom,
  Title,
  Date,
  ButtonsWrapper,
  Anchor,
  AdminWrapper,
} from './Publication.styled';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import { deletePublication } from '../../features/digital/digitalPublicationsSlice';
import { SmallButton, SmallCancelButton } from '../../constants/base';

export const Publication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deletePublication(props.id));
  };

  return (
    <Content>
      <Figure>
        <Image src={props.image} alt={props.title} />
      </Figure>
      <Bottom>
        <Title>{props.title}</Title>
        <Date>
          {t('Digital.puplication.date', {
            date: dayjs(new window.Date(props.createdAt)).format(
              'MMMM DD - YYYY',
            ),
            hour: dayjs(new window.Date(props.createdAt)).format('HH:MM'),
          })}
        </Date>
        <ButtonsWrapper>
          <Anchor href={props.links.facebook} target="_blank">
            <Facebook />
          </Anchor>
          <Anchor href={props.links.instagram} target="_blank">
            <Instagram />
          </Anchor>
        </ButtonsWrapper>
      </Bottom>
      {isAdmin && (
        <AdminWrapper>
          <SmallCancelButton onClick={handleDelete}>
            {t('Digital.puplication.admin.delete')}
          </SmallCancelButton>
          <SmallButton onClick={(event) => props.handleEdit(event, props)}>
            {t('Digital.puplication.admin.edit')}
          </SmallButton>
        </AdminWrapper>
      )}
    </Content>
  );
};

Publication.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  image: PropTypes.string,
  createdAt: PropTypes.string,
  links: PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
};
