import React from 'react';
import { useTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './DeliveryTableTop.styled';

export const DeliveryTableTop = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Toolbar>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div">
        {t('Delivery.sales.title')}
      </Typography>
    </Toolbar>
  );
};
