import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Title = styled.h2`
  font-size: 27px;
  text-align: center;
  margin-bottom: 16px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const LoaderText = styled.p`
  font-size: 1rem;
  margin-left: 12px;
`;

export const NoOrderTitle = styled.h2`
  font-size: 27px;
  text-align: center;
  margin-bottom: 16px;
  color: #c43c42;
`;

export const useStyles = makeStyles((theme) => ({
  divOrderConfirmCancelButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
  productsMainBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  cardProductDiv: {
    borderRadius: '8px',
    border: 'solid 1px lightgray',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 25px 15px 10px',
    marginTop: '8px',
    marginBottom: '8px',
    maxWidth: '1200px',
    '@media (max-width: 1199px)': {
      flexFlow: 'column',
      paddingLeft: '25px',
    },
  },
  divProductImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '130px',
    height: '130px',
  },
  productImg: {
    maxWidth: '100px',
    maxHeight: '120px',
  },
  divProductContent: {
    borderRadius: '8px',
    border: 'solid 1px lightgray',
    minHeight: '130px',
    width: '400px',
    fontFamily: 'Montserrat',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    '@media (max-width: 1199px)': {
      width: '100%',
    },
  },
}));
