import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../services/auth';
import { BtnShape, BtnText } from './InvDowload.styled';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Grid, TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

export const SendInvToEmail = ({ onClick }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid direction="column">
      <div>
        <BtnShape onClick={onClick}>
          <SendIcon />
          <BtnText>{t('Inventory.buttonSendToEmail.sendToEmail')}</BtnText>
        </BtnShape>
      </div>
      <Box mt={1}>
        <Chip
          icon={<InfoIcon />}
          label="More info"
          color="primary"
          variant="outlined"
          size="small"
          onClick={handleClickOpen}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('Inventory.sendInventoryFile.dialogInfoTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Inventory.sendInventoryFile.dialogInfoContent')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('Inventory.buttonOk')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

SendInvToEmail.propTypes = {
  onClick: PropTypes.func,
};

export const SendInvEmailing = () => {
  const { t } = useTranslation();
  return (
    <BtnShape disabled>
      <CircularProgress color="secondary" size={20} thickness={7} />
      <BtnText>{t('Inventory.buttonSending')}</BtnText>
    </BtnShape>
  );
};

export const SendInvToEmailModal = (props) => {
  const { t } = useTranslation();
  const [errorLogo, setErrorLogo] = useState('');
  const branchStoreState = useSelector((state) => state.store);
  const [sendingFileToEmail, setSendingFileToEmail] = useState(0);
  const [fileSuccesfullySent, setFileSuccesfullySent] = useState(0);

  const handleFileChange = () => {
    const fileInput = document.querySelector('input[type="file"]');

    if (fileInput.files[0]) {
      if (
        fileInput.files[0].type === 'text/plain' ||
        fileInput.files[0].type === 'text/csv' ||
        fileInput.files[0].type === 'application/vnd.ms-excel' ||
        fileInput.files[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (fileInput.files[0].size <= 1024 * 1024) {
          setErrorLogo('');
        } else {
          setErrorLogo(t('Inventory.sendInventoryFile.helperText.fileSize'));
          document.querySelector('input[type="file"]').value = null;
        }
      } else {
        setErrorLogo(t('Inventory.sendInventoryFile.helperText.fileType'));
        document.querySelector('input[type="file"]').value = null;
      }
    } else {
      document.querySelector('input[type="file"]').value = null;
    }
  };

  const onSubmitFile = async (event) => {
    event.preventDefault();
    setSendingFileToEmail(1);

    const fileInput = document.querySelector('input[type="file"]');

    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Token ${getToken()}`);

    let formdata = new FormData();

    if (fileInput.files[0]) {
      if (
        fileInput.files[0].type === 'text/plain' ||
        fileInput.files[0].type === 'text/csv' ||
        fileInput.files[0].type === 'application/vnd.ms-excel' ||
        fileInput.files[0].type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        formdata.append('file', fileInput.files[0]);
      } else {
        alert('no txt,csv,xls');
      }
    }

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/user/branch-stores/${branchStoreState.id}/send-mail-inventory/`,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .then(
          setTimeout(() => {
            setFileSuccesfullySent(1);
          }, 3000),
        )
        .then(
          setTimeout(() => {
            props.handleClose();
          }, 6000),
        )
        .catch((error) => console.log('error', error));
    } catch (err) {
      if (err.response.status === 400) {
        console.log(err.response.data);
        setFileSuccesfullySent(2);
        return;
      }
    }
  };

  return (
    <>
      <form onSubmit={onSubmitFile}>
        <DialogTitle>
          {t('Inventory.sendInventoryFile.dialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{t('Inventory.sendInventoryFile.dialogText')}</b>
          </DialogContentText>
          <br />
          <TextField
            type="file"
            accept=".txt, .csv, .xls, .xlsx"
            placeholder="{t('BusinessForm.uploadYourLogo')}"
            className="uploadYourLogoWrap"
            name="businessLogo"
            variant="outlined"
            onChange={handleFileChange}
            helperText={errorLogo}
            required
          />
          <br />
          <br />
          <DialogActions>
            {sendingFileToEmail === 0 ? (
              <BtnShape onSubmit={onSubmitFile}>
                <SendIcon />
                <BtnText>
                  {t('Inventory.sendInventoryFile.buttonSendFile')}
                </BtnText>
              </BtnShape>
            ) : null}

            {sendingFileToEmail === 1 ? (
              <BtnShape disabled>
                <CircularProgress color="secondary" size={20} thickness={7} />
                <BtnText>{t('Inventory.buttonSending')}</BtnText>
              </BtnShape>
            ) : null}
          </DialogActions>
          {fileSuccesfullySent === 1 ? (
            <p align="right">
              <b>{t('Inventory.sendInventoryFile.fileSuccesfullySent')}</b>
            </p>
          ) : null}
          {fileSuccesfullySent === 2 ? (
            <p align="right">
              <b>{t('Inventory.sendInventoryFile.fileNotSent')}</b>
            </p>
          ) : null}
        </DialogContent>
        <br />
      </form>
    </>
  );
};

SendInvToEmailModal.propTypes = {
  handleClose: PropTypes.func,
};
