import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Subtitle, Button } from '../../constants/base';
import { TextField, Form, Anchor, ErrorText } from './Login.styled';
import { userLogin } from '../../api/users';
import { setToken } from '../../services/auth';

export const Login = () => {
  const { t } = useTranslation();
  const [error, setError] = useState({
    username: [],
    password: [],
    nonFieldErrors: [],
  });

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const userToken = await userLogin({
        params: {
          username: event.target.username.value,
          password: event.target.password.value,
          origin: 'dashboard',
        },
      });
      await setToken(userToken.key);
      await window.location.reload();
    } catch (err) {
      const errData = err.response.data;
      if (err.response.status === 400) {
        setError({
          username: errData.username || [],
          password: errData.password || [],
          nonFieldErrors: errData.nonFieldErrors || [],
        });
        return;
      }
      throw new Error(error);
    }
  };

  return (
    <>
      <Subtitle>{t('Login.title')}</Subtitle>
      <Form onSubmit={onSubmit}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          type="text"
          error={error.username.length}
          helperText={error.username[0]}
          label={t('Login.username.label')}
          placeholder={t('Login.username.placeholder')}
          name="username"
          variant="outlined"
          size="small"
          required
        />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          error={error.password.length}
          helperText={error.password[0]}
          label={t('Login.password.label')}
          placeholder={t('Login.password.placeholder')}
          name="password"
          variant="outlined"
          size="small"
          required
        />
        {error.nonFieldErrors.length > 0 && (
          <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
        )}
        <Anchor to="/password-reset/">{t('Login.password.reset')}</Anchor>
        <Button>{t('Login.cta')}</Button>
      </Form>
    </>
  );
};
