import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  createPublication,
  editPublication,
} from '../../../features/digital/digitalPublicationsSlice';
import { Button, CancelButton } from '../../../constants/base';
import {
  useStyles,
  ModalWrapper,
  Title,
  TextField,
  Form,
  ErrorText,
  ButtonWrapper,
} from './PublicationModal.styled';

export const PublicationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [error, setError] = useState({
    title: [],
    date: [],
    copy: [],
    facebookUrl: [],
    instagramUrl: [],
    image: [],
    nonFieldErrors: [],
  });

  const onSubmit = (event) => {
    event.preventDefault();
    if (props.data.title !== '') {
      dispatch(
        editPublication({
          id: props.data.id,
          title: event.target.title.value,
          image:
            'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/fr/e15/s1080x1080/153618659_930079304198318_2433380970497304062_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=102&_nc_ohc=xB_Hu4mAJIwAX_BQTod&oh=af9a15e09f1bc1137d320dcc60a679b2&oe=607A62A6',
          copy: event.target.copy.value,
          createdAt: event.target.date.value,
          links: {
            facebook: event.target.facebookUrl.value,
            instagram: event.target.instagramUrl.value,
          },
        }),
      );
      props.handleClose(event);
      return;
    }
    dispatch(
      createPublication({
        id: 11,
        title: event.target.title.value,
        image:
          'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/fr/e15/s1080x1080/153618659_930079304198318_2433380970497304062_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=102&_nc_ohc=xB_Hu4mAJIwAX_BQTod&oh=af9a15e09f1bc1137d320dcc60a679b2&oe=607A62A6',
        copy: event.target.copy.value,
        createdAt: event.target.date.value,
        links: {
          facebook: event.target.facebookUrl.value,
          instagram: event.target.instagramUrl.value,
        },
      }),
    );
    props.handleClose(event);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}>
      <Fade in={props.open}>
        <ModalWrapper>
          <Title id="transition-modal-title">
            {props.data.title !== ''
              ? t('InnerDigital.publicationModal.title')
              : t('InnerDigital.publicationModal.edit.title')}
          </Title>
          <Form onSubmit={onSubmit}>
            <TextField
              error={error.title.length}
              helperText={error.title[0]}
              label={t('InnerDigital.publicationModal.form.title.label')}
              type="text"
              placeholder={t('InnerDigital.publicationModal.form.title.label')}
              defaultValue={props.data.title}
              name="title"
            />
            <TextField
              id="date"
              name="date"
              label={t('InnerDigital.publicationModal.form.date.label')}
              type="date"
              defaultValue={
                props.data.createdAt !== ''
                  ? dayjs(new window.Date(props.data.createdAt)).format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              error={error.copy.length}
              helperText={error.copy[0]}
              label={t('InnerDigital.publicationModal.form.copy.label')}
              type="text"
              defaultValue={props.data.copy}
              placeholder={t('InnerDigital.publicationModal.form.copy.label')}
              name="copy"
            />
            <TextField
              error={error.facebookUrl.length}
              helperText={error.facebookUrl[0]}
              label={t('InnerDigital.publicationModal.form.facebookUrl.label')}
              type="text"
              defaultValue={props.data.links.facebook}
              placeholder={t(
                'InnerDigital.publicationModal.form.facebookUrl.label',
              )}
              name="facebookUrl"
            />
            <TextField
              error={error.instagramUrl.length}
              helperText={error.instagramUrl[0]}
              label={t('InnerDigital.publicationModal.form.instagramUrl.label')}
              type="text"
              defaultValue={props.data.links.instagram}
              placeholder={t(
                'InnerDigital.publicationModal.form.instagramUrl.label',
              )}
              name="instagramUrl"
            />
            {error.nonFieldErrors.length > 0 && (
              <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
            )}
            <ButtonWrapper>
              <CancelButton onClick={props.handleClose}>
                {t('InnerDigital.publicationModal.cancel')}
              </CancelButton>
              <Button>{t('InnerDigital.publicationModal.cta')}</Button>
            </ButtonWrapper>
          </Form>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

PublicationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    copy: PropTypes.string,
    createdAt: PropTypes.string,
    links: {
      facebook: PropTypes.string,
      instagram: PropTypes.string,
    },
  }),
};

PublicationModal.defaultProps = {
  open: false,
  data: {
    id: 0,
    title: '',
    image: '',
    copy: '',
    createdAt: '',
    links: {
      facebook: '',
      instagram: '',
    },
  },
};
