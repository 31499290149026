import styled from 'styled-components';
import { breakpoints } from '../../constants';

export const Root = styled.div`
  display: flex;
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 2rem 16px;
  width: calc(100vw - 50px);
  box-sizing: border-box;
  @media ${breakpoints.tablet} {
    width: 100%;
    padding: 2rem;
  }
`;

export const MainContent = styled.div`
  height: 100%;
`;
