import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../api/users';
import { useSelector } from 'react-redux';
import { setToken } from '../../services/auth';
import { setUser } from '../../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import {
  InnerBusinessFormBox,
  TextFieldWrapBusinessForm,
  InputLabel,
} from './SignUpForms.styled';
import './SignUpForms.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';

export const ScheduleForm = () => {
  const { t } = useTranslation();

  const [mondaySelect, setMondaySelect] = useState(false);
  const [tuesdaySelect, setTuesdaySelect] = useState(false);
  const [wednesdaySelect, setWednesdaySelect] = useState(false);
  const [thursdaySelect, setThursdaySelect] = useState(false);
  const [fridaySelect, setFridaySelect] = useState(false);
  const [saturdaySelect, setSaturdaySelect] = useState(false);
  const [sundaySelect, setSundaySelect] = useState(false);

  return (
    <>
      <InnerBusinessFormBox>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <InputLabel style={{ marginRight: '344px' }}>
            {t('BusinessForm.scheduleForm.setYourSchedule')}
          </InputLabel>
          <InputLabel>{t('BusinessForm.scheduleForm.close')}</InputLabel>
        </div>
        <TextFieldWrapBusinessForm>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="dayGrid">
              <b>{t('BusinessForm.scheduleForm.monday')}</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!mondaySelect}
                    disabled={mondaySelect}
                    name="mondayFromHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!mondaySelect}
                    disabled={mondaySelect}
                    name="mondayFromMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <b>to</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!mondaySelect}
                    disabled={mondaySelect}
                    name="mondayToHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="00">00</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!mondaySelect}
                    disabled={mondaySelect}
                    name="mondayToMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={mondaySelect}
                control={<Checkbox name="mondayClosed" color="primary" />}
                onChange={() => setMondaySelect(!mondaySelect)}
              />
            </Grid>
          </Grid>
        </TextFieldWrapBusinessForm>

        <TextFieldWrapBusinessForm>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="dayGrid">
              <b>{t('BusinessForm.scheduleForm.tuesday')}</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!tuesdaySelect}
                    disabled={tuesdaySelect}
                    name="tuesdayFromHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!tuesdaySelect}
                    disabled={tuesdaySelect}
                    name="tuesdayFromMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <b>to</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!tuesdaySelect}
                    disabled={tuesdaySelect}
                    name="tuesdayToHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="00">00</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!tuesdaySelect}
                    disabled={tuesdaySelect}
                    name="tuesdayToMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={tuesdaySelect}
                control={<Checkbox name="tuesdayClosed" color="primary" />}
                onChange={() => setTuesdaySelect(!tuesdaySelect)}
              />
            </Grid>
          </Grid>
        </TextFieldWrapBusinessForm>

        <TextFieldWrapBusinessForm>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="dayGrid">
              <b>{t('BusinessForm.scheduleForm.wednesday')}</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!wednesdaySelect}
                    disabled={wednesdaySelect}
                    name="wednesdayFromHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!wednesdaySelect}
                    disabled={wednesdaySelect}
                    name="wednesdayFromMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <b>to</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!wednesdaySelect}
                    disabled={wednesdaySelect}
                    name="wednesdayToHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="00">00</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!wednesdaySelect}
                    disabled={wednesdaySelect}
                    name="wednesdayToMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={wednesdaySelect}
                control={<Checkbox name="wednesdayClosed" color="primary" />}
                onChange={() => setWednesdaySelect(!wednesdaySelect)}
              />
            </Grid>
          </Grid>
        </TextFieldWrapBusinessForm>

        <TextFieldWrapBusinessForm>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="dayGrid">
              <b>{t('BusinessForm.scheduleForm.thursday')}</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!thursdaySelect}
                    disabled={thursdaySelect}
                    name="thursdayFromHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!thursdaySelect}
                    disabled={thursdaySelect}
                    name="thursdayFromMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <b>to</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!thursdaySelect}
                    disabled={thursdaySelect}
                    name="thursdayToHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="00">00</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!thursdaySelect}
                    disabled={thursdaySelect}
                    name="thursdayToMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={thursdaySelect}
                control={<Checkbox name="thursdayClosed" color="primary" />}
                onChange={() => setThursdaySelect(!thursdaySelect)}
              />
            </Grid>
          </Grid>
        </TextFieldWrapBusinessForm>

        <TextFieldWrapBusinessForm>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="dayGrid">
              <b>{t('BusinessForm.scheduleForm.friday')}</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!fridaySelect}
                    disabled={fridaySelect}
                    name="fridayFromHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!fridaySelect}
                    disabled={fridaySelect}
                    name="fridayFromMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <b>to</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!fridaySelect}
                    disabled={fridaySelect}
                    name="fridayToHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="00">00</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!fridaySelect}
                    disabled={fridaySelect}
                    name="fridayToMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={fridaySelect}
                control={<Checkbox name="fridayClosed" color="primary" />}
                onChange={() => setFridaySelect(!fridaySelect)}
              />
            </Grid>
          </Grid>
        </TextFieldWrapBusinessForm>

        <TextFieldWrapBusinessForm>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="dayGrid">
              <b>{t('BusinessForm.scheduleForm.saturday')}</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!saturdaySelect}
                    disabled={saturdaySelect}
                    name="saturdayFromHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!saturdaySelect}
                    disabled={saturdaySelect}
                    name="saturdayFromMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <b>to</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!saturdaySelect}
                    disabled={saturdaySelect}
                    name="saturdayToHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="00">00</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!saturdaySelect}
                    disabled={saturdaySelect}
                    name="saturdayToMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={saturdaySelect}
                control={<Checkbox name="saturdayClosed" color="primary" />}
                onChange={() => setSaturdaySelect(!saturdaySelect)}
              />
            </Grid>
          </Grid>
        </TextFieldWrapBusinessForm>

        <TextFieldWrapBusinessForm>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="dayGrid">
              <b>{t('BusinessForm.scheduleForm.sunday')}</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!sundaySelect}
                    disabled={sundaySelect}
                    name="sundayFromHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!sundaySelect}
                    disabled={sundaySelect}
                    name="sundayFromMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <b>to</b>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className="categorySelect"
                style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Select
                    required={!sundaySelect}
                    disabled={sundaySelect}
                    name="sundayToHour">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="00">00</MenuItem>
                  </Select>
                  <b>&nbsp;&nbsp;:&nbsp;&nbsp;</b>
                  <Select
                    required={!sundaySelect}
                    disabled={sundaySelect}
                    name="sundayToMinutes">
                    <MenuItem value="Select" disabled>
                      <em>{t('BusinessForm.categorySelect.select')}</em>
                    </MenuItem>
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="35">35</MenuItem>
                    <MenuItem value="40">40</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                    <MenuItem value="50">50</MenuItem>
                    <MenuItem value="55">55</MenuItem>
                  </Select>
                </div>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                value={sundaySelect}
                control={<Checkbox name="sundayClosed" color="primary" />}
                onChange={() => setSundaySelect(!sundaySelect)}
              />
            </Grid>
          </Grid>
        </TextFieldWrapBusinessForm>
      </InnerBusinessFormBox>
    </>
  );
};
