import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const getLang = () => {
  if (navigator.language.includes('en')) {
    return 'en';
  } else if (navigator.language.includes('es')) {
    return 'es';
  }
  return 'en';
};

const translationsConfig = () => {
  const resources = {
    en: {
      translations: require('./translations/en.json'),
    },
    es: {
      translations: require('./translations/es.json'),
    },
  };
  i18n.use(initReactI18next).init({
    resources,
    lng: getLang(),
    fallbackLng: 'en',
    load: 'currentOnly',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
};

export default translationsConfig;
