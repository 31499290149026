import styled from 'styled-components';

export const BusinessFormMainWrapper = styled.section`
  background-color: #f4f4f4;
`;

export const BusinessFormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1350px;
  margin: 0 auto;
`;
