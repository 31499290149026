import axios from 'axios';
import { setOptions } from './setOptions';

export const postRequest = async (options) => {
  const serviceOptions = setOptions(options);

  const response = await axios.post(serviceOptions.url, serviceOptions.params, {
    headers: serviceOptions.headers,
  });

  return response.data;
};

export const getRequest = async (options) => {
  const serviceOptions = setOptions(options);

  const response = await axios.get(serviceOptions.url, {
    headers: serviceOptions.headers,
    data: serviceOptions.params,
  });
  return response.data;
};

export const deleteRequest = async (options) => {
  const serviceOptions = setOptions(options);

  const response = await axios.delete(serviceOptions.url, {
    data: serviceOptions.params,
    headers: serviceOptions.headers,
  });

  return response.data;
};

export const putRequest = async (options) => {
  const serviceOptions = setOptions(options);

  const response = await axios.put(serviceOptions.url, serviceOptions.params, {
    headers: serviceOptions.headers,
  });

  return response.data;
};
