import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { InfoRestaurantOrdersTable } from '../../components/InfoRestaurantOrdersTable/InfoRestaurantOrdersTable';
import { Title } from './RestaurantOrders.styled';
import { getRestaurantOrders } from '../../api/orderProducts';
import { getToken, deleteToken } from '../../services/auth';
import { setOrders } from '../../features/orders/ordersSlice';

export const RestaurantOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const storeId = useSelector((state) => state.store.id);
  const storeCategory = useSelector((state) => {
    if (
      state.store.store &&
      state.store.store.storeCategory &&
      state.store.store.storeCategory.name
    ) {
      return state.store.store.storeCategory.name;
    }
  });

  useEffect(() => getOrders(), []);

  const getOrders = async (page = 1) => {
    try {
      let params = {
        token: getToken(),
        id: storeId,
      };

      if (page > 1) {
        params = { ...params, page };
      }

      const orders = await getRestaurantOrders(params);
      await dispatch(setOrders(orders));
      await setLoading(false);
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
    // if (page > 1) return;
  };

  return (
    <ContentWrapper>
      <Title>{t('RestaurantOrders.title')}</Title>
      <div>
        <InfoRestaurantOrdersTable loading={loading} pageChange={getOrders} />
      </div>
    </ContentWrapper>
  );
};
