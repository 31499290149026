import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLayout } from '../../shared/BaseLayout/BaseLayout';
import { Container, Square, Left, Right } from './PasswordReset.styled';
import { Title } from '../../constants/base';
import { ConfirmPassReset } from '../../components/PassReset/ConfirmPassReset';

export const ConfirmPasswordReset = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <Container>
        <Square>
          <Left>
            <Title>{t('PasswordResetConfirm.formLeft.title')}</Title>
          </Left>
          <Right>
            <ConfirmPassReset />
          </Right>
        </Square>
      </Container>
    </BaseLayout>
  );
};
