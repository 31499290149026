import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { DigitalPublications } from '../../components/DigitalPublications/DigitalPublications';
import { DigitalWrapper } from './InnerDigital.styled';
import { TopSection } from '../../shared/TopSection';
import { PublicationModal } from '../../components/DigitalPublications/PublicationModal/PublicationModal';

export const InnerDigital = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [publicationData, setPublicationData] = useState({
    title: '',
    image: '',
    copy: '',
    createdAt: '',
    links: {
      facebook: '',
      instagram: '',
    },
  });

  const handleOpen = (event, data = undefined) => {
    event.preventDefault();
    setModalOpen(true);
    if (data) setPublicationData(data);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setPublicationData({
      title: '',
      image: '',
      copy: '',
      createdAt: '',
      links: {
        facebook: '',
        instagram: '',
      },
    });
    setModalOpen(false);
  };

  return (
    <ContentWrapper>
      <DigitalWrapper>
        <TopSection title={t('InnerDigital.title')} onClick={handleOpen} />
        <PublicationModal
          open={modalOpen}
          handleClose={handleClose}
          data={publicationData}
        />
        <DigitalPublications handleEdit={handleOpen} />
      </DigitalWrapper>
    </ContentWrapper>
  );
};
