import styled from 'styled-components';
import { colors, breakpoints } from '../../constants';
import { Button } from '../../constants/base';
import { TextField as Field } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${colors.gray01};
  font-family: Mulish;
`;

export const Box = styled.div`
  width: 100%;
  margin: 60px 20px 140px 20px;
  background-color: ${colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${breakpoints.tablet} {
    margin-top: 140px;
    margin-bottom: 216px;
    width: 649px;
  }
`;

export const Chip = styled.div`
  margin-top: 61px;
  width: 113px;
  height: 29px;
  background-color: ${colors.lightred};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.red};
`;

export const Title = styled.div`
  margin-top: 14px;
  margin-bottom: 23px;
  font-size: 36px;
  font-weight: 800;
  color: ${colors.black};
  text-align: center;
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 18px;
  width: 100%;
  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export const BusinessFormBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media ${breakpoints.laptopM} {
    flex-direction: row;
  }
`;

export const InnerFormBox = styled.div`
  margin: 0 8px;
  width: 100%;
`;

export const InnerBusinessFormBox = styled.div`
  margin: 0;
  width: 100%;
  @media ${breakpoints.laptopM} {
    margin: 0 21px;
  }
  {InputLabel} p {
    margin-top: 15px
  }
  .businessTypeCategoryDiv {
    margin: 0
  }
  .businessType {
    margin-top: 25px;
  }
  .categorySelect {
    margin-top: 15px;
    width: 100%;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded > ul  {
    border: solid 3px blue;
  }
`;

export const TextFieldWrap = styled.div`
  width: 250px;
  @media ${breakpoints.tablet} {
    width: 271px;
  }
`;

export const TextFieldWrapL = styled.div`
  width: 250px;
  @media ${breakpoints.tablet} {
    width: 559px;
  }
`;

export const TextFieldWrapBusinessForm = styled.div`
  @media ${breakpoints.tablet} {
    width: 598px;
  }
  input::file-selector-button {
    background-color: ${colors.red};
    color: ${colors.white};
    border: none;
    height: 42px;
    width: 122px;
    border-radius: 4px;
    border: 1px solid #000;
    margin-top: -50%;
  }
  input[type='file'] {
    color: #bdbdbd;
  }
  .yourDeliveryAreaClassMiles {
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const InputLabel = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.black};
`;

export const TextField = styled(Field)`
  width: 100%;
  margin: 8px 0 !important;
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
  }
  &.yourDeliveryAreaClass {
    width: 88px;
    height: 57px;
  }
  &.yourDeliveryAreaClass::after {
    content: Hola;
  }
  &.MuiInputBase-input.MuiInputBase-input.howManyBranchesClass {
    width: 163px;
    heigth: 93px;
    pointer-events: none;
    & input {
      width: 163px;
      heigth: 93px;
      padding: 0;
      text-align: center;
    }
  }
`;

export const Input = styled.input`
  margin-top: 15px;
  border: none;
  background-color: ${colors.black01};
  width: 247px;
  height: 57px;
  font-size: 16px;
  font-weight: 300;
  color: ${colors.black};
  outline: 0;
`;

export const InputFullWidth = styled.input`
  margin-top: 15px;
  border: none;
  background-color: ${colors.black01};
  padding: 0 24px;
  width: 558px;
  height: 57px;
  font-size: 16px;
  font-weight: 300;
  color: ${colors.black};
  outline: none;
`;

export const SignUpButton = styled(Button)`
  padding: 0;
  margin-top: 60px;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 900;
  width: 287px;
  height: 58px;
  &:hover {
    background-color: ${colors.red01};
  }
  &:active {
    background-color: ${colors.red};
  }
`;

export const TitleConfirmEmailAddress = styled(Title)`
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  @media ${breakpoints.tablet} {
    width: 381px;
  }
`;

export const TitleConfirmYourAccount = styled(Title)`
  margin-top: 14px;
  margin-bottom: 42px;
  text-align: center;
`;

export const StartButton = styled(Button)`
  margin-top: 38px;
  margin-bottom: 11px;
  font-size: 18px;
  font-weight: 900;
  width: 287px;
  height: 58px;
  &:hover {
    background-color: ${colors.red01};
  }
  &:active {
    background-color: ${colors.red};
  }
`;

export const BoxAboutYou = styled(Box)`
  width: 100%;
  margin: 0px;
  padding-bottom: 58px;
  font-family: Mulish;
`;

export const InputAboutYou = styled(Input)`
  width: 598px;
`;

export const TitleAboutYouWrap = styled(Title)`
  margin: 0;
  margin-bottom: 47px;
  width: 100%;
  text-align: center;
  @media ${breakpoints.tablet} {
    text-align: left;
  }
`;
export const TitleAboutYou = styled.p`
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  color: ${colors.black};
  margin-top: 49px;
  @media ${breakpoints.tablet} {
    margin-left: 69px;
  }
`;

export const NextButton = styled(Button)`
  padding: 0;
  margin-top: 47px;
  margin-bottom: 216px;
  font-size: 18px;
  font-weight: 900;
  width: 287px;
  height: 58px;
  &:hover {
    background-color: ${colors.red01};
  }
  &:active {
    background-color: ${colors.red};
  }
`;
