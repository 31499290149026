import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import { LoggedHeader } from '../../components/Header/Logged/LoggedHeader';
import { SideBar } from '../../shared/SideBar/SideBar';
import { Root, Content, MainContent } from './ContentWrapper.styled';

export const ContentWrapper = ({ children }) => {
  const isTemporaryCreation = useSelector(
    (state) => state.store.temporaryCreation,
  );

  return (
    <Root>
      {isTemporaryCreation === false ? <SideBar /> : null}
      <LoggedHeader />
      <Content>
        <Toolbar />
        <MainContent>{children}</MainContent>
      </Content>
    </Root>
  );
};

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
