import React from 'react';
import { useTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import { useStyles } from './ShoppersTableTop.styled';

export const ShoppersTableTop = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Toolbar>
      <div className={classes.title} id="tableTitle">
        <h1>{t('Shoppers.table.title')}</h1>
      </div>
    </Toolbar>
  );
};
