import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Subtitle, Button } from '../../constants/base';
import {
  TitleField,
  TextField,
  Form,
  Anchor,
  ErrorText,
} from './PassReset.styled';
import { resetPassword } from '../../api/users';
import { getToken } from '../../services/auth';

export const PassReset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [clearEmail, setClearEmail] = useState('');
  const [formSent, setFormSent] = useState(false);

  const [error, setError] = useState({
    email: [],
    nonFieldErrors: [],
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setClearEmail('');

    try {
      await resetPassword({
        token: getToken(),
        params: {
          email: event.target.email.value,
          redirectUrl: window.location.origin + '/password-reset/confirm/',
        },
      });
      setFormSent(true);
      window.setTimeout(function () {
        navigate('/', { replace: true });
      }, 4000);
    } catch (err) {
      const errData = err.response.data;
      if (err.response.status === 400) {
        setError({
          email: errData.email || [],
          nonFieldErrors: errData.nonFieldErrors || [],
        });
        return;
      }
      throw new Error(err);
    }
  };

  return (
    <>
      <Subtitle>{t('PasswordReset.formRight.subTitle')}</Subtitle>
      <TitleField>{t('PasswordReset.form.titleField.description')}</TitleField>
      <Form onSubmit={onSubmit}>
        <TextField
          error={error.email.length}
          helperText={error.email[0]}
          label={t('PasswordReset.form.inputLabel.email')}
          placeholder={t('PasswordReset.form.inputPlaceholder.email')}
          type="email"
          name="email"
          id="clearEmail"
          value={clearEmail}
          onChange={(event) => setClearEmail(event.target.value)}
          variant="outlined"
          size="small"
          required
        />
        {error.nonFieldErrors.length > 0 && (
          <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
        )}
        {formSent && (
          <Alert severity="success">
            <AlertTitle>{t('PasswordReset.form.alert.success')}</AlertTitle>
          </Alert>
        )}
        <Button type="submit">{t('PasswordReset.form.CTA.submit')}</Button>
        <Anchor to="/">{t('PasswordReset.form.anchor.backLogin')}</Anchor>
      </Form>
    </>
  );
};
