import React from 'react';
import PropTypes from 'prop-types';
import { AddCircle } from '../AddCircle';
import { Top, Title, Divider } from './TopSection.styled';

export const TopSection = ({ title, onClick }) => {
  return (
    <>
      <Top>
        <Title>{title}</Title>
        <AddCircle onClick={onClick} />
      </Top>
      <Divider />
    </>
  );
};

TopSection.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TopSection.defaultProps = {
  onClick: () => {},
};
