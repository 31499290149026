import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

export const configSentry = () => {
  Sentry.init({
    dsn: 'https://2e3f31118f0c4d7f80d7cf0aff360595@o390255.ingest.sentry.io/5791587',
    integrations: [new TracingIntegrations.BrowserTracing()],
    attachStacktrace: true,
    autoSessionTracking: true,
    tracesSampleRate: 0.2,
  });
};
