/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { Loader } from '../../components/Loader/Loader';
import { OrderOverview, OrderConfirmCancel } from '../../components/Order';
import { Title, LoaderWrapper, LoaderText } from './OrderConfirmation.styled';
import { useStyles } from './OrderConfirmation.styled';
import { useSelector } from 'react-redux';
import { getOrderProductsRestaurant } from '../../api/orderProducts';
import { getToken } from '../../services/auth';

export const OrderConfirmation = () => {
  const { t } = useTranslation();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const storeId = useSelector((state) => state.store.id);
  const classes = useStyles();
  const navigate = useNavigate();
  const idOrderRestaurant = useParams();
  const orderRestaurant = idOrderRestaurant;

  useEffect(() => fetchOrder(), []);

  const fetchOrder = async () => {
    try {
      const productsRestaurantsOrder = await getOrderProductsRestaurant({
        id: storeId,
        token: getToken(),
        idOrderRest: orderRestaurant.id,
      });
      await setOrder(productsRestaurantsOrder);
      await setLoading(false);
    } catch (error) {
      navigate('/');
    }
  };

  const ProductsList = ({ products }) => {
    return (
      <div>
        {products.map((prods, index) => (
          <div key={prods.id}>
            <div className={classes.productsMainBox}>
              <div className={classes.cardProductDiv} key={index}>
                <div className={classes.divProductImg}>
                  <img
                    src={prods.product.images[0].image}
                    alt={prods.product.name}
                    className={classes.productImg}
                  />
                </div>
                <div className={classes.divProductContent}>
                  <div>
                    <h3>{prods.product.name}</h3>
                    <p>
                      <b>{t('OrderConfirmation.product.skuLabel')}</b>
                      {prods.product.sku}
                    </p>
                    <p>
                      <b>{t('OrderConfirmation.product.quantityLabel')}</b>
                      {prods.quantity}
                    </p>
                    <p>
                      <b>{t('OrderConfirmation.product.priceLabel')}</b>
                      {prods.product.currency.symbol}
                      {prods.product.price.toFixed(2)}
                    </p>
                    {Object.keys(prods.attributes).map((item) => (
                      <p key={item}>
                        <b>{item}:&nbsp;</b>
                        {prods.attributes[item].map(
                          (elem, index) =>
                            `${elem.name}${
                              index < prods.attributes[item].length - 1
                                ? ', '
                                : ''
                            }`,
                        )}
                      </p>
                    ))}
                    {prods.observation.length ? (
                      <>
                        <b>{t('OrderConfirmation.product.observationLabel')}</b>
                        <span>{prods.observation}</span>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ContentWrapper>
      <Title>{t('OrderConfirmation.title')}</Title>
      {loading ? (
        <LoaderWrapper>
          <Loader />
          <LoaderText>{t('OrderConfirmation.loading')}</LoaderText>
        </LoaderWrapper>
      ) : (
        <>
          <OrderOverview {...order} />
          <div>
            {order.branchStores.map((item) => (
              <ProductsList key={order.id} {...item} />
            ))}
          </div>
          {order.status.description === 'CHECKING_ORDER' && (
            <div className={classes.divOrderConfirmCancelButtons}>
              <OrderConfirmCancel {...order} />
            </div>
          )}
        </>
      )}
    </ContentWrapper>
  );
};
