import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { TopSection } from '../../shared/TopSection';
import { ShoppersWrapper, TableWrapper } from './Shopper.styled';
import { ShoppersTable } from '../../components/ShoppersTable';
import { ShoppersModal } from '../../components/ShoppersTable/ShoppersModal/ShoppersModal';
import { setShoppers } from '../../features/shoppers/shoppersSlice';
import { retreiveShoppers } from '../../api/exclusiveShoppers';
import { getToken, deleteToken } from '../../services/auth';

export const Shoppers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.store.id);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    retreiveShoppers({
      id: storeId,
      token: getToken(),
    })
      .then((res) => dispatch(setShoppers(res)))
      .catch((error) => {
        if (error.response.status === 401) {
          deleteToken();
          return;
        }
        console.error(error);
      });
  }, []);

  const handleOpen = (event) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setModalOpen(false);
  };

  return (
    <ContentWrapper>
      <ShoppersWrapper>
        <TopSection title={t('Shoppers.title')} onClick={handleOpen} />
        <TableWrapper>
          <ShoppersTable loading={false} pageChange={() => {}} />
        </TableWrapper>
      </ShoppersWrapper>
      <ShoppersModal open={modalOpen} handleClose={handleClose} />
    </ContentWrapper>
  );
};
