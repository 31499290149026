import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import uiSlice from '../features/ui/uiSlice';
import ordersSlice from '../features/orders/ordersSlice';
import notificationsSlice from '../features/notifications/notificationsSlice';
import storeSlice from '../features/store/storeSlice';
import innerStoresSlice from '../features/store/innerStoresSlice';
import availableStoresSlice from '../features/store/availableStores';
import digitalMonthsSlice from '../features/digital/digitalMonthsSlice';
import digitalPublicationsSlice from '../features/digital/digitalPublicationsSlice';
import shoppersSlice from '../features/shoppers/shoppersSlice';
import couponsSlice from '../features/coupons/couponsSlice';
import inventorySlice from '../features/inventory/inventorySlice';

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiSlice,
  availableStores: availableStoresSlice,
  store: storeSlice,
  orders: ordersSlice,
  notifications: notificationsSlice,
  digitalMonths: digitalMonthsSlice,
  digitalPublications: digitalPublicationsSlice,
  innerStores: innerStoresSlice,
  shoppers: shoppersSlice,
  coupons: couponsSlice,
  products: inventorySlice,
});

export default rootReducer;
