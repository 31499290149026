import React from 'react';
import { useTranslation } from 'react-i18next';
import notFoundImage from '../../assets/egg404.png';
import { Wrapper, Figure, Img, Title, Subtitle, Message } from './Error.styled';

export const Error = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Figure>
        <Img src={notFoundImage} alt={t('Error.text')} />
      </Figure>
      <Title>{t('Error.title')}</Title>
      <Subtitle>{t('Error.text')}</Subtitle>
      <Message dangerouslySetInnerHTML={{ __html: t('Error.cta') }} />
    </Wrapper>
  );
};
