import styled from 'styled-components';
import { colors } from '../../constants';

export const Circle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${colors.red};
  border: none;
  &:hover {
    cursor: pointer;
  }
  svg {
    color: white;
  }
`;
