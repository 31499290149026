import styled from 'styled-components';
import { colors, styles } from '../../constants';

export const ChangeWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const FormWrapper = styled.div`
  border: solid 1px ${colors.lightgray};
  box-shadow: ${styles.shadow};
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 8px;
`;

export const TitleWrapper = styled.div`
  h2 {
    text-align: left;
    margin-bottom: 8px;
    font-size: 1.5rem;
  }
`;
