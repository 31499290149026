import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const CouponsTableHead = (props) => {
  const { t } = useTranslation();

  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'id',
      label: t('Coupons.table.id'),
      align: 'left',
    },
    { id: 'code', label: t('Coupons.table.code'), align: 'left' },
    { id: 'description', label: t('Coupons.table.description'), align: 'left' },
    {
      id: 'discount',
      label: t('Coupons.table.discount'),
      align: 'left',
    },
    {
      id: 'initDate',
      label: t('Coupons.table.initDate'),
      align: 'left',
    },
    {
      id: 'endDate',
      label: t('Coupons.table.endDate'),
      align: 'left',
    },
    {
      id: 'orders',
      label: t('Coupons.table.orders'),
      align: 'left',
    },
    {
      id: 'status',
      label: t('Coupons.table.status'),
      align: 'left',
    },
    {
      id: 'author',
      label: t('Coupons.table.author'),
      align: 'left',
    },
    {
      id: 'delete',
      label: '',
      align: 'left',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding="center"
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={true}>
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

CouponsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
