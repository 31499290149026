import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Drawer,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StoreRounded from '@material-ui/icons/StoreRounded';
import Toolbar from '@material-ui/core/Toolbar';
import { useStyles } from './SideBar.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faStore,
  faMotorcycle,
  faUsers,
  faTag,
  faDollyFlatbed,
} from '@fortawesome/free-solid-svg-icons';

export const SideBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const months = useSelector((state) => state.digitalMonths);
  const features = useSelector((state) => state.ui.features);
  const innerStores = useSelector((state) => state.innerStores);
  const [digitalOpen, setDigitalOpen] = useState(true);

  const isTemporaryCreation = useSelector(
    (state) => state.store.temporaryCreation,
  );

  const handleClick = () => setDigitalOpen(!digitalOpen);

  let activeStyle = {
    backgroundColor: 'rgba(242, 242, 242, 1)',
  };

  return isTemporaryCreation === false ? (
    <>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem
              button
              component={NavLink}
              to="/"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faHome}
                  className={classes.iconSideBarStyled}
                />
              </ListItemIcon>
              <ListItemText primary={t('Sidebar.dashboard')} />
            </ListItem>
            <Divider />
            {features.showPublications && (
              <>
                <ListItem button onClick={handleClick}>
                  <ListItemIcon>
                    <SettingsInputAntenna />
                  </ListItemIcon>
                  <ListItemText primary={t('Sidebar.digital')} />
                  {digitalOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={digitalOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {features.managePublications
                      ? innerStores.map((store, index) => (
                          <ListItem
                            button
                            key={`${store.name} - ${index}`}
                            className={classes.nested}
                            component={NavLink}
                            to={`/stores/${store.id}/publications/`}
                            style={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }>
                            <ListItemIcon>
                              <StoreRounded />
                            </ListItemIcon>
                            <ListItemText primary={store.name} />
                          </ListItem>
                        ))
                      : months.map((month, index) => (
                          <ListItem
                            button
                            key={`${month.slug} - ${index}`}
                            className={classes.nested}
                            component={NavLink}
                            to={`/digital/${month.slug}/`}
                            style={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }>
                            <ListItemText primary={month.text} />
                          </ListItem>
                        ))}
                  </List>
                </Collapse>
                <Divider />
              </>
            )}
            {features.managePickUp && (
              <>
                <ListItem
                  button
                  component={NavLink}
                  to="/pickup/"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faStore}
                      className={classes.iconSideBarStyled}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('Sidebar.pickup')} />
                </ListItem>
                <Divider />
              </>
            )}
            {features.manageDelivery && (
              <>
                <ListItem
                  button
                  component={NavLink}
                  to="/delivery/"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faMotorcycle}
                      className={classes.iconSideBarStyled}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('Sidebar.delivery')} />
                </ListItem>
                <Divider />
              </>
            )}
            {features.manageShoppers && (
              <>
                <ListItem
                  button
                  component={NavLink}
                  to="/shoppers/"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faUsers}
                      className={classes.iconSideBarStyled}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('Sidebar.shoppers')} />
                </ListItem>
                <Divider />
              </>
            )}
            {features.manageCoupons && (
              <>
                <ListItem
                  button
                  component={NavLink}
                  to="/coupons/"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faTag}
                      className={classes.iconSideBarStyled}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('Sidebar.coupons')} />
                </ListItem>
                <Divider />
              </>
            )}
            {features.manageRestaurantOrders && (
              <>
                <ListItem
                  button
                  component={NavLink}
                  to="/restaurant-orders/"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>
                  <ListItemIcon>
                    <RestaurantIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Sidebar.restaurantOrders')} />
                </ListItem>
                <Divider />
              </>
            )}
            {features.manageInventory && (
              <>
                <ListItem
                  button
                  component={NavLink}
                  to="/inventory/"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faDollyFlatbed}
                      className={classes.iconSideBarStyled}
                    />
                  </ListItemIcon>
                  <ListItemText primary={t('Sidebar.inventory')} />
                </ListItem>
                <Divider />
              </>
            )}
          </List>
        </div>
      </Drawer>
    </>
  ) : null;
};
