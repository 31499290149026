import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from './Loading.styled';
import { Loader } from '../../components/Loader/Loader';
import { Subtitle } from '../../constants/base';

export const Loading = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Subtitle>{t('Loading.hold')}</Subtitle>
      <Loader size="3x" />
    </Content>
  );
};
