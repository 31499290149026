import styled from 'styled-components';
import MatetialDivider from '@material-ui/core/Divider';

export const Title = styled.h2`
  font-size: 27px;
  text-align: center;
  margin-bottom: 16px;
`;

export const DigitalWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

export const Divider = styled(MatetialDivider)`
  width: 100%;
`;
