import React from 'react';
import { BaseLayout } from '../../shared/BaseLayout/BaseLayout';
import { RegisterForm } from '../../components/SignUpForms/RegisterForm';

export const SignUp = () => {
  return (
    <BaseLayout>
      <RegisterForm />
    </BaseLayout>
  );
};
