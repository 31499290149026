import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const PaginatorContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

export const CtaWrapper = styled.div`
  display: flex;
  gap: 3px 24px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 8px 15px 8px 30px;
  margin-bottom: 15px;
  flex-direction: row;
  flex-wrap: nowrap;
`;
