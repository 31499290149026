import styled from 'styled-components';
import { TextField as Field } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { colors, styles, breakpoints } from '../../constants';

export const ProfileWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const FormWrapper = styled.div`
  border: solid 1px ${colors.lightgray};
  box-shadow: ${styles.shadow};
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 8px;
`;

export const TitleWrapper = styled.div`
  h2 {
    text-align: left;
    margin-bottom: 8px;
    font-size: 1.5rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 16px;
  }
`;

export const FormRow = styled.div`
  width: 95%;
  @media ${breakpoints.mobileL} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
  }
`;

export const TextField = styled(Field)`
  width: 100%;
  margin: 8px 0 !important;
`;

export const ErrorText = styled.p`
  color: ${colors.red};
  text-align: center;
`;

export const Anchor = styled(Link)`
  color: ${colors.red};
  margin-top: 32px;
  text-align: center;
  display: block;
`;
