import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import {
  ChangeWrapper,
  FormWrapper,
  TitleWrapper,
} from './PasswordChange.styled';
import { Subtitle } from '../../constants/base';
import { PassChange } from '../../components/PassChange/PassChange';

export const PasswordChange = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <ChangeWrapper>
        <TitleWrapper>
          <Subtitle>{t('PasswordChange.title')}</Subtitle>
        </TitleWrapper>
        <FormWrapper>
          <PassChange />
        </FormWrapper>
      </ChangeWrapper>
    </ContentWrapper>
  );
};
