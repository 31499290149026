import styled from 'styled-components';
import { colors } from '../../constants';
import { makeStyles } from '@material-ui/core/styles';

export const EditButton = styled.button`
  color: ${colors.red};
  background-color: transparent;
  border: none;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const DeleteButton = styled.button`
  color: ${colors.red};
  background-color: transparent;
  border: none;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const useStyles = makeStyles((theme) => ({
  shopperActiveCell: {
    display: 'flex',
  },
  shopperActiveTrue: {
    color: 'green',
  },
  shopperActiveFalse: {
    color: 'red',
  },
  orderTypeDelivery: {
    display: 'flex',
    justifyContent: 'center',
    width: '138.63px',
    backgroundColor: '#D96E18',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '-0.08px',
    color: '#fff',
    padding: '8px 0px',
    borderRadius: '5px',
  },
  orderTypePickup: {
    display: 'flex',
    justifyContent: 'center',
    width: '138.63px',
    backgroundColor: '#6CB33F',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '-0.08px',
    color: '#fff',
    padding: '8px 0px',
    borderRadius: '5px',
  },
  deleteShopperDialogTitle: {
    color: '#c43c42',
  },
  buttonCancel: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    color: '#000',
    backgroundColor: 'transparent',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
    marginBottom: '10px',
  },
  buttonDelete: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
    borderRadius: '15px',
    marginRight: '20px',
    marginBottom: '10px',
  },
}));
