import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from '../services/http';

export const retreiveShoppers = async (data) => {
  const response = await getRequest({
    url: `user/branch-stores/${data.id}/manage-shapyers`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const searchShoppers = async (data) => {
  const response = await getRequest({
    url: `user/branch-stores/${data.id}/find-users/?email=${data.email}`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const createShopperRequest = async (data) => {
  const response = await postRequest({
    url: `user/branch-stores/${data.id}/create-shopper-request/`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const createShoppers = async (data) => {
  const response = await postRequest({
    url: `user/branch-stores/${data.id}/manage-shapyers/`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const updateShoppers = async (data) => {
  const response = await putRequest({
    url: `user/branch-stores/${data.id}/manage-shapyers/${data.shopper}/`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const deleteShoppers = async (data) => {
  const response = await deleteRequest({
    url: `user/branch-stores/${data.id}/manage-shapyers/${data.shopper}/`,
    data: data.params,
    token: data.token,
  });

  return response;
};
