import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Publication } from './Publication';
import { Wrapper } from './DigitalPublications.styled';

export const DigitalPublications = (props) => {
  const publications = useSelector((state) => state.digitalPublications);

  return (
    <Wrapper>
      {publications.map((publication, index) => (
        <Publication
          key={`${publication.id} - ${index}`}
          handleEdit={props.handleEdit}
          {...publication}
        />
      ))}
    </Wrapper>
  );
};

DigitalPublications.propTypes = {
  handleEdit: PropTypes.func,
};
