import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    text: 'January',
    slug: 'january-2021',
  },
  {
    text: 'February',
    slug: 'february-2021',
  },
  {
    text: 'March',
    slug: 'march-2021',
  },
];

const digitalMonthsSlice = createSlice({
  name: 'digitalMonths',
  initialState,
  reducers: {
    setMonths(_, { payload }) {
      return payload;
    },
  },
});

export const { setMonths } = digitalMonthsSlice.actions;

export default digitalMonthsSlice.reducer;
