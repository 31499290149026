import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    id: 2,
    name: 'Interfood Plaza Latina - Raleigh',
    store: {
      name: 'Interfood Plaza Latina',
      description: 'Latinos Supermarket',
      logo: 'https://shapyadevelop.s3.amazonaws.com/media/logos/stores/Interfood_1.png',
    },
  },
  {
    id: 3,
    name: 'El Mandado - Raleigh',
    store: {
      name: 'El Mandado',
      description: 'Latinos Supermarket',
      logo: 'https://shapyadevelop.s3.amazonaws.com/media/logos/stores/Interfood_1.png',
    },
  },
];

const innerStoresSlice = createSlice({
  name: 'innerStores',
  initialState,
  reducers: {
    setStores(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setStores } = innerStoresSlice.actions;

export default innerStoresSlice.reducer;
