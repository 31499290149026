import styled from 'styled-components';
import { TextField as Field } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../constants';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const ModalWrapper = styled.div`
  background-color: ${colors.white};
  outline: none;
  padding: 24px;
  border-radius: 8px;
  min-width: 500px;
`;

export const Title = styled.h2`
  color: ${colors.black};
  font-size: 24px;
  margin-bottom: 47px;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 16px;
  }
`;

export const TextField = styled(Field)`
  width: 100%;
  margin: 8px 0 !important;
`;

export const ErrorText = styled.p`
  color: ${colors.red};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  justify-content: space-between;
`;
