import { postRequest, getRequest } from '../services/http';

export const registerUser = async (data) => {
  const response = await postRequest({
    url: 'auth/registration/',
    data: data.params,
  });

  return response;
};

export const userLogin = async (data) => {
  const response = await postRequest({
    url: 'auth/login/',
    data: data.params,
  });

  return response;
};

export const retreiveUser = async (data) => {
  const response = await getRequest({
    url: 'auth/user/',
    token: data.token,
  });

  return response;
};

export const logoutUser = async (data) => {
  const response = await postRequest({
    url: 'auth/logout/',
    token: data.token,
  });

  return response;
};

export const retreiveUserStores = async (data) => {
  const response = await getRequest({
    url: 'user/branch-stores/',
    token: data.token,
  });

  return response;
};

export const updateUser = async (data) => {
  const response = await postRequest({
    url: 'auth/user/~update/',
    token: data.token,
    data: data.params,
  });

  return response;
};

export const changePassword = async (data) => {
  const response = await postRequest({
    url: 'auth/password/change/',
    token: data.token,
    data: data.params,
  });

  return response;
};

export const resetPassword = async (data) => {
  const response = await postRequest({
    url: 'auth/password/reset/',
    data: data.params,
  });

  return response;
};

export const confirmResetPassword = async (data) => {
  const response = await postRequest({
    url: `auth/password/reset/confirm/${data.params.uid}${data.params.token}/`,
    data: data.params,
  });

  return response;
};
