import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import { CouponsTableTop } from './CouponsTableTop';
import { CouponsTableContent } from './CouponsTableContent';
import { CouponsTableHead } from './CouponsTableHead';
import { LoadingTable } from '../InfoTable/LoadingTable';
import { useStyles, PaginatorContent } from './CouponsTable.styled';

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitedFilter = orderBy.split('.');
    if (a[splitedFilter[0]] === null || b[splitedFilter[0]] === null) {
      return 0;
    }

    if (
      b[splitedFilter[0]][splitedFilter[1]] <
      a[splitedFilter[0]][splitedFilter[1]]
    ) {
      return -1;
    }
    if (
      b[splitedFilter[0]][splitedFilter[1]] >
      a[splitedFilter[0]][splitedFilter[1]]
    ) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const CouponsTable = ({ loading, pageChange }) => {
  const classes = useStyles();
  const coupons = useSelector((state) => state.coupons.results);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('title');

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, page) => {
    event.preventDefault();
    pageChange(page);
  };

  const pagesCount = 1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CouponsTableTop />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table">
            <CouponsTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={coupons.length}
            />
            {coupons.length ? (
              <TableBody>
                {stableSort(coupons, getComparator(order, orderBy)).map(
                  (coupon, index) => (
                    <CouponsTableContent
                      key={`${coupon.id}-${index}`}
                      index={index}
                      coupon={coupon}
                    />
                  ),
                )}
              </TableBody>
            ) : (
              <LoadingTable loading={loading} />
            )}
          </Table>
        </TableContainer>
        <PaginatorContent>
          <Pagination count={pagesCount} onChange={handlePageChange} />
        </PaginatorContent>
      </Paper>
    </div>
  );
};

CouponsTable.propTypes = {
  loading: PropTypes.bool,
  pageChange: PropTypes.func,
};

CouponsTable.defaultProps = {
  loading: false,
  pageChange: () => {},
};
