import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/Logo_ShapYa_Red.png';
import {
  Container,
  Figure,
  Img,
  List,
  ListItem,
  Anchor,
} from './Header.styled';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Anchor href="https://shapya.com/">
        <Figure>
          <Img src={logo} alt="Shapya" />
        </Figure>
      </Anchor>
      <List>
        <ListItem>
          <Anchor href="https://shapya.com/our-stores/">
            {t('Header.unlogged.meet')}
          </Anchor>
        </ListItem>
        <ListItem>
          <Anchor href="https://shapya.com/storeRestaurantForm/">
            {t('Header.unlogged.work')}
          </Anchor>
        </ListItem>
      </List>
    </Container>
  );
};
