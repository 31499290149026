import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import dayjs from 'dayjs';
import CloudDownloadRounded from '@material-ui/icons/CloudDownloadRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { InvoiceAnchor, useStyles } from './DeliveryTableContent.styled';
import { getBranchStoreOrderProducts } from '../../api/branchStores';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import LogoShapya from '../../assets/Logo_ShapYa_Red.png';
import { getToken } from '../../services/auth';
import { Box } from '@material-ui/core';

export const DeliveryTableContent = ({ singleOrder, index }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const storeId = useSelector((state) => state.store.id);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const labelId = `enhanced-table-checkbox-${index}`;
  const symbol = singleOrder.orderCost.currency.symbol;

  const sumTotalOrder = [
    singleOrder.orderCost.productsCost,
    singleOrder.orderCost.tax,
  ]
    .reduce((a, b) => a + b)
    .toFixed(2);

  const [orderProds, setorderProds] = useState([]);

  useEffect(() => {
    async function showOrderProducts() {
      try {
        const reqOrderProds = await getBranchStoreOrderProducts({
          id: storeId,
          orderId: singleOrder.id,
          token: getToken(),
        });
        setorderProds(reqOrderProds);
        setorderProds(
          reqOrderProds.branchStores.map((branchstoreso) => {
            return branchstoreso.products.map((soproducts) => {
              return (
                <li key={soproducts.product.storeBranchProductId}>
                  {soproducts.product.name}
                </li>
              );
            });
          }),
        );
      } catch (error) {
        console.error(error);
      }
    }
    showOrderProducts();
  }, []);

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" id={labelId} scope="row">
        <strong>{singleOrder.reference}</strong>
      </TableCell>
      <TableCell align="left">
        {singleOrder.client.user.firstName} {singleOrder.client.user.lastName}
      </TableCell>
      <TableCell align="left">
        {singleOrder.orderCost.receipt ? (
          <InvoiceAnchor onClick={handleOpen}>
            <ShoppingCartIcon />
          </InvoiceAnchor>
        ) : null}
      </TableCell>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <img src={LogoShapya} width="120" height="36" alt="Shapya Logo" />
            <h2 id="transition-modal-title">
              {t('Delivery.modal.orderid')} {singleOrder.reference}
            </h2>
            <Divider />
            <h3>{t('Delivery.modal.productstitle')}</h3>
            <Box mt={1}>
              <p id="transition-modal-description">{orderProds}</p>
            </Box>
          </div>
        </Fade>
      </Modal>
      <TableCell align="left">
        {singleOrder.status && singleOrder.status.description
          ? singleOrder.status.description
          : ''}
      </TableCell>
      <TableCell align="left">
        {dayjs(new Date(singleOrder.orderInitAt)).format('MMMM DD - YYYY')}
      </TableCell>
      <TableCell align="left">
        {singleOrder.deliveryDatetime
          ? dayjs(new Date(singleOrder.deliveryDatetime)).format(
              'MMMM DD - YYYY',
            )
          : ''}
      </TableCell>
      <TableCell align="left">{singleOrder.shapyer}</TableCell>
      <TableCell align="left">{`${symbol} ${sumTotalOrder}`}</TableCell>
      <TableCell align="right">
        {singleOrder.orderCost.receipt ? (
          <InvoiceAnchor
            href={singleOrder.orderCost.receipt}
            download
            target="_blank">
            <CloudDownloadRounded />
          </InvoiceAnchor>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

DeliveryTableContent.propTypes = {
  singleOrder: PropTypes.shape({
    branchStores: PropTypes.array,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reference: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    client: PropTypes.shape({
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
    orderCost: PropTypes.shape({
      productsCost: PropTypes.number,
      currency: PropTypes.shape({
        symbol: PropTypes.string,
      }),
      receipt: PropTypes.string,
      tax: PropTypes.number,
    }),
    tax: PropTypes.number,
    status: PropTypes.shape({
      description: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    orderInitAt: PropTypes.string,
    deliveryDatetime: PropTypes.string,
    shapyer: PropTypes.string,
    deliveredBy: PropTypes.string,
  }),
  index: PropTypes.number,
};

DeliveryTableContent.defaultProps = {
  singleOrder: {
    branchStores: [],
    shapyer: '-',
    deliveredBy: '-',
  },
};
