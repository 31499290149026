import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button } from '../../constants/base';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { EditShopperDialog } from './EditShopperDialog';
import {
  editShopper,
  deleteShopper,
} from '../../features/shoppers/shoppersSlice';
import { updateShoppers, deleteShoppers } from '../../api/exclusiveShoppers';
import { getToken, deleteToken } from '../../services/auth';
import { EditButton, DeleteButton } from './ShoppersTableContent.styled';
import { useStyles } from './ShoppersTableContent.styled';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ShoppersTableContent = ({ shopper }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.store.id);
  const classes = useStyles();

  const [dialogOpen, setDialog] = useState(false);
  const [dialogDeleteConfirmationOpen, setDialogDeleteConfirmation] =
    useState(false);

  const handleClickOpenDialogDeleteConfirmation = () => {
    setDialogDeleteConfirmation(true);
  };
  const handleCloseDialogDeleteConfirmation = () => {
    setDialogDeleteConfirmation(false);
  };

  const handleEditShopper = async (event) => {
    event.preventDefault();
    try {
      const updatedShopper = await updateShoppers({
        id: storeId,
        token: getToken(),
        shopper: shopper.id,
        params: {
          user: shopper.user.id.toString(),
          active: event.target.activeRadioGroup.value,
          branch_store: storeId.toString(),
          orderType: event.target.jobAssignmentRadioGroup.value,
        },
      });
      await dispatch(editShopper(updatedShopper));
      await setDialog(false);
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      throw new Error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteShoppers({
        id: storeId,
        token: getToken(),
        shopper: shopper.id,
        params: {
          branch_store: storeId,
        },
      });
      await dispatch(deleteShopper(shopper.id));
      await handleCloseDialogDeleteConfirmation;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" scope="row">
        <strong>{shopper.id}</strong>
      </TableCell>
      <TableCell align="left">{shopper.user.firstName}</TableCell>
      <TableCell align="left">{shopper.user.lastName}</TableCell>
      <TableCell align="left">{shopper.user.email}</TableCell>
      <TableCell align="left" className={classes.shopperActiveCell}>
        {shopper.active.toString() === 'true' ? (
          <CheckCircleIcon
            className={classes.shopperActiveTrue}
            fontSize="large"
          />
        ) : (
          <CancelIcon className={classes.shopperActiveFalse} fontSize="large" />
        )}
      </TableCell>
      <TableCell align="left">
        {shopper.orderType === 'delivery' ? (
          <span className={classes.orderTypeDelivery}>Delivery</span>
        ) : shopper.orderType === 'pickup' ? (
          <span className={classes.orderTypePickup}>Pick Up</span>
        ) : null}
      </TableCell>
      <TableCell align="left">
        <EditButton onClick={() => setDialog(true)}>
          <EditIcon />
        </EditButton>
        <Dialog
          open={dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDialog(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <EditShopperDialog
            onSubmit={handleEditShopper}
            hideDialog={() => setDialog(false)}
            shopperFirstName={shopper.user.firstName}
            shopperLastName={shopper.user.lastName}
            shopperEmail={shopper.user.email}
            shopperActive={shopper.active.toString()}
            shopperJa={shopper.orderType}
          />
        </Dialog>
      </TableCell>
      <TableCell align="left">
        <DeleteButton onClick={handleClickOpenDialogDeleteConfirmation}>
          <DeleteIcon />
        </DeleteButton>
        <Dialog
          open={dialogDeleteConfirmationOpen}
          TransitionComponent={Transition}
          onClose={handleCloseDialogDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            className={classes.deleteShopperDialogTitle}>
            {t('Shoppers.shoppersDeleteDialog.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('Shoppers.shoppersDeleteDialog.content')}
              <h3>
                {shopper.user.firstName} {shopper.user.lastName}
              </h3>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.buttonCancel}
              onClick={handleCloseDialogDeleteConfirmation}>
              {t('Shoppers.shoppersDeleteDialog.button.cancel')}
            </Button>
            <Button className={classes.buttonDelete} onClick={handleDelete}>
              {t('Shoppers.shoppersDeleteDialog.button.delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

ShoppersTableContent.propTypes = {
  shopper: PropTypes.shape({
    id: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    orderType: PropTypes.string.isRequired,
    signinDate: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  }),
  index: PropTypes.number,
};
