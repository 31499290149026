import React from 'react';
import { useTranslation } from 'react-i18next';
import notFoundImage from '../../assets/egg404.png';
import {
  Wrapper,
  Figure,
  Img,
  Title,
  Subtitle,
  Message,
} from './NotFound.styled';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Figure>
        <Img src={notFoundImage} alt={t('NotFound.text')} />
      </Figure>
      <Title>{t('NotFound.title')}</Title>
      <Subtitle>{t('NotFound.text')}</Subtitle>
      <Message dangerouslySetInnerHTML={{ __html:t('NotFound.cta') }} />
    </Wrapper>
  );
};
