import { getRequest, postRequest, putRequest } from '../services/http';

export const getLastInventory = async (data) => {
  try {
    if (data.page) {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/?page=${data.page}`,
        token: data.token,
      });
      return response;
    } else {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/`,
        token: data.token,
      });

      return response;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getSearchProductInventory = async (data) => {
  try {
    if (data.page) {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/?page=${data.page}&product__name=${data.searchWord}`,
        token: data.token,
      });
      return response;
    } else {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/?product__name=${data.searchWord}`,
        token: data.token,
      });

      return response;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getSearchSkuInventory = async (data) => {
  try {
    if (data.page) {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/?page=${data.page}&product__sku=${data.searchSku}`,
        token: data.token,
      });
      return response;
    } else {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/?product__sku=${data.searchSku}`,
        token: data.token,
      });

      return response;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getSearchCategoryInventory = async (data) => {
  try {
    if (data.page) {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/?page=${data.page}&product__category=${data.searchCategoryId}`,
        token: data.token,
      });
      return response;
    } else {
      const response = await getRequest({
        url: `user/branch-stores/${data.id}/store-branch-products/?product__category=${data.searchCategoryId}`,
        token: data.token,
      });

      return response;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const updateLastStoreInventory = async (data) => {
  try {
    const response = await postRequest({
      url: `user/branch-stores/${data.id}/download-inventory/`,
      data: data.params,
      token: data.token,
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const downloadStoreInventory = async (data) => {
  try {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/download-inventory/`,
      data: data.params,
      token: data.token,
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateLastBaseInventory = async (data) => {
  try {
    const response = await postRequest({
      url: `user/branch-stores/${data.id}/download-base-inventory/`,
      data: data.params,
      token: data.token,
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const downloadBaseInventory = async (data) => {
  try {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/download-base-inventory/`,
      data: data.params,
      token: data.token,
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateProductInventory = async (data) => {
  try {
    const response = await putRequest({
      url: `user/branch-stores/${data.id}/store-branch-products/${data.productId}/`,
      data: data.params,
      token: data.token,
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
