import styled from 'styled-components';
import { colors } from '../../constants';
import { TextField as Field } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  buttoncancel: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    color: '#000',
    backgroundColor: 'transparent',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
  },
  buttonupdate: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
    borderRadius: '15px',
    marginRight: '20px',
  },

  icon: {
    borderRadius: '5px',
    width: 20,
    height: 20,
    border: 'solid 1.2px #828282',
    backgroundColor: '#fff',
    'input:hover ~ &': {
      backgroundColor: '#f2f2f2',
    },
  },
  checkedIcon: {
    backgroundColor: '#C43C42',
    border: 'solid 1.2px #C43C42',
    'input:hover ~ &': {
      backgroundColor: '#C43C42',
    },
  },
  radiolabel: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
    color: '#000',
  },
}));

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  button {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const TextField = styled(Field)`
  width: 100%;
  margin: 8px 0 !important;
`;

export const ErrorText = styled.p`
  color: ${colors.red};
  text-align: center;
`;

export const ModalWrapper = styled.div`
  background-color: ${colors.white};
  outline: none;
  padding: 24px;
  border-radius: 8px;
  width: 784px;
  font-family: 'Montserrat';
`;

export const Title = styled.h2`
  color: ${colors.black};
  font-size: 24px;
  margin-bottom: 27px;
  text-align: center;
`;
