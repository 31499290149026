const { REACT_APP_API_URL } = process.env;
const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const setOptions = (options) => {
  const altHeaders = options.token
    ? { Authorization: `Token ${options.token}` }
    : {};

  return {
    headers: {
      ...HEADERS,
      ...altHeaders,
    },
    params: options.data ? { ...options.data } : {},
    url: `${REACT_APP_API_URL}/${options.url}`,
  };
};
