import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Square, Title, Subtitle, Percent } from './InfoSquare.styled';

export const InfoSquare = ({ ammount, text, percentage, increase }) => {
  return (
    <Square>
      <Title>{ammount}</Title>
      <Subtitle>{text}</Subtitle>
      <Percent increase={increase}>
        <FontAwesomeIcon icon={increase ? faCaretUp : faCaretDown} />
        {percentage}%
      </Percent>
    </Square>
  );
};

InfoSquare.propTypes = {
  ammount: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  increase: PropTypes.bool,
};

InfoSquare.defaultProps = {
  increase: false,
};
