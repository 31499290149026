export const calculateOrdersCost = (prev, current) => {
  if (prev === 0 && current === 0) {
    return {
      percentage: 0,
      increase: false,
    };
  } else if (prev > 0 && current === 0) {
    return {
      percentage: 100,
      increase: false,
    };
  } else if (prev === 0 && current > 0) {
    return {
      percentage: 100,
      increase: true,
    };
  }

  if (prev > current) {
    const netDifference = prev - current;
    const percentage = (netDifference * 100) / prev;

    return {
      percentage: percentage,
      increase: false,
    };
  }

  const percentage = (current * 100) / prev;
  const isPositive = current >= prev;

  return {
    percentage: isPositive ? percentage : percentage * -1,
    increase: isPositive,
  };
};
