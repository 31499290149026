import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

export const DeactivateDialog = ({ onSubmit, hideDialog }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title">
        {t('Coupons.table.deactivate.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('Coupons.table.deactivate.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="gray">
          {t('Coupons.table.deactivate.cancel')}
        </Button>
        <Button onClick={onSubmit} color="primary">
          {t('Coupons.table.deactivate.cta')}
        </Button>
      </DialogActions>
    </>
  );
};

DeactivateDialog.propTypes = {
  onSubmit: PropTypes.func,
  hideDialog: PropTypes.func,
};
