import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const storeCategoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setCategories } = storeCategoriesSlice.actions;

export default storeCategoriesSlice.reducer;
