import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Alert, AlertTitle } from '@material-ui/lab';
import { H3, Button } from '../../constants/base';
import { TextField, Form, Anchor, ErrorText } from './PassChange.styled';
import { changePassword } from '../../api/users';
import { getToken } from '../../services/auth';

export const PassChange = () => {
  const { t } = useTranslation();

  const [formSent, setFormSent] = useState(false);

  const [values, setValues] = React.useState({
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
    showOldPassword: false,
    showPassword1: false,
    showPassword2: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowOldPassword = () => {
    setValues({ ...values, showOldPassword: !values.showOldPassword });
  };

  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };

  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const [error, setError] = useState({
    oldPassword: [],
    newPassword1: [],
    newPassword2: [],
    nonFieldErrors: [],
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await changePassword({
        token: getToken(),
        params: {
          oldPassword: event.target.oldPassword.value,
          newPassword1: event.target.newPassword1.value,
          newPassword2: event.target.newPassword2.value,
        },
      });
      setFormSent(true);
    } catch (err) {
      const errData = err.response.data;
      if (err.response.status === 400) {
        setError({
          oldPassword: errData.oldPassword || [],
          newPassword1: errData.newPassword1 || [],
          newPassword2: errData.newPassword2 || [],
          nonFieldErrors: errData.nonFieldErrors || [],
        });
        return;
      }
      throw new Error(err);
    }
  };

  return (
    <>
      <H3>{t('PasswordChange.subtitle')}</H3>
      <Form onSubmit={onSubmit}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  edge="end">
                  {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={values.showOldPassword ? 'text' : 'password'}
          value={values.oldPassword}
          onChange={handleChange('oldPassword')}
          error={error.oldPassword.length}
          helperText={error.oldPassword[0]}
          label={t('PasswordChange.oldPassword.label')}
          placeholder={t('PasswordChange.oldPassword.placeholder')}
          name="oldPassword"
          variant="outlined"
          size="small"
          required
        />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  edge="end">
                  {values.showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={values.showPassword1 ? 'text' : 'password'}
          value={values.newPassword1}
          onChange={handleChange('newPassword1')}
          error={error.newPassword1.length}
          helperText={error.newPassword1[0]}
          label={t('PasswordChange.newPassword1.label')}
          placeholder={t('PasswordChange.newPassword1.placeholder')}
          name="newPassword1"
          variant="outlined"
          size="small"
          required
        />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  edge="end">
                  {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={values.showPassword2 ? 'text' : 'password'}
          value={values.newPassword2}
          onChange={handleChange('newPassword2')}
          error={error.newPassword2.length}
          helperText={error.newPassword2[0]}
          label={t('PasswordChange.newPassword2.label')}
          placeholder={t('PasswordChange.newPassword2.placeholder')}
          name="newPassword2"
          variant="outlined"
          size="small"
          required
        />
        {error.nonFieldErrors.length > 0 && (
          <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
        )}
        {formSent && (
          <Alert severity="success">
            <AlertTitle>{t('PasswordChange.success')}</AlertTitle>
          </Alert>
        )}
        <Button>{t('PasswordChange.cta')}</Button>
        <Anchor to="/profile/">{t('PasswordChange.back')}</Anchor>
      </Form>
    </>
  );
};
