import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  results: [],
};

const shoppersSlice = createSlice({
  name: 'shoppers',
  initialState,
  reducers: {
    setShoppers(_, { payload }) {
      return payload;
    },
    createShopper(state, { payload }) {
      return {
        ...state,
        results: state.results.concat(payload),
      };
    },
    editShopper(state, { payload }) {
      const shoppers = [];

      for (let i = 0; i <= state.results.length - 1; i++) {
        if (state.results[i].id === payload.id) {
          shoppers.push(payload);
          continue;
        }
        shoppers.push(state.results[i]);
      }
      return {
        ...state,
        results: shoppers,
      };
    },
    deleteShopper(state, { payload }) {
      const shoppers = state.results.filter(
        (shopper) => shopper.id !== payload,
      );
      return {
        ...state,
        results: shoppers,
      };
    },
  },
});

export const { setShoppers, createShopper, editShopper, deleteShopper } =
  shoppersSlice.actions;

export default shoppersSlice.reducer;
