import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Loader } from '../Loader/Loader';
import { EmptyContainer, Empty } from './LoadingTable.styled';

export const LoadingTable = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell id={1} scope="row" />
      <TableCell id={1} scope="row" />
      <TableCell id={1} scope="row" />
      <TableCell id={1} scope="row" />
      <TableCell id={1} scope="row">
        <EmptyContainer>
          {loading ? <Loader /> : null}
          <Empty>
            {loading
              ? t('Dashboard.table.loading')
              : t('Dashboard.table.empty')}
          </Empty>
        </EmptyContainer>
      </TableCell>
      <TableCell id={1} scope="row" />
      <TableCell id={1} scope="row" />
      <TableCell id={1} scope="row" />
      <TableCell id={1} scope="row" />
    </TableRow>
  );
};

LoadingTable.propTypes = {
  loading: PropTypes.bool,
};
