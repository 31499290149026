import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Dashboard,
  Digital,
  Entry,
  SignUp,
  InnerDigital,
  Loading,
  NotFound,
  Pickup,
  Suscription,
  Profile,
  Shoppers,
  Coupons,
  Inventory,
  RestaurantOrders,
  Delivery,
  OrderConfirmation,
  PasswordChange,
  PasswordReset,
  ConfirmPasswordReset,
  BusinessForm,
} from '../pages';
import { retreiveUser, retreiveUserStores } from '../api/users';
import { getToken, deleteToken } from '../services/auth';
import { setUser } from '../features/user/userSlice';
import { setStore } from '../features/store/storeSlice';
import { modifyFeatures } from '../features/ui/uiSlice';
import { setStoreList } from '../features/store/availableStores';

export const Router = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(getToken() !== '');
  const [isTemporaryCreation, setIsTemporaryCreation] = useState(false);

  useEffect(() => getUser(), [isLogged]);

  const getUser = async () => {
    try {
      if (isLogged) {
        const userInfo = await retreiveUser({ token: getToken() });
        const userStores = await retreiveUserStores({ token: getToken() });

        await dispatch(setUser({ ...userInfo }));
        await dispatch(setStoreList([...userStores.results]));

        if (userStores.count > 0) {
          const storedStore =
            JSON.parse(localStorage.getItem('selected_store')) ||
            userStores.results[0].id;
          const selectedStore = userStores.results.filter(
            (singleStore) => singleStore.id === storedStore,
          );

          await dispatch(setStore(selectedStore[0]));
          await dispatch(
            modifyFeatures({
              manageRestaurantOrders:
                selectedStore[0].store.storeCategory &&
                selectedStore[0].store.storeCategory.name === 'Restaurants',
              managePickUp: selectedStore[0].hasPickup || false,
              manageDelivery: selectedStore[0].hasDelivery || false,
            }),
          );
          localStorage.setItem(
            'selected_store',
            JSON.stringify(selectedStore[0].id),
          );
        }
        await setIsLogged(true);
      }
      await setLoading(false);
    } catch (error) {
      setLoading(false);
      const status = error.response.status;
      if (status === 401 || status === 403) {
        deleteToken();
        window.location.reload();
      }
      console.error(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      {!isLogged ? (
        <Routes>
          <Route exact path="/" element={<Entry />} />
          <Route exact path="/signup/" element={<SignUp />} />
          <Route path="*" element={<NotFound />} />
          <Route exact path="/password-reset/" element={<PasswordReset />} />
          <Route
            exact
            path="/password-reset/confirm/"
            element={<ConfirmPasswordReset />}
          />
        </Routes>
      ) : isTemporaryCreation === false ? (
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/pickup/" element={<Pickup />} />
          <Route exact path="/delivery/" element={<Delivery />} />
          <Route exact path="/inventory/" element={<Inventory />} />
          <Route exact path="/order/:id/" element={<OrderConfirmation />} />
          <Route exact path="/profile/" element={<Profile />} />
          <Route exact path="/password-change/" element={<PasswordChange />} />
          <Route exact path="/suscription/" element={<Suscription />} />
          <Route exact path="/digital/" element={<Digital />} />
          <Route exact path="/digital/:month/" element={<Digital />} />
          <Route
            exact
            path="/stores/:id/publications/"
            element={<InnerDigital />}
          />
          <Route exact path="/shoppers/" element={<Shoppers />} />
          <Route exact path="/coupons/" element={<Coupons />} />
          <Route
            exact
            path="/restaurant-orders/"
            element={<RestaurantOrders />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/" element={<BusinessForm />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};
