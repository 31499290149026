import React from 'react';
import { render } from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { configSentry } from './utils/configSentry';
import rootReducer from './reducer';
import { Router } from './routes/Router';
import ErrorBoundary from './ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import translationsConfig from './services/i18n/';
import { colors } from './constants';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.red,
    },
    secondary: {
      main: colors.white,
    },
  },
});

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    font-size: 16px;
  }

  figure, p, h1, h2, h3, h4, h5 {
    margin: 0;
  }
`;

const store = configureStore({ reducer: rootReducer });
const isLogged = store.getState().user.token !== '';

if (process.env.NODE_ENV !== 'development') {
  configSentry();
}
translationsConfig();

render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RC_V3_SITE_KEY}>
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <React.StrictMode>
            <GlobalStyle />
            <Router isLogged={isLogged} />
          </React.StrictMode>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  </GoogleReCaptchaProvider>,
  document.getElementById('shapya-b2b'),
);

serviceWorker.unregister();
