import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../constants';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const InvoiceAnchor = styled.a`
  color: ${colors.red};
  &:hover {
    cursor: pointer;
  }
`;
