import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import { InventoryTableTop } from './InventoryTableTop';
import { InventoryTableContent } from './InventoryTableContent';
import { InfoInventoryTableHead } from './InfoInventoryTableHead';
import InputSearchInventoryTable from './InputSearchInventoryTable';
import { LoadingInventoryTable } from './LoadingInventoryTable';
import {
  StoreInvDowload,
  StoreInvDowloading,
  BaseInvDowload,
  BaseInvDowloading,
  SendInvToEmail,
  SendInvEmailing,
  SendInvToEmailModal,
} from '../../shared/InvDownload';
import {
  useStyles,
  PaginatorContent,
  CtaWrapper,
} from './InfoInventoryTable.styled';
import {
  updateLastStoreInventory,
  downloadStoreInventory,
  updateLastBaseInventory,
  downloadBaseInventory,
} from '../../api/inventories';
import { getToken } from '../../services/auth';

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitedFilter = orderBy.split('.');
    if (a[splitedFilter[0]] === null || b[splitedFilter[0]] === null) {
      return 0;
    }

    if (
      b[splitedFilter[0]][splitedFilter[1]] <
      a[splitedFilter[0]][splitedFilter[1]]
    ) {
      return -1;
    }
    if (
      b[splitedFilter[0]][splitedFilter[1]] >
      a[splitedFilter[0]][splitedFilter[1]]
    ) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const InfoInventoryTable = ({
  loading,
  pageChange,
  showInventory,
  setShowInventory,
  productSkuParam,
  setProductSkuParam,
  productNameParam,
  setProductNameParam,
  categoryIdParam,
  setCategoryIdParam,
}) => {
  const classes = useStyles();
  const products = useSelector((state) => state.products);
  const storeId = useSelector((state) => state.store.id);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');

  const [storeInventaryDownloading, setStoreInventaryDownloading] =
    React.useState(false);
  const [baseInventaryDownloading, setBaseInventaryDownloading] =
    React.useState(false);
  const [sendInventaryEmailing, setSendInventaryEmailing] =
    React.useState(false);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, page) => {
    event.preventDefault();
    pageChange(page);
  };

  const pagesCount = products.count > 25 ? Math.ceil(products.count / 25) : 1;

  const handleClickUpdateStoreInventory = () => {
    updateLastStoreInventory({
      id: storeId,
      token: getToken(),
    });
  };

  const handleClickDownloadStoreInventory = () => {
    setStoreInventaryDownloading(true);
    handleClickUpdateStoreInventory();
    const downloadStoreInventoryInterval = setInterval(() => {
      downloadStoreInventory({
        id: storeId,
        token: getToken(),
      }).then((responseDownload) => {
        if (responseDownload.status !== 'CREATING FILE') {
          clearInterval(downloadStoreInventoryInterval);
          setStoreInventaryDownloading(false);
        }
        if (responseDownload.file !== null) {
          window.open(`${responseDownload.file}`, '_parent');
          clearInterval(downloadStoreInventoryInterval);
          setStoreInventaryDownloading(false);
        }
      });
    }, 5000);
  };

  const handleClickUpdateBaseInventory = () => {
    updateLastBaseInventory({
      id: storeId,
      token: getToken(),
    });
  };

  const handleClickDownloadBaseInventory = () => {
    setBaseInventaryDownloading(true);
    handleClickUpdateBaseInventory();
    const downloadBaseInventoryInterval = setInterval(() => {
      downloadBaseInventory({
        id: storeId,
        token: getToken(),
      }).then((responseBaseDownload) => {
        if (responseBaseDownload.status !== 'CREATING FILE') {
          clearInterval(downloadBaseInventoryInterval);
          setBaseInventaryDownloading(false);
        }
        if (responseBaseDownload.file !== null) {
          window.open(`${responseBaseDownload.file}`, '_parent');
          clearInterval(downloadBaseInventoryInterval);
          setBaseInventaryDownloading(false);
        }
      });
    }, 10000);
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSendInventoryToEmail = () => {
    handleClickOpen();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <InventoryTableTop />
        <CtaWrapper>
          {!storeInventaryDownloading ? (
            <StoreInvDowload onClick={handleClickDownloadStoreInventory} />
          ) : null}
          {storeInventaryDownloading ? <StoreInvDowloading /> : null}
          {!baseInventaryDownloading ? (
            <BaseInvDowload onClick={handleClickDownloadBaseInventory} />
          ) : null}
          {baseInventaryDownloading ? <BaseInvDowloading /> : null}
          {!sendInventaryEmailing ? (
            <SendInvToEmail onClick={handleClickSendInventoryToEmail} />
          ) : null}
          {sendInventaryEmailing ? <SendInvEmailing /> : null}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <SendInvToEmailModal handleClose={handleClose} />
          </Dialog>
        </CtaWrapper>
        <InputSearchInventoryTable
          showInventory={showInventory}
          setShowInventory={setShowInventory}
          productSkuParam={productSkuParam}
          setProductSkuParam={setProductSkuParam}
          productNameParam={productNameParam}
          setProductNameParam={setProductNameParam}
          categoryIdParam={categoryIdParam}
          setCategoryIdParam={setCategoryIdParam}
        />
        <TableContainer>
          <Table
            id="InventoryTable"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            onRequestSort={handleRequestSort}>
            <InfoInventoryTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={products.results.length}
            />
            {products.results.length && !loading ? (
              <TableBody>
                {stableSort(
                  products.results,
                  getComparator(order, orderBy),
                ).map((inventoryProduct, index) => (
                  <InventoryTableContent
                    key={`${inventoryProduct.reference}-${index}`}
                    index={index}
                    inventoryProduct={inventoryProduct}
                  />
                ))}
              </TableBody>
            ) : (
              <LoadingInventoryTable loading={loading} />
            )}
          </Table>
        </TableContainer>
        <PaginatorContent>
          <Pagination count={pagesCount} onChange={handlePageChange} />
        </PaginatorContent>
      </Paper>
    </div>
  );
};

InfoInventoryTable.propTypes = {
  loading: PropTypes.bool,
  pageChange: PropTypes.func,
  showInventory: PropTypes.string,
  setShowInventory: PropTypes.func,
  productSkuParam: PropTypes.string,
  setProductSkuParam: PropTypes.func,
  productNameParam: PropTypes.string,
  setProductNameParam: PropTypes.func,
  categoryIdParam: PropTypes.string,
  setCategoryIdParam: PropTypes.func,
};

InfoInventoryTable.defaultProps = {
  loading: false,
  pageChange: () => {},
  showInventory: '',
  setShowInventory: () => {},
  productSkuParam: '',
  setProductSkuParam: () => {},
  productNameParam: '',
  setProductNameParam: () => {},
  categoryIdParam: '',
  setCategoryIdParam: () => {},
};
