import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { createCoupons } from '../../../api/coupons';
import { createCoupon } from '../../../features/coupons/couponsSlice';
import { Button, CancelButton } from '../../../constants/base';
import {
  useStyles,
  ModalWrapper,
  Title,
  TextField,
  Form,
  ErrorText,
  ButtonWrapper,
  TwoColumns,
} from './CouponsModal.styled';
import { getToken, deleteToken } from '../../../services/auth';

export const CouponsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const storeId = useSelector((state) => state.store.id);

  const [status, setStatus] = useState(false);
  const [error, setError] = useState({
    code: [],
    description: [],
    discount: [],
    initDate: [],
    endDate: [],
    status: [],
    nonFieldErrors: [],
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const theCoupon = await createCoupons({
        id: storeId,
        token: getToken(),
        params: {
          code: event.target.code.value,
          discount: event.target.discount.value,
          description: event.target.description.value,
          initDate: event.target.initDate.value,
          endDate: event.target.endDate.value,
          status: status ? 1 : 0,
        },
      });
      await dispatch(createCoupon(theCoupon));
      await setError({
        code: [],
        description: [],
        discount: [],
        initDate: [],
        endDate: [],
        status: [],
        nonFieldErrors: [],
      });
      await props.handleClose(event);
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      if (error.response.status === 400) {
        const errData = error.response.data;
        setError({
          code: errData.code || [],
          discount: errData.discount || [],
          description: errData.description || [],
          initDate: errData.initDate || [],
          endDate: errData.endDate || [],
          status: errData.status || [],
          nonFieldErrors: errData.nonFieldErrors || [],
        });
        return;
      }
      console.error(error);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}>
      <Fade in={props.open}>
        <ModalWrapper>
          <Title id="transition-modal-title">
            {props.data.code === ''
              ? t('Coupons.CouponsModal.title')
              : t('Coupons.CouponsModal.edit.title')}
          </Title>
          <Form onSubmit={onSubmit}>
            <TwoColumns>
              <TextField
                error={error.code.length}
                helperText={error.code[0]}
                label={t('Coupons.CouponsModal.form.code.label')}
                type="text"
                placeholder={t('Coupons.CouponsModal.form.code.label')}
                defaultValue={props.data.code}
                name="code"
              />
              <TextField
                error={error.discount.length}
                helperText={error.discount[0]}
                label={t('Coupons.CouponsModal.form.discount.label')}
                type="number"
                placeholder={t('Coupons.CouponsModal.form.discount.label')}
                defaultValue={props.data.discount}
                name="discount"
              />
            </TwoColumns>
            <TextField
              error={error.description.length}
              helperText={error.description[0]}
              label={t('Coupons.CouponsModal.form.description.label')}
              type="text"
              placeholder={t('Coupons.CouponsModal.form.description.label')}
              defaultValue={props.data.description}
              name="description"
            />
            <TwoColumns>
              <TextField
                error={error.initDate.length}
                helperText={error.initDate[0]}
                id="initDate"
                name="initDate"
                label={t('Coupons.CouponsModal.form.initDate.label')}
                type="datetime-local"
                defaultValue={
                  props.data.initDate !== ''
                    ? dayjs(new window.Date(props.data.initDate)).format(
                        'YYYY-MM-DD HH:MM',
                      )
                    : ''
                }
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                error={error.endDate.length}
                helperText={error.endDate[0]}
                id="endDate"
                name="endDate"
                label={t('Coupons.CouponsModal.form.endDate.label')}
                type="datetime-local"
                defaultValue={
                  props.data.endDate !== ''
                    ? dayjs(new window.Date(props.data.endDate)).format(
                        'YYYY-MM-DD HH:MM',
                      )
                    : ''
                }
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
            </TwoColumns>
            <FormControlLabel
              label={t(
                `Coupons.CouponsModal.form.status.${status ? 1 : 0}.label`,
              )}
              control={
                <Switch
                  checked={status}
                  onChange={() => setStatus(!status)}
                  name="status"
                  color="primary"
                />
              }
            />
            {error.nonFieldErrors.length > 0 && (
              <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
            )}
            <ButtonWrapper>
              <CancelButton onClick={props.handleClose}>
                {t('Coupons.CouponsModal.cancel')}
              </CancelButton>
              <Button type="submit">{t('Coupons.CouponsModal.cta')}</Button>
            </ButtonWrapper>
          </Form>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

CouponsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    initDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
};

CouponsModal.defaultProps = {
  open: false,
  data: {
    id: 0,
    code: '',
    description: '',
    discount: 0,
    link: '',
    initDate: '',
    endDate: '',
    createdAt: '',
    status: '',
  },
};
