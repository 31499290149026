import styled from 'styled-components';
import { styles, colors, breakpoints } from '../../constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 98px);
`;

export const Square = styled.div`
  width: 100%;
  box-shadow: ${styles.shadow};
  max-width: 500px;
  background-color: ${colors.white};
  display: grid;
  grid-gap: 16px;
  padding: 0 1rem;
  @media ${breakpoints.tablet} {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    max-width: 700px;
  }
`;

export const Left = styled.div`
  background-color: ${colors.red};
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Right = styled.div`
  background-color: ${colors.white};
  padding: 32px;
  h2 {
    color: ${colors.red};
    margin: 32px 0 16px 0;
  }
`;

export const ContextSelector = styled.button`
  background: none;
  outline: none;
  border: none;
  float: right;
  font-size: 16px;
  color: ${colors.gray};
  &:hover {
    cursor: pointer;
  }
`;
