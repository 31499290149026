import styled from 'styled-components';
import { styles, colors, breakpoints } from '../../constants';

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: ${styles.shadow};
  box-sizing: border-box;
`;

export const Figure = styled.figure`
  width: 200px;
`;

export const Img = styled.img`
  width: 100%;
`;

export const List = styled.ul`
  display: none;
  @media ${breakpoints.tablet} {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export const ListItem = styled.li`
  padding: 10px;
  &:hover {
    a {
      color: ${colors.red};
    }
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
  color: ${colors.black};
`;
