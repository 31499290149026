import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BtnShape, BtnText } from './InvDowload.styled';
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Box, Grid } from '@material-ui/core';

export const BaseInvDowload = ({ onClick }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid direction="column">
      <div>
        <BtnShape onClick={onClick}>
          <SaveAltIcon />
          <BtnText>{t('Inventory.buttonDownload.baseInventory')}</BtnText>
        </BtnShape>
      </div>
      <Box mt={1}>
        <Chip
          icon={<InfoIcon />}
          label="More info"
          color="primary"
          variant="outlined"
          size="small"
          onClick={handleClickOpen}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('Inventory.baseInventoryDownload.dialogInfoTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Inventory.baseInventoryDownload.dialogInfoContent')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('Inventory.buttonOk')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

BaseInvDowload.propTypes = {
  onClick: PropTypes.func,
};

export const BaseInvDowloading = () => {
  const { t } = useTranslation();
  return (
    <Grid direction="column">
      <BtnShape disabled>
        <CircularProgress color="secondary" size={20} thickness={7} />
        <BtnText>{t('Inventory.buttonDownloading')}</BtnText>
      </BtnShape>
      <Box mt={1}>&nbsp;</Box>
    </Grid>
  );
};
