import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
}));

const searchOrderId = () => {
  let input, filter, table, tr, td, i, txtValue;
  input = document.getElementById('inputSearchOrderId');
  filter = input.value.toUpperCase();
  table = document.getElementById('DeliveryTable');
  tr = table.getElementsByTagName('tr');
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName('th')[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = '';
      } else {
        tr[i].style.display = 'none';
      }
    }
  }
};

const searchClient = () => {
  let input, filter, table, tr, td, i, txtValue;
  input = document.getElementById('inputSearchClient');
  filter = input.value.toUpperCase();
  table = document.getElementById('DeliveryTable');
  tr = table.getElementsByTagName('tr');
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName('td')[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = '';
      } else {
        tr[i].style.display = 'none';
      }
    }
  }
};

const searchStatus = () => {
  let input, filter, table, tr, td, i, txtValue;
  input = document.getElementById('inputSearchStatus');
  filter = input.value.toUpperCase();
  table = document.getElementById('DeliveryTable');
  tr = table.getElementsByTagName('tr');
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName('td')[2];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = '';
      } else {
        tr[i].style.display = 'none';
      }
    }
  }
};

export default function InputWithIcon() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <div className={classes.margin}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <SearchIcon />
          </Grid>
          <Grid item>
            <TextField
              id="inputSearchOrderId"
              label={t('Delivery.inputsearch.orderid')}
              className={classes.textField}
              onChange={searchOrderId}
              InputLabelProps={{ style: { fontSize: 12 } }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="inputSearchClient"
              label={t('Delivery.inputsearch.client')}
              className={classes.textField}
              onChange={searchClient}
              InputLabelProps={{ style: { fontSize: 12 } }}
            />
          </Grid>
          <Grid item>
            <TextField
              id="inputSearchStatus"
              label={t('Delivery.inputsearch.status')}
              className={classes.textField}
              onChange={searchStatus}
              InputLabelProps={{ style: { fontSize: 12 } }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
