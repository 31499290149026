import styled from 'styled-components';

export const Content = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(216 216 216 / 52%);
  h2 {
    margin-bottom: 32px;
  }
`;
