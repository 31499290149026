import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Loader } from '../Loader/Loader';
import { EmptyContainer, Empty } from './LoadingRestaurantOrdersTable.styled';

export const LoadingRestaurantOrdersTable = ({ loading }) => {
  const { t } = useTranslation();

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell id={1} scope="row" colSpan={10}>
        <EmptyContainer>
          {loading ? <Loader /> : null}
          <Empty>
            {loading
              ? t('RestaurantOrders.table.loading')
              : t('RestaurantOrders.table.empty')}
          </Empty>
        </EmptyContainer>
      </TableCell>
    </TableRow>
  );
};

LoadingRestaurantOrdersTable.propTypes = {
  loading: PropTypes.bool,
};
