import styled from 'styled-components';

export const Title = styled.h2`
  font-size: 27px;
  text-align: center;
  margin-bottom: 16px;
`;

export const InfoWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
`;

export const TableWrapper = styled.div`
  margin-top: 16px;
`;
