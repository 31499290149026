export default {
  red: '#C43C42',
  red01: '#CA4E54',
  lightred: '#F3D8D9',
  green: '#C2CD23',
  gray: '#808080',
  gray01: '#E5E5E5',
  lightgray: '#C7C7C7',
  black: '#000',
  black01: '#1F1F1F',
  white: '#FFF',
};
