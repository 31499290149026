import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Button,
  SmallCancelButton,
  SmallButton,
} from '../../../constants/base';
import { useStyles, ModalWrapper, Title, Form } from './ShoppersModal.styled';
import { Divider, IconButton, OutlinedInput } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  searchShoppers,
  createShopperRequest,
  createShoppers,
} from '../../../api/exclusiveShoppers';
import { getToken, deleteToken } from '../../../services/auth';
import { createShopper } from '../../../features/shoppers/shoppersSlice';

export const ShoppersModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeId = useSelector((state) => state.store.id);
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [jobAssignment, setJobAssignment] = useState('');
  const [verify, setVerify] = useState('');
  const [shapyerRequestEmailSent, setShapyerRequestEmailSent] = useState(0);

  const handleSearchExclusiveShopper = async (event) => {
    event.preventDefault();
    setVerify('');
    setShapyerRequestEmailSent(0);

    const res = await searchShoppers({
      id: storeId,
      token: getToken(),
      email: searchValue,
    });

    try {
      if (res.count === 0) {
        setVerify(0);
      }
      if (res.count >= 1) {
        setVerify(1);
        setUserId(res.results[0].id);
        setFirstName(res.results[0].firstName);
        setLastName(res.results[0].lastName);
        setEmail(res.results[0].email);
        setDateTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [error, setError] = useState({
    user: [],
    active: [],
    branch_store: [],
    order_type: [],
  });

  const onCreate = async (event) => {
    event.preventDefault();

    try {
      const theShopper = await createShoppers({
        id: storeId,
        token: getToken(),
        params: {
          user: userId,
          active: 'True',
          branch_store: storeId.toString(),
          order_type: jobAssignment,
        },
      });
      await dispatch(createShopper(theShopper));
      await setError({
        user: [],
        active: [],
        branch_store: [],
        order_type: [],
      });
      await onClose(event, setSearchValue(''), setVerify(''));
      setSearchValue('');
      setVerify('');
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      if (error.response.status === 400) {
        const errData = error.response.data;
        setError({
          user: errData.user || [],
          active: errData.active || [],
          branch_store: errData.branch_store || [],
          order_type: errData.order_type || [],
        });
        return;
      }
      console.error(error);
    }
  };

  const handleExclusiveShapyerProcessRegistration = async (event) => {
    event.preventDefault();

    const res = await createShopperRequest({
      id: storeId,
      token: getToken(),
      params: {
        email: searchValue,
      },
    });

    try {
      if (res) {
        setShapyerRequestEmailSent(1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = (event) => {
    setUserId('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setDateTime('');
    setVerify('');
    props.handleClose(event);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}>
      <Fade in={props.open}>
        <ModalWrapper>
          <div className={classes.iconBox}>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Title id="transition-modal-title" className={classes.modalTitle}>
            {t('Shoppers.shoppersModal.addShapyer')}
          </Title>

          <Form onSubmit={onCreate}>
            <div>
              <label className={classes.emailLabel}>
                {t('Shoppers.shoppersModal.email')}
              </label>
              <OutlinedInput
                margin="dense"
                className={classes.outlinedInput}
                type="email"
                onChange={(e) => setSearchValue(e.target.value)}
                required
              />
              <Button
                className={classes.searchbutton}
                onClick={handleSearchExclusiveShopper}>
                {t('Shoppers.shoppersModal.searchButton')}
              </Button>
            </div>
            {verify === 0 ? (
              <>
                <Divider className={classes.modalDivider} />
                {shapyerRequestEmailSent === 0 ? (
                  <>
                    <p className={classes.usernotfound}>
                      {t('Shoppers.shoppersModal.userNotFound')}
                    </p>
                    <p className={classes.exclusiveShapyerRegister}>
                      {t('Shoppers.shoppersModal.exclusiveShapyerRegister')}{' '}
                      <b>{searchValue}</b>?
                    </p>
                    <div className={classes.exclusiveShapyerRegisterButtons}>
                      <SmallCancelButton
                        className={classes.buttonCancel}
                        onClick={onClose}>
                        {t('Shoppers.shoppersModal.noButton')}
                      </SmallCancelButton>
                      <SmallButton
                        className={classes.buttonAdd}
                        onClick={handleExclusiveShapyerProcessRegistration}>
                        {t('Shoppers.shoppersModal.yesButton')}
                      </SmallButton>
                    </div>
                  </>
                ) : (
                  <p className={classes.emailSent}>
                    {t(
                      'Shoppers.shoppersModal.exclusiveShapyerRegisterEmailSent',
                    )}
                  </p>
                )}
              </>
            ) : verify === 1 ? (
              <>
                <Divider className={classes.modalDivider} />
                <div className={classes.shapyerdatamaingrid}>
                  <div className={classes.shapyerdatabox}>
                    <p className={classes.shapyerdatatitle}>
                      {t('Shoppers.shoppersModal.shapyerData')}
                    </p>
                    <p className={classes.shapyerName}>
                      {firstName} {lastName}
                    </p>
                    <p className={classes.shapyerEmail}>{email}</p>
                    <p className={classes.shapyerSignInDate}>
                      {t('Shoppers.shoppersModal.signInDate')} {dateTime}
                    </p>
                  </div>
                  <div className={classes.jobAssignmentBox}>
                    <p className={classes.jobAssignmentTitle}>
                      {t('Shoppers.shoppersModal.jobAssignment')}
                    </p>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="radiogroupjobAssignment"
                        name="radiogroupjobAssignment">
                        <FormControlLabel
                          value="delivery"
                          onClick={(e) => setJobAssignment(e.target.value)}
                          control={
                            <Radio
                              color="primary"
                              icon={<span className={classes.icon} />}
                              required={true}
                              checkedIcon={
                                <span
                                  className={clsx(
                                    classes.icon,
                                    classes.checkedIcon,
                                  )}
                                />
                              }
                            />
                          }
                          label={
                            <span className={classes.radioLabel}>
                              {t('Shoppers.shoppersModal.delivery')}
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="pickup"
                          onClick={(e) => setJobAssignment(e.target.value)}
                          control={
                            <Radio
                              color="primary"
                              icon={<span className={classes.icon} />}
                              required={true}
                              checkedIcon={
                                <span
                                  className={clsx(
                                    classes.icon,
                                    classes.checkedIcon,
                                  )}
                                />
                              }
                            />
                          }
                          label={
                            <span className={classes.radioLabel}>
                              {t('Shoppers.shoppersModal.pickup')}
                            </span>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className={classes.buttonsActionsDiv}>
                  <div>
                    <SmallCancelButton
                      className={classes.buttonCancel}
                      onClick={onClose}>
                      {t('Shoppers.shoppersModal.cancelButton')}
                    </SmallCancelButton>
                    <SmallButton className={classes.buttonAdd} type="submit">
                      {t('Shoppers.shoppersModal.addButton')}
                    </SmallButton>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <br />
                <br />
              </div>
            )}
          </Form>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

ShoppersModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
