import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { InfoSquare } from '../../components/InfoSquare/InfoSquare';
import { InfoTable } from '../../components/InfoTable/InfoTable';
import { InfoRestaurantOrdersTable } from '../../components/InfoRestaurantOrdersTable/InfoRestaurantOrdersTable';
import {
  getBranchStoreOrders,
  getBranchStoreSales,
} from '../../api/branchStores';
import { getRestaurantOrders } from '../../api/orderProducts';
import { getToken, deleteToken } from '../../services/auth';
import { setOrders } from '../../features/orders/ordersSlice';
import { InfoWrapper, TableWrapper, Title } from './Dashboard.styled';
import { calculateOrdersCost } from '../../utils/calculateOrdersCost';

export const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [costs, setCosts] = useState({
    dailySales: 0,
    weeklySales: 0,
    monthSales: 0,
    previousDailySales: 0,
    previousWeeklySales: 0,
    previousMonthSales: 0,
    currency: {
      symbol: '$',
      code: 'USD',
    },
  });

  const storeId = useSelector((state) => state.store.id);
  const storeCategory = useSelector((state) => {
    if (
      state.store.store &&
      state.store.store.storeCategory &&
      state.store.store.storeCategory.name
    ) {
      return state.store.store.storeCategory.name;
    }
  });

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async (page = 1) => {
    try {
      setLoading(true);
      let params = {
        token: getToken(),
        id: storeId,
      };

      if (page > 1) {
        params = { ...params, page };
      }

      const orders =
        storeCategory !== 'Restaurants'
          ? await getBranchStoreOrders(params)
          : await getRestaurantOrders(params);
      await dispatch(setOrders(orders));
      await setLoading(false);
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
    if (page > 1) return;

    try {
      const sales = await getBranchStoreSales({
        id: storeId,
        params: {
          token: getToken(),
        },
      });
      await setCosts(sales);
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  const dailySales = calculateOrdersCost(
    costs.previousDailySales,
    costs.dailySales,
  );
  const weeklySales = calculateOrdersCost(
    costs.previousWeeklySales,
    costs.weeklySales,
  );
  const monthSales = calculateOrdersCost(
    costs.previousMonthSales,
    costs.monthSales,
  );

  return (
    <ContentWrapper>
      <Title>{t('Dashboard.title')}</Title>
      <InfoWrapper>
        <InfoSquare
          ammount={`${costs.currency.symbol} ${costs.dailySales.toFixed(2)}`}
          text={t('Dashboard.sales.daily')}
          percentage={dailySales.percentage.toFixed(2)}
          increase={dailySales.increase}
        />
        <InfoSquare
          ammount={`${costs.currency.symbol} ${costs.weeklySales.toFixed(2)}`}
          text={t('Dashboard.sales.weekly')}
          percentage={weeklySales.percentage.toFixed(2)}
          increase={weeklySales.increase}
        />
        <InfoSquare
          ammount={`${costs.currency.symbol} ${costs.monthSales.toFixed(2)}`}
          text={t('Dashboard.sales.monthly')}
          percentage={monthSales.percentage.toFixed(2)}
          increase={monthSales.increase}
        />
      </InfoWrapper>
      <TableWrapper>
        {storeCategory !== 'Restaurants' ? (
          <InfoTable loading={loading} pageChange={getOrders} />
        ) : (
          <InfoRestaurantOrdersTable loading={loading} pageChange={getOrders} />
        )}
      </TableWrapper>
    </ContentWrapper>
  );
};
