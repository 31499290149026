import styled from 'styled-components';

export const Empty = styled.p`
  font-size: 16px;
  text-align: center;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 16px;
  }
`;
