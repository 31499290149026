import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const availableStoresSlice = createSlice({
  name: 'availableStores',
  initialState,
  reducers: {
    setStoreList(state, { payload }) {
      return payload;
    },
  },
});

export const { setStoreList } = availableStoresSlice.actions;

export default availableStoresSlice.reducer;
