import styled from 'styled-components';
import colors from './colors';

export const Title = styled.h1`
  font-size: 32px;
  color: ${colors.white};
  text-align: center;
  margin: 0;
`;

export const Subtitle = styled.h2`
  font-size: 28px;
  color: ${colors.black};
  text-align: center;
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: 22px;
  color: ${colors.black};
  text-align: center;
  margin: 0;
`;

export const Button = styled.button`
  background-color: ${(props) =>
    props.disabled ? colors.lightgray : colors.red};
  padding: 14px 78px;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const CancelButton = styled.button`
  background-color: transparent;
  padding: 14px 78px;
  border: none;
  color: ${colors.red};
  border: solid 1px ${colors.red};
  font-size: 16px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const SmallButton = styled.button`
  background-color: ${colors.red};
  padding: 7px 35px;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  border-radius: 4px;
  max-width: 100px;
  &:hover {
    cursor: pointer;
  }
`;

export const SmallCancelButton = styled.button`
  background-color: transparent;
  padding: 7px 35px;
  border: none;
  color: ${colors.red};
  font-size: 16px;
  border-radius: 4px;
  max-width: 100px;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
  }
`;
