import styled from 'styled-components';
import { colors, styles, breakpoints } from '../../constants';

export const Square = styled.div`
  background-color: ${colors.white};
  box-shadow: ${styles.shadow};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  min-width: 100%;
  margin-bottom: 1rem;
  @media ${breakpoints.tablet} {
    min-width: 200px;
    margin: 0 auto 2rem auto;
  }
  @media ${breakpoints.laptop} {
    min-width: 300px;
  }
`;

export const Title = styled.h3`
  font-size: 28px;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  margin: 0;
  color: ${colors.gray};
  font-weight: bold;
`;

export const Percent = styled.p`
  color: ${(props) => (props.increase ? colors.green : colors.red)};
  font-size: 16px;
  font-weight: bold;
  svg {
    margin-right: 8px;
  }
`;
