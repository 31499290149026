import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { TopSection } from '../../shared/TopSection';
import { CouponsWrapper, TableWrapper } from './Coupons.styled';
import { CouponsTable } from '../../components/CouponsTable';
import { CouponsModal } from '../../components/CouponsTable';
import { setCoupons } from '../../features/coupons/couponsSlice';
import { retreiveCoupons } from '../../api/coupons';
import { getToken, deleteToken } from '../../services/auth';

export const Coupons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.store.id);

  const [modalOpen, setModalOpen] = useState(false);
  const [couponsData, setCouponsData] = useState({
    code: '',
    description: '',
    discount: 10,
    initDate: '',
    endDate: '',
    createdAt: '',
    status: 0,
    author: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  useEffect(() => {
    retreiveCoupons({
      id: storeId,
      token: getToken(),
    })
      .then((res) => dispatch(setCoupons(res)))
      .catch((error) => {
        if (error.response.status === 401) {
          deleteToken();
          return;
        }
        console.error(error);
      });
  }, []);

  const handleOpen = (event, data = undefined) => {
    event.preventDefault();
    setModalOpen(true);
    if (data) setCouponsData(data);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setCouponsData({
      code: '',
      description: '',
      discount: 10,
      link: '',
      initDate: '',
      endDate: '',
      createdAt: '',
      status: '',
      author: {
        firstName: '',
        lastName: '',
        email: '',
      },
    });
    setModalOpen(false);
  };

  return (
    <ContentWrapper>
      <CouponsWrapper>
        <TopSection title={t('Coupons.title')} onClick={handleOpen} />
        <CouponsModal
          open={modalOpen}
          handleClose={handleClose}
          data={couponsData}
        />
        <TableWrapper>
          <CouponsTable loading={false} pageChange={() => {}} />
        </TableWrapper>
      </CouponsWrapper>
    </ContentWrapper>
  );
};
