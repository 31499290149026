import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const inventoriesSlice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {
    setInventories(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateProductBasicInfo(state, { payload }) {
      const inventories = [];
      inventories.push(payload);
      for (let i = 0; i <= state.results.length - 1; i++) {
        if (
          state.results[i].storeBranchProductId != payload.storeBranchProductId
        ) {
          inventories.push(state.results[i]);
        }
      }
      return { ...state, results: inventories };
    },
  },
});

export const { setInventories, updateProductBasicInfo } =
  inventoriesSlice.actions;

export default inventoriesSlice.reducer;
