import styled from 'styled-components';
import { TextField as Field } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../constants';

export const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emailLabel: {
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '700',
    marginRight: '15px',
  },
  outlinedInput: {
    width: '414px',
    height: '50px',
  },
  searchbutton: {
    padding: '0',
    width: '118px',
    height: '50px',
    color: '#F7F7F7',
    backgroundColor: '#4F4F4F',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    marginLeft: '16px',
    fontSize: '14px',
    fontWeight: '700',
  },
  modalDivider: {
    width: '92%',
    marginTop: '43px',
  },
  iconBox: {
    position: 'absolute',
    width: '736px',
    textAlign: 'right',
  },
  modalTitle: {
    marginTop: '27px',
  },
  usernotfound: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#C43C42',
    lineHeight: '20px',
    marginTop: '42px',
    marginBottom: '20px',
  },
  exclusiveShapyerRegister: {
    maxWidth: '600px',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32px',
  },
  exclusiveShapyerRegisterButtons: {
    marginBottom: '50px',
  },
  emailSent: {
    maxWidth: '600px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    color: '#C43C42',
    lineHeight: '32px',
    marginTop: '42px',
    marginBottom: '50px',
  },
  shapyerdatamaingrid: {
    marginTop: '53px',
    display: 'flex',
  },
  shapyerName: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '20px',
    marginTop: '32px',
    color: '#000',
  },
  shapyerEmail: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '13px',
    color: '#000',
  },
  shapyerSignInDate: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '20px',
    marginTop: '13px',
    color: '#000',
  },

  jobAssignmentBox: {
    marginLeft: '50.67px',
  },

  shapyerdatatitle: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '20px',
    color: '#C43C42',
  },

  jobAssignmentTitle: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '20px',
    color: '#C43C42',
    marginBottom: '41px',
  },

  buttonCancel: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
  },
  buttonAdd: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
    borderRadius: '15px',
  },

  icon: {
    borderRadius: '5px',
    width: 20,
    height: 20,
    border: 'solid 1.2px #828282',
    backgroundColor: '#fff',
    'input:hover ~ &': {
      backgroundColor: '#f2f2f2',
    },
  },
  checkedIcon: {
    backgroundColor: '#C43C42',
    border: 'solid 1.2px #C43C42',
    'input:hover ~ &': {
      backgroundColor: '#C43C42',
    },
  },
  radioLabel: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
    color: '#000',
  },
  buttonsActionsDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '42px',
    marginBottom: '18px',
    marginRight: '24px',
  },
}));

export const ModalWrapper = styled.div`
  background-color: ${colors.white};
  outline: none;
  padding: 24px;
  border-radius: 8px;
  width: 784px;
  font-family: 'Montserrat';
`;

export const Title = styled.h2`
  color: ${colors.black};
  font-size: 24px;
  margin-bottom: 27px;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 16px;
  }
`;

export const TextField = styled(Field)`
  width: 100%;
  margin: 8px 0 !important;
`;

export const ErrorText = styled.p`
  color: ${colors.red};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  justify-content: space-between;
`;
