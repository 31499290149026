import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const AcceptOrderButton = styled.button`
  text-decoration: none;
  text-transform: none;
  width: 130px;
  height: 41px;
  background-color: #c43c42;
  color: #fff;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 700;
  border: none;
  margin-right: 10px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
  &:active {
    opacity: 0.6;
  }
`;

export const ConfirmOrderCancelButton = styled.button`
  text-decoration: none;
  text-transform: none;
  width: 93px;
  height: 41px;
  background-color: #fff;
  color: #c43c42;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 700;
  border: none;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
  &:active {
    background-color: rgba(196, 60, 66, 0.2);
  }
`;

export const ConfirmOrderAcceptButton = styled.button`
  text-decoration: none;
  text-transform: none;
  width: 93px;
  height: 41px;
  background-color: #c43c42;
  color: #fff;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 700;
  border: none;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
  &:active {
    opacity: 0.6;
  }
`;

export const CancelOrderButton = styled.button`
  text-decoration: none;
  text-transform: none;
  width: 130px;
  height: 41px;
  background-color: #c43c42;
  color: #fff;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 700;
  border: none;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
  &:active {
    opacity: 0.6;
  }
`;

export const CancelOrderCancelButton = styled.button`
  text-decoration: none;
  text-transform: none;
  width: 93px;
  height: 41px;
  background-color: #fff;
  color: #c43c42;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 700;
  border: none;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
  &:active {
    background-color: rgba(196, 60, 66, 0.2);
  }
`;

export const CancelOrderAcceptButton = styled.button`
  text-decoration: none;
  text-transform: none;
  width: 93px;
  height: 41px;
  background-color: #c43c42;
  color: #fff;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 700;
  border: none;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
  &:active {
    opacity: 0.6;
  }
`;

export const useStyles = makeStyles((theme) => ({
  divCancelOrderButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
  },
  orderConfirmDialogTitle: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  orderConfirmDialogActions: {
    marginTop: '16px',
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    color: '#c43c42',
    marginBottom: '16px',
  },
  dialogTitleLineOne: { marginBottom: '16px' },
  dialogTitleLineTwo: { marginBottom: '32px' },
  dialogTitleLineThree: { marginTop: '16px', marginBottom: '32px' },
  orderConfirmTextDiv: {
    position: 'absolute',
    marginTop: '15px',
    marginBottom: '15px',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    color: '#1f1f1f',
    maxWidth: '270px',
    textAlign: 'center',
  },

  dialogContentDiv: {
    marginBottom: '16px',
  },
  orderCancelObservationTitle: {
    paddingTop: '20px',
    paddingBottom: '20px',
    fontWeight: '700',
  },

  orderCancelTextDiv: {
    position: 'absolute',
    marginTop: '15px',
    marginBottom: '15px',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    color: '#1f1f1f',
    maxWidth: '270px',
    textAlign: 'center',
  },
}));
