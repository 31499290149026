import styled from 'styled-components';
import { colors, styles, breakpoints } from '../../constants';

export const Content = styled.button`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${colors.white};
  box-shadow: ${styles.shadow};
  border: none;
  padding: 0;
  text-align: left;
  @media ${breakpoints.mobileL} {
    width: 100%;
  }
`;

export const Figure = styled.figure`
  width: 100%;
  max-height: 200px;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Bottom = styled.div`
  padding: 16px;
`;

export const Title = styled.h4`
  font-size: 20px;
  margin-bottom: 4px;
  word-break: break-word;
  color: ${colors.black};
`;

export const Date = styled.p`
  font-size: 0.875rem;
  color: ${colors.black};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Anchor = styled.a`
  text-decoration: none;
  color: ${colors.lightgray};
  transition: ease all 300ms;
  margin-top: 8px;
  &:hover {
    color: ${colors.red};
  }
  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const AdminWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 150px 150px;
  grid-gap: 8px;
  margin-bottom: 8px;
`;
