import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '../../components/Header/Header';
import { Layout } from './BaseLayout.styled';

export const BaseLayout = ({ children, isLogged }) => {
  return (
    <Layout>
      <Header isLogged={isLogged} />
      {children}
    </Layout>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isLogged: PropTypes.bool,
};

BaseLayout.defaultProps = {
  isLogged: false,
};
