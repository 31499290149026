import styled from 'styled-components';
import { colors } from '../../constants';

export const AltButton = styled.button`
  color: ${colors.red};
  background-color: transparent;
  border: none;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const DeleteButton = styled.button`
  color: ${colors.red};
  background-color: transparent;
  border: none;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
