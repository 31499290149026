import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import { DeactivateDialog } from './DeactivateDialog';
import { ReactivateDialog } from './ReactivateDialog';
import { editCoupon, deleteCoupon } from '../../features/coupons/couponsSlice';
import { updateCoupons, deleteCoupons } from '../../api/coupons';
import { getToken, deleteToken } from '../../services/auth';
import { AltButton, DeleteButton } from './CouponsTableContent.styled';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CouponsTableContent = ({ coupon, index }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storeId = useSelector((state) => state.store.id);

  const labelId = `enhanced-table-checkbox-${index}`;
  const [dialogOpen, setDialog] = useState(false);
  const [error, setError] = useState({
    initDate: [],
    endDate: [],
    nonFieldErrors: [],
  });

  const handleDeactivation = async () => {
    try {
      const updatedCoupon = await updateCoupons({
        id: storeId,
        token: getToken(),
        coupon: coupon.id,
        params: {
          code: coupon.code,
          description: coupon.description,
          discount: coupon.discount,
          status: 0,
          initDate: coupon.initDate,
          endDate: coupon.endDate,
        },
      });
      await dispatch(editCoupon(updatedCoupon));
      await setDialog(false);
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  const handleReactivation = async (event) => {
    event.preventDefault();

    try {
      const updatedCoupon = await updateCoupons({
        id: storeId,
        token: getToken(),
        coupon: coupon.id,
        params: {
          code: coupon.code,
          description: coupon.description,
          discount: coupon.discount,
          status: 1,
          initDate: event.target.initDate.value,
          endDate: event.target.endDate.value,
        },
      });
      await dispatch(editCoupon(updatedCoupon));
      await setDialog(false);
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      if (error.response.status === 400) {
        const errData = error.response.data;
        setError({
          initDate: errData.initDate || [],
          endDate: errData.endDate || [],
          nonFieldErrors: errData.nonFieldErrors || [],
        });
        return;
      }
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCoupons({
        id: storeId,
        token: getToken(),
        coupon: coupon.id,
      });
      await dispatch(deleteCoupon(coupon.id));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" id={labelId} scope="row">
        <strong>{coupon.id}</strong>
      </TableCell>
      <TableCell align="left">{coupon.code}</TableCell>
      <TableCell align="left">{coupon.description}</TableCell>
      <TableCell align="left">{coupon.discount}</TableCell>
      <TableCell align="left">
        {dayjs(new Date(coupon.initDate)).format('MMMM DD - YYYY')}
      </TableCell>
      <TableCell align="left">
        {dayjs(new Date(coupon.endDate)).format('MMMM DD - YYYY')}
      </TableCell>
      <TableCell align="left">{coupon.orders}</TableCell>
      <TableCell align="left">
        {t(`Coupons.table.status.${coupon.status}`)}
        <AltButton onClick={() => setDialog(true)}>
          {t(`Coupons.table.${coupon.status}`)}
        </AltButton>
        <Dialog
          open={dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDialog(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          {coupon.status ? (
            <DeactivateDialog
              onSubmit={handleDeactivation}
              hideDialog={() => setDialog(false)}
            />
          ) : (
            <ReactivateDialog
              onSubmit={handleReactivation}
              hideDialog={() => setDialog(false)}
              error={error}
            />
          )}
        </Dialog>
      </TableCell>
      <TableCell align="left">{`${coupon.author.firstName} ${coupon.author.lastName}`}</TableCell>
      <TableCell align="left">
        <DeleteButton onClick={handleDelete}>
          <DeleteIcon />
        </DeleteButton>
      </TableCell>
    </TableRow>
  );
};

CouponsTableContent.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    initDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    orders: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }),
  index: PropTypes.number,
};
