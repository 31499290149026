import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { DigitalPublications } from '../../components/DigitalPublications/DigitalPublications';
import { DigitalWrapper, Title, Divider } from './Digital.styled';

export const Digital = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <DigitalWrapper>
        <Title>{t('Digital.title')}</Title>
        <Divider />
        <DigitalPublications />
      </DigitalWrapper>
    </ContentWrapper>
  );
};
