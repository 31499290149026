import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { InfoDeliveryTable } from '../../components/InfoDeliveryTable/InfoDeliveryTable';
import { Title } from './Delivery.styled';
import {
  getBranchStoreDeliveryOrders,
  getBranchStoreSales,
} from '../../api/branchStores';
import { getToken, deleteToken } from '../../services/auth';
import { setOrders } from '../../features/orders/ordersSlice';

export const Delivery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const storeId = useSelector((state) => state.store.id);

  useEffect(() => getOrders(), []);

  const getOrders = async (page = 1) => {
    try {
      setLoading(true);
      let params = {
        token: getToken(),
        id: storeId,
      };

      if (page > 1) {
        params = { ...params, page };
      }

      const orders = await getBranchStoreDeliveryOrders(params);
      await dispatch(setOrders(orders));
      await setLoading(false);
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
    if (page > 1) return;

    try {
      await getBranchStoreSales({
        token: getToken(),
        id: storeId,
      });
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  return (
    <ContentWrapper>
      <Title>{t('Delivery.title')}</Title>
      <div>
        <InfoDeliveryTable loading={loading} pageChange={getOrders} />
      </div>
    </ContentWrapper>
  );
};
