import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const InfoPickupTableHead = (props) => {
  const { t } = useTranslation();

  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'reference',
      label: t('Pickup.table.head.reference'),
      align: 'left',
    },
    {
      id: 'client.user.firstName',
      label: t('Pickup.table.head.client'),
      align: 'left',
    },
    { id: 'products', label: t('Pickup.table.head.products'), align: 'left' },
    {
      id: 'status.description',
      label: t('Pickup.table.head.status'),
      align: 'left',
    },
    { id: 'createdAt', label: t('Pickup.table.head.createdat'), align: 'left' },
    {
      id: 'deliveryDatetime',
      label: t('Pickup.table.head.duedate'),
      align: 'left',
    },
    {
      id: 'shapyer',
      label: t('Pickup.table.head.shapyer'),
      align: 'left',
    },
    {
      id: 'deliveredby',
      label: t('Pickup.table.head.deliveredby'),
      align: 'left',
    },
    {
      id: 'orderCost.productsCost',
      label: t('Pickup.table.head.ordervalue'),
      align: 'left',
    },
    { id: 'invoice', label: t('Pickup.table.head.invoice'), align: 'right' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding="center"
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={true}>
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

InfoPickupTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
