import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import {
  SuscriptionWrapper,
  FormWrapper,
  TitleWrapper,
} from './Suscription.styled';
import { Subtitle } from '../../constants/base';

export const Suscription = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <SuscriptionWrapper>
        <TitleWrapper>
          <Subtitle>{t('Suscription.title')}</Subtitle>
        </TitleWrapper>
        <FormWrapper>Suscription details should be here...</FormWrapper>
      </SuscriptionWrapper>
    </ContentWrapper>
  );
};
