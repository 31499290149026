import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getToken, deleteToken } from '../../services/auth';
import { updateProductInventory } from '../../api/inventories';
import { updateProductBasicInfo } from '../../features/inventory/inventorySlice';
import { Button } from '../../constants/base';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { ProducThumbImg, useStyles } from './InventoryTableContent.styled';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LogoShapya from '../../assets/Logo_ShapYa_Red.png';
import Divider from '@material-ui/core/Divider';
import { Box, FormControlLabel } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';

export const InventoryTableContent = ({ inventoryProduct, index }) => {
  const { t } = useTranslation();
  const storeId = useSelector((state) => state.store.id);
  const labelId = `enhanced-table-checkbox-${index}`;
  const classes = useStyles();

  const [sbProductId, setSbProductId] = React.useState(
    inventoryProduct.storeBranchProductId,
  );

  const [priceValue, setPriceValue] = React.useState(inventoryProduct.price);
  const [nameValue, setNameValue] = React.useState(inventoryProduct.name);
  const [bestSellingValue, setBestSellingValue] = React.useState(
    inventoryProduct.bestSelling,
  );
  const [activeValue, setActiveValue] = React.useState(inventoryProduct.active);
  const [skuValue, setSkuValue] = React.useState(inventoryProduct.sku);
  const [descriptionValue, setDescriptionValue] = React.useState(
    inventoryProduct.description,
  );

  const [disable, setDisable] = React.useState(false);
  const priceRef = useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setSbProductId(inventoryProduct.storeBranchProductId);
    setPriceValue(inventoryProduct.price);
    setNameValue(inventoryProduct.name);
    setBestSellingValue(inventoryProduct.bestSelling);
    setActiveValue(inventoryProduct.active);
    setSkuValue(inventoryProduct.sku);
    setDescriptionValue(inventoryProduct.description);
    setOpen(true);
    setDisable(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const priceChanged = () => {
    setTimeout(
      () => (priceRef.current.style.backgroundColor = 'rgba(0, 128, 0, 0.2)'),
      300,
    );
    setTimeout(
      () => (priceRef.current.style.backgroundColor = 'transparent'),
      5000,
    );
  };

  const dispatch = useDispatch();

  const handleUpdateProductPrice = async () => {
    try {
      const updateProductInfo = await updateProductInventory({
        id: storeId,
        token: getToken(),
        productId: sbProductId,
        params: {
          price: priceValue,
          discount: 0,
          sku: skuValue,
          name: nameValue,
          description:
            descriptionValue === '' ? 'No description' : descriptionValue,
          best_selling: bestSellingValue,
          active: activeValue,
        },
      });
      await dispatch(updateProductBasicInfo(updateProductInfo));
      setOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  return (
    <TableRow hover tabIndex={-1} ref={priceRef}>
      <TableCell id={labelId} scope="row" align="center">
        {inventoryProduct.sku}
      </TableCell>
      <TableCell id={labelId} scope="row" align="center">
        {inventoryProduct.name}
      </TableCell>
      <TableCell id={labelId} scope="row" align="center">
        <ProducThumbImg>
          <img src={inventoryProduct.images[0].image} />
        </ProducThumbImg>
      </TableCell>
      <TableCell align="center">
        {inventoryProduct.currency.code}
        {inventoryProduct.currency.symbol}
        {inventoryProduct.price.toFixed(2)}
      </TableCell>
      <TableCell align="center">
        <EditIcon className={classes.editPriceIcon} onClick={handleOpen} />
      </TableCell>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <img src={LogoShapya} width="120" height="36" alt="Shapya Logo" />
            <h2 id="transition-modal-title">
              {t('Inventory.modal.productBasicInfoUpdate')}
            </h2>
            <Divider />
            <Box mt={3} mb={2}>
              <h4>{inventoryProduct.name}</h4>
              <h4>SKU: {inventoryProduct.sku}</h4>
            </Box>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">
                {t('Inventory.modal.productBasicInfoUpdate.inputProductPrice')}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                type="number"
                inputProps={{ min: 0 }}
                defaultValue={inventoryProduct.price.toFixed(2)}
                autoComplete="off"
                onChange={(e) => {
                  setPriceValue(e.target.value);
                  e.target.value === '' || nameValue === ''
                    ? setDisable(true)
                    : setDisable(false);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    {inventoryProduct.currency.code}
                    {inventoryProduct.currency.symbol}
                  </InputAdornment>
                }
                labelWidth={60}
              />
            </FormControl>
            <br />
            <br />
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined">
              <InputLabel htmlFor="outlined-adornment-name">
                {t('Inventory.modal.productBasicInfoUpdate.inputProductName')}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-name"
                type="text"
                defaultValue={inventoryProduct.name}
                autoComplete="off"
                onChange={(e) => {
                  setNameValue(e.target.value);
                  e.target.value === '' || priceValue === ''
                    ? setDisable(true)
                    : setDisable(false);
                }}
                labelWidth={60}
                inputProps={{ maxLength: 120 }}
              />
            </FormControl>
            <br />
            <br />
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined">
              <InputLabel htmlFor="outlined-adornment-name">
                {t(
                  'Inventory.modal.productBasicInfoUpdate.inputProductDescription',
                )}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-description"
                type="text"
                defaultValue={inventoryProduct.description}
                autoComplete="off"
                onChange={(e) => {
                  setDescriptionValue(e.target.value);
                }}
                labelWidth={90}
                inputProps={{ maxLength: 520 }}
              />
            </FormControl>
            <br />
            <br />
            <FormControlLabel
              control={<Switch color="primary" />}
              label={
                <h4>
                  {t(
                    'Inventory.modal.productBasicInfoUpdate.switchProductAvailable',
                  )}
                </h4>
              }
              labelPlacement="start"
              checked={activeValue}
              onChange={() => setActiveValue(!activeValue)}
            />
            <br />
            <FormControlLabel
              control={<Switch color="primary" />}
              label={
                <h4>
                  {t(
                    'Inventory.modal.productBasicInfoUpdate.switchIsBestSelling',
                  )}
                </h4>
              }
              labelPlacement="start"
              checked={bestSellingValue}
              onChange={() => setBestSellingValue(!bestSellingValue)}
            />
            <Box mt={3}>
              <h3 id="transition-modal-description">
                {t('Inventory.modal.productBasicInfoUpdateConfirmation')}
              </h3>
            </Box>
            <Box mt={2} className={classes.buttonsWrap}>
              <Button className={classes.buttonCancel} onClick={handleClose}>
                {t('Inventory.modal.productBasicInfoUpdate.button.cancel')}
              </Button>
              <Button
                disabled={disable}
                className={classes.buttonDelete}
                onClick={() => {
                  priceChanged();
                  setDisable(true);
                  handleUpdateProductPrice();
                }}>
                {t('Inventory.modal.productBasicInfoUpdate.button.update')}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </TableRow>
  );
};

InventoryTableContent.propTypes = {
  inventoryProduct: PropTypes.shape({
    storeBranchProducts: PropTypes.array,
    storeBranchProductId: PropTypes.number,
    sku: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    active: PropTypes.bool,
    bestSelling: PropTypes.bool,
    description: PropTypes.string,
    images: PropTypes.shape({
      image: PropTypes.shape,
    }),
    currency: PropTypes.shape({
      symbol: PropTypes.string,
      code: PropTypes.string,
    }),
  }),

  index: PropTypes.number,
};
