import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import dayjs from 'dayjs';
import { useStyles } from './RestaurantOrdersTableContent.styled';

export const RestaurantOrdersTableContent = ({ singleOrder, index }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelId = `enhanced-table-checkbox-${index}`;
  const symbol = singleOrder.orderCost.currency.symbol;

  const sumTotalOrder = [singleOrder.orderCost.productsCost, 0]
    .reduce((a, b) => a + b)
    .toFixed(2);

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" id={labelId} scope="row">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={'/order/' + singleOrder.id}
          className={classes.fullOrderDetailsLink}>
          <strong>{singleOrder.reference}</strong>
        </a>
      </TableCell>
      <TableCell align="left">
        {singleOrder.client.user.firstName} {singleOrder.client.user.lastName}
      </TableCell>
      <TableCell align="left">
        {singleOrder.status && singleOrder.status.description
          ? singleOrder.status.description
          : ''}
      </TableCell>
      <TableCell align="left">
        {dayjs(new Date(singleOrder.createdAt)).format('MMMM DD - YYYY')}
      </TableCell>
      <TableCell align="left">{`${symbol} ${sumTotalOrder}`}</TableCell>
      <TableCell align="left">{`${symbol} ${singleOrder.orderCost.taxes.toFixed(
        2,
      )}`}</TableCell>
      <TableCell align="left">{`${symbol} ${singleOrder.orderCost.productsCostPlusTaxes.toFixed(
        2,
      )}`}</TableCell>
    </TableRow>
  );
};

RestaurantOrdersTableContent.propTypes = {
  singleOrder: PropTypes.shape({
    id: PropTypes.number,
    branchStores: PropTypes.array,
    reference: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    client: PropTypes.shape({
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
    orderCost: PropTypes.shape({
      productsCost: PropTypes.number,
      currency: PropTypes.shape({
        symbol: PropTypes.string,
      }),
      receipt: PropTypes.string,
      taxes: PropTypes.number,
      productsCostPlusTaxes: PropTypes.number,
    }),
    tax: PropTypes.number,
    status: PropTypes.shape({
      description: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    deliveryDatetime: PropTypes.string,
    shapyer: PropTypes.string,
    deliveredBy: PropTypes.string,
  }),
  index: PropTypes.number,
};

RestaurantOrdersTableContent.defaultProps = {
  singleOrder: {
    branchStores: [],
    shapyer: '-',
    deliveredBy: '-',
  },
};
