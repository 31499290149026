import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { TextField, ErrorText, Form } from './ReactivateDialog.js.styled';

export const ReactivateDialog = ({ error, onSubmit, hideDialog }) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit}>
      <DialogTitle id="alert-dialog-slide-title">
        {t('Coupons.table.reactivate.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('Coupons.table.reactivate.description')}
        </DialogContentText>
        <TextField
          error={error.initDate.length}
          helperText={error.initDate[0]}
          id="initDate"
          name="initDate"
          label={t('Coupons.CouponsModal.form.initDate.label')}
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          error={error.endDate.length}
          helperText={error.endDate[0]}
          id="endDate"
          name="endDate"
          label={t('Coupons.CouponsModal.form.endDate.label')}
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
        />
        {error.nonFieldErrors.length > 0 && (
          <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog} color="gray">
          {t('Coupons.table.reactivate.cancel')}
        </Button>
        <Button type="submit" color="primary">
          {t('Coupons.table.reactivate.cta')}
        </Button>
      </DialogActions>
    </Form>
  );
};

ReactivateDialog.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  hideDialog: PropTypes.func,
};
