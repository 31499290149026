import React from 'react';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import { Circle } from './AddCircle.styled';

export const AddCircle = ({ onClick }) => {
  return (
    <Circle onClick={onClick}>
      <Add />
    </Circle>
  );
};

AddCircle.propTypes = {
  onClick: PropTypes.func,
};
