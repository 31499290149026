import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLastInventory,
  getSearchProductInventory,
  getSearchSkuInventory,
  getSearchCategoryInventory,
} from '../../api/inventories';
import { getBranchStoreCategories } from '../../api/branchStores';
import { getToken, deleteToken } from '../../services/auth';
import { setInventories } from '../../features/inventory/inventorySlice';
import { setCategories } from '../../features/store/storeCategoriesSlice';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function InputSearchInventoryTable({
  setShowInventory,
  setProductSkuParam,
  setProductNameParam,
  setCategoryIdParam,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const products = useSelector((state) => state.products);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const storeId = useSelector((state) => state.store.id);
  const storeName = useSelector((state) => state.store.name);
  const storeLogo = useSelector((state) => state.store.store.logo);
  const [bsCategories, setBsCategories] = useState([]);
  const [searchedBy, setSearchedBy] = useState('all');
  const [searchedValue, setSearchedValue] = useState('');
  const [inputSku, setInputSku] = useState('');
  const [inputProductName, setInputProductName] = useState('');

  const getSearchInventoriesByAllProducts = async (page = 1) => {
    try {
      setLoading(true);
      let params = {
        token: getToken(),
        id: storeId,
      };

      if (page > 1) {
        params = { ...params, page };
      }

      const inventories = await getLastInventory(params);
      await dispatch(setInventories(inventories));
      await setLoading(false);
      setSearchedBy('all');
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  const getSearchInventoriesByProductName = async (page = 1) => {
    try {
      setLoading(true);
      let params = {
        token: getToken(),
        id: storeId,
        searchWord: inputProductName,
      };

      if (page > 1) {
        params = { ...params, page };
      }

      const inventories = await getSearchProductInventory(params);
      await dispatch(setInventories(inventories));
      await setLoading(false);
      setSearchedBy('searchByProductName');
      setSearchedValue(inputProductName);
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  const getSearchInventoriesBySku = async (page = 1) => {
    try {
      setLoading(true);
      let params = {
        token: getToken(),
        id: storeId,
        searchSku: inputSku,
      };

      if (page > 1) {
        params = { ...params, page };
      }

      const inventories = await getSearchSkuInventory(params);
      await dispatch(setInventories(inventories));
      await setLoading(false);
      setSearchedBy('searchBySku');
      setSearchedValue(inputSku);
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  const getSearchInventoriesByCategoryId = async (
    catIdRender,
    catNameRender,
    page = 1,
  ) => {
    try {
      setLoading(true);
      let params = {
        token: getToken(),
        id: storeId,
        searchCategoryId: catIdRender,
      };

      if (page > 1) {
        params = { ...params, page };
      }

      const inventories = await getSearchCategoryInventory(params);
      await dispatch(setInventories(inventories));
      await setLoading(false);
      setSearchedBy('searchByCategoryId');
      setSearchedValue(catNameRender);
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  const handleAllProducts = () => {
    setShowInventory('all');
    getSearchInventoriesByAllProducts();
  };

  const handleChangeSkuValue = (event) => {
    setShowInventory('searchBySku');
    setInputSku(event.target.value);
  };

  const handleChangeProductNameValue = (event) => {
    setShowInventory('searchByProductName');
    setInputProductName(event.target.value);
  };

  const handleChangeCategoryIdValue = (event) => {
    setCategoryId(event.currentTarget.value);
  };

  const handleProductsByCategory = (catId, catName) => {
    setShowInventory('searchByCategoryId');
    getSearchInventoriesByCategoryId(catId, catName);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      setLoading(true);
      let params = {
        token: getToken(),
        id: storeId,
      };

      const categories = await getBranchStoreCategories(params);
      await dispatch(setCategories(categories));
      await setLoading(false);
      setBsCategories(categories.results);
    } catch (error) {
      await setLoading(false);
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      console.error(error);
    }
  };

  const [categoriesDialogOpen, setCategoriesDialogOpen] = React.useState(false);

  const handleClickCategoriesDialogOpen = () => {
    setCategoriesDialogOpen(true);
  };

  const handleClickCategoriesDialogClose = () => {
    setCategoriesDialogOpen(false);
  };

  const [categoryId, setCategoryId] = useState('');

  return (
    <div>
      <div className={classes.margin}>
        <Grid container spacing={1}>
          <Grid item>
            <Chip
              size="small"
              label={t('Inventory.buttonSearch.showAll')}
              clickable
              color="primary"
              disabled={searchedBy === 'all' ? true : false}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleAllProducts();
              }}
            />
          </Grid>
          <Grid item>
            <Chip
              size="small"
              label={t('Inventory.buttonSearch.byCategories')}
              clickable
              color="primary"
              onClick={handleClickCategoriesDialogOpen}
            />
            <Dialog
              onClose={handleClickCategoriesDialogClose}
              aria-labelledby="simple-dialog-title"
              open={categoriesDialogOpen}>
              <DialogTitle id="simple-dialog-title" align="center">
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClickCategoriesDialogClose}>
                  <CloseIcon />
                </IconButton>
                <img src={storeLogo} alt={storeName} height="40" />
                <br />
                {storeName} - {t('Inventory.dialogTitle.byCategories')}
              </DialogTitle>
              <List>
                {bsCategories.map((bsCategory) => (
                  <ListItem
                    button
                    onChange={handleChangeCategoryIdValue}
                    onClick={(e) => (
                      e.preventDefault(),
                      e.stopPropagation(),
                      setCategoryId(bsCategory.id),
                      setCategoryIdParam(bsCategory.id),
                      handleProductsByCategory(bsCategory.id, bsCategory.name),
                      handleClickCategoriesDialogClose()
                    )}
                    key={bsCategory.id}
                    dense>
                    <ListItemText primary={bsCategory.name} />
                    <ListItemIcon>{bsCategory.productCount}</ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </Dialog>
          </Grid>
          <br />
          <br />
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid
            item
            component="form"
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setProductSkuParam(inputSku);
              getSearchInventoriesBySku();
            }}>
            <TextField
              id="inputSearchProductSku"
              label={t('Inventory.inputsearch.productSku')}
              className={classes.textField}
              onChange={handleChangeSkuValue}
              InputLabelProps={{ style: { fontSize: 12 } }}
              defaultValue={inputSku}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            component="form"
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setProductNameParam(inputProductName);
              getSearchInventoriesByProductName();
            }}>
            <TextField
              id="inputSearchProductName"
              label={t('Inventory.inputsearch.productName')}
              className={classes.textField}
              onChange={handleChangeProductNameValue}
              InputLabelProps={{ style: { fontSize: 12 } }}
              defaultValue={inputProductName}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid>
          <br />
          <>
            {searchedBy === 'all' && products.count > 0 ? (
              <>
                <Chip
                  size="small"
                  label={
                    <h3>{t('Inventory.inputSearchResultLabel.allProducts')}</h3>
                  }
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>#</h1>
                    </Avatar>
                  }
                  label={products.count}
                  spacing={5}
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>P</h1>
                    </Avatar>
                  }
                  label={products.totalPages}
                />
              </>
            ) : null}
          </>
          <>
            {searchedBy === 'searchByCategoryId' && products.count > 0 ? (
              <>
                <Chip
                  size="small"
                  label={
                    <h4>
                      {t(
                        'Inventory.inputSearchResultLabel.searchedByCategoryId',
                      )}
                      : <i>&quot;{searchedValue}&quot;</i>
                    </h4>
                  }
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>#</h1>
                    </Avatar>
                  }
                  label={products.count}
                  spacing={5}
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>P</h1>
                    </Avatar>
                  }
                  label={products.totalPages}
                />
              </>
            ) : null}
          </>
          <>
            {searchedBy === 'searchBySku' && products.count > 0 ? (
              <>
                <Chip
                  size="small"
                  label={
                    <h4>
                      {t('Inventory.inputSearchResultLabel.searchedBySKU')}:{' '}
                      <i>&quot;{searchedValue}&quot;</i>
                    </h4>
                  }
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>#</h1>
                    </Avatar>
                  }
                  label={products.count}
                  spacing={5}
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>P</h1>
                    </Avatar>
                  }
                  label={products.totalPages}
                />
              </>
            ) : null}
          </>
          <>
            {searchedBy === 'searchByProductName' && products.count > 0 ? (
              <>
                <Chip
                  size="small"
                  label={
                    <h4>
                      {t(
                        'Inventory.inputSearchResultLabel.searchedByProductName',
                      )}
                      : <i>&quot;{searchedValue}&quot;</i>
                    </h4>
                  }
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>#</h1>
                    </Avatar>
                  }
                  label={products.count}
                  spacing={5}
                />
                &nbsp;
                <Chip
                  size="small"
                  avatar={
                    <Avatar>
                      <h1>P</h1>
                    </Avatar>
                  }
                  label={products.totalPages}
                />
              </>
            ) : null}
          </>
        </Grid>
      </div>
    </div>
  );
}

InputSearchInventoryTable.propTypes = {
  showInventory: PropTypes.string,
  setShowInventory: PropTypes.func,
  setProductSkuParam: PropTypes.func,
  setProductNameParam: PropTypes.func,
  setCategoryIdParam: PropTypes.func,
};

InputSearchInventoryTable.defaultProps = {
  showInventory: '',
  setShowInventory: () => {},
  setProductSkuParam: () => {},
  setProductNameParam: () => {},
  setCategoryIdParam: () => {},
};
