import React from 'react';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import { AboutYouStoreBusinessForm } from '../../components/SignUpForms/AboutYouStoreBusinessForm';
import {
  BusinessFormMainWrapper,
  BusinessFormWrapper,
} from './BusinessForm.styled';

export const BusinessForm = () => {
  return (
    <>
      <BusinessFormMainWrapper>
        <ContentWrapper></ContentWrapper>
        <BusinessFormWrapper>
          <AboutYouStoreBusinessForm />
        </BusinessFormWrapper>
      </BusinessFormMainWrapper>
    </>
  );
};
