import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from '../services/http';

export const retreiveCoupons = async (data) => {
  const response = await getRequest({
    url: `user/branch-stores/${data.id}/promotionalcodes/`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const createCoupons = async (data) => {
  const response = await postRequest({
    url: `user/branch-stores/${data.id}/promotionalcodes/`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const updateCoupons = async (data) => {
  const response = await putRequest({
    url: `user/branch-stores/${data.id}/promotionalcodes/${data.coupon}/`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const deleteCoupons = async (data) => {
  const response = await deleteRequest({
    url: `user/branch-stores/${data.id}/promotionalcodes/${data.coupon}/`,
    data: data.params,
    token: data.token,
  });

  return response;
};
