import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ContentWrapper } from '../../shared/ContentWrapper/ContentWrapper';
import {
  ProfileWrapper,
  FormWrapper,
  TitleWrapper,
  Form,
  FormRow,
  TextField,
  ErrorText,
  Anchor,
} from './Profile.styled';
import { Subtitle, Button } from '../../constants/base';
import { updateUser } from '../../api/users';
import { setUser } from '../../features/user/userSlice';
import { getToken } from '../../services/auth';

export const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user, shallowEqual);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    firstName: [],
    lastName: [],
    username: [],
    nonFieldErrors: [],
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const updatedUser = await updateUser({
        token: getToken(),
        params: {
          firstName: event.target.firstName.value,
          lastName: event.target.lastName.value,
          username: event.target.username.value,
        },
      });
      await dispatch(setUser(updatedUser));
      await setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response.status === 400) {
        const errData = err.response.data;
        setError({
          firstName: errData.firstName || [],
          lastName: errData.lastName || [],
          username: errData.username || [],
          nonFieldErrors: errData.nonFieldErrors || [],
        });
        return;
      }
      console.error(error);
    }
  };

  return (
    <ContentWrapper>
      <ProfileWrapper className="Profile">
        <TitleWrapper>
          <Subtitle>
            {t('Profile.title', { name: `${user.firstName} ${user.lastName}` })}
          </Subtitle>
        </TitleWrapper>
        <FormWrapper>
          <Form onSubmit={onSubmit}>
            <FormRow>
              <TextField
                defaultValue={user.firstName}
                error={error.firstName.length}
                helperText={error.firstName[0]}
                label={t('User.firstName.label')}
                type="text"
                placeholder={t('User.firstName.label')}
                name="firstName"
                variant="outlined"
                size="small"
              />
              <TextField
                defaultValue={user.lastName}
                error={error.lastName.length}
                helperText={error.lastName[0]}
                label={t('User.lastName.label')}
                type="text"
                placeholder={t('User.lastName.label')}
                name="lastName"
                variant="outlined"
                size="small"
              />
              <TextField
                defaultValue={user.username}
                error={error.username.length}
                helperText={error.username[0]}
                label={t('User.username.label')}
                type="text"
                placeholder={t('User.username.label')}
                name="username"
                variant="outlined"
                size="small"
                required
              />
              <TextField
                defaultValue={user.email}
                label={t('User.email.label')}
                type="email"
                placeholder={t('User.email.label')}
                name="email"
                variant="outlined"
                size="small"
                required
                disabled
              />
              <TextField
                defaultValue={user.phoneNumber}
                label={t('User.phone.label')}
                type="text"
                placeholder={t('User.phone.label')}
                name="phone"
                variant="outlined"
                size="small"
                disabled
              />
            </FormRow>
            {error.nonFieldErrors.length > 0 && (
              <ErrorText>{error.nonFieldErrors[0]}</ErrorText>
            )}
            <Button disabled={loading}>{t('Profile.cta')}</Button>
            <Anchor to="/password-change/">
              {t('Profile.password.change')}
            </Anchor>
          </Form>
        </FormWrapper>
      </ProfileWrapper>
    </ContentWrapper>
  );
};
