import { getRequest, putRequest, postRequest } from '../services/http';

export const getBranchStoreOrders = async (data) => {
  if (data.page) {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/orders/?page=${data.page}`,
      token: data.token,
    });
    return response;
  } else {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/orders/`,
      token: data.token,
    });

    return response;
  }
};

export const getBranchStorePickupOrders = async (data) => {
  if (data.page) {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/orders/?order_type=pickup&page=${data.page}`,
      token: data.token,
    });
    return response;
  } else {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/orders/?order_type=pickup`,
      token: data.token,
    });

    return response;
  }
};

export const getBranchStoreDeliveryOrders = async (data) => {
  if (data.page) {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/orders/?order_type=delivery&page=${data.page}`,
      token: data.token,
    });
    return response;
  } else {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/orders/?order_type=delivery`,
      token: data.token,
    });

    return response;
  }
};

export const getBranchStoreOrderProducts = async (data) => {
  const response = await getRequest({
    url: `user/branch-stores/${data.id}/orders/${data.orderId}/products/`,
    data: data.params,
    token: data.token,
  });

  return response;
};

export const getBranchStoreSales = async (data) => {
  const response = await getRequest({
    url: `branch-stores/${data.id}/sales/`,
    token: data.token,
  });

  return response;
};

export const getBranchStoreCategories = async (data) => {
  const response = await getRequest({
    url: `branch-stores/${data.id}/product-categories/`,
    token: data.token,
  });

  return response;
};

export const retreiveBranchStore = async (data) => {
  const response = await getRequest({
    url: `user/branch-stores/${data.id}`,
    token: data.token,
  });

  return response;
};

export const retreiveStore = async (data) => {
  const response = await getRequest({
    url: `user/branch-stores/${data.id}/store/`,
    token: data.token,
  });

  return response;
};

export const updateStore = async (data) => {
  const response = await putRequest({
    url: `user/branch-stores/${data.id}/store/`,
    token: data.token,
    data: data.params,
  });

  return response;
};

export const updateBranchStore = async (data) => {
  const response = await putRequest({
    url: `user/branch-stores/${data.id}/`,
    token: data.token,
    data: data.params,
  });

  return response;
};

export const addEditScheduleDay = async (data) => {
  const response = await postRequest({
    url: `user/branch-stores/${data.id}/schedules/`,
    token: data.token,
    data: data.params,
  });

  return response;
};
