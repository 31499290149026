import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreIcon from '@material-ui/icons/MoreVert';
import { setStore } from '../../../features/store/storeSlice';
import logo from '../../../assets/Logo_ShapYa_Red.png';
import { logoutUser } from '../../../api/users';
import { deleteToken, getToken } from '../../../services/auth';
import { useStyles, Figure, Img } from './LoggedHeader.styled';
import { Hidden } from '@material-ui/core';
import YourLogo from '../../../assets/yourlogo.png';

export const LoggedHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsOpen, setNotificationsOpen] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const notifications = useSelector(
    (state) => state.notifications,
    shallowEqual,
  );
  const store = useSelector((state) => state.store, shallowEqual);
  const availableStores = useSelector(
    (state) => state.availableStores,
    shallowEqual,
  );
  const manageSuscription = useSelector(
    (state) => state.ui.features.manageSuscription,
  );

  const isMenuOpen = Boolean(anchorEl);
  const isNotificationsMenuOpen = Boolean(notificationsOpen);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isTemporaryCreation = useSelector(
    (state) => state.store.temporaryCreation,
  );

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuOpen = (event) => {
    setNotificationsOpen(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleNotificationsMenuClose = () => {
    setNotificationsOpen(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      await logoutUser({ token: getToken() });
      await deleteToken();
      window.location = '/';
    } catch (error) {
      console.error(error);
    }
  };

  const notificationsId = 'primary-search-notifications-menu';

  const notificationsMenu = notifications.length && (
    <Menu
      anchorEl={notificationsOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={notificationsId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isNotificationsMenuOpen}
      onClose={handleNotificationsMenuClose}>
      {notifications.map((elem) => (
        <MenuItem key={elem.id}>{elem.name}</MenuItem>
      ))}
    </Menu>
  );

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      {!isTemporaryCreation ? (
        <MenuItem onClick={() => navigate('/profile/')}>
          {t('LoggedHeader.account')}
        </MenuItem>
      ) : null}
      {!isTemporaryCreation
        ? availableStores.map((singleStore) => (
            <MenuItem
              selected={singleStore.id === store.id}
              key={singleStore.id}
              onClick={() => {
                if (singleStore.id === store.id) return;

                dispatch(setStore(singleStore));
                localStorage.setItem(
                  'selected_store',
                  JSON.stringify(singleStore.id),
                );
                window.location.reload();
              }}>
              {singleStore.name}
            </MenuItem>
          ))
        : null}
      <Hidden xsUp>
        {manageSuscription && (
          <MenuItem disabled onClick={() => navigate('/suscription/')}>
            {t('LoggedHeader.suscription')}
          </MenuItem>
        )}
      </Hidden>
      <MenuItem onClick={handleLogout}>{t('LoggedHeader.logout')}</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem disableRipple className={classes.mobileImgMenuItem}>
        <img
          alt={store.name}
          src={store.store.logo}
          className={classes.mobileLoggedAvatar}
        />
      </MenuItem>
      <MenuItem disabled>
        <IconButton
          aria-label={t('LoggedHeader.notifications.count', {
            count: notifications,
          })}
          color="inherit">
          <Badge badgeContent={notifications.length} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>{t('LoggedHeader.notifications')}</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label={t('LoggedHeader.current_user')}
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <SettingsIcon />
        </IconButton>
        <p>{t('LoggedHeader.profile')}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" color="secondary" className={classes.appBar}>
        <Toolbar>
          <Figure>
            <Img src={logo} alt="Shapya" />
          </Figure>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <Typography className={classes.title} variant="h6" noWrap>
            {store.name}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label={t('LoggedHeader.notifications')}
              color="inherit"
              aria-controls={notificationsId}
              onClick={handleNotificationsMenuOpen}
              aria-haspopup="true"
              disabled>
              <Badge badgeContent={notifications.length} color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {isTemporaryCreation === false ? (
              <img
                alt={store.name}
                src={store.store.logo ? store.store.logo : YourLogo}
                className={classes.loggedAvatar}
                onClick={handleProfileMenuOpen}
              />
            ) : (
              <Button
                color="primary"
                size="small"
                className={classes.loggedAvatar}
                onClick={handleProfileMenuOpen}>
                <b>{t('LoggedHeader.yourLogo')}</b>
              </Button>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label={t('LoggedHeader.show_more')}
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {notificationsMenu}
    </div>
  );
};
