import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { updateBranchStore, addEditScheduleDay } from '../../api/branchStores';
import { useTranslation } from 'react-i18next';
import {
  BoxAboutYou,
  TitleAboutYouWrap,
  TitleAboutYou,
  BusinessFormBox,
  InnerBusinessFormBox,
  TextFieldWrapBusinessForm,
  InputLabel,
  TextField,
  SignUpButton,
} from './SignUpForms.styled';
import './SignUpForms.css';
import { Form } from '../Register/Register.styled';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ConfirmEmailAddress } from './ConfirmEmailAddress';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import { ScheduleForm } from './ScheduleForm';
import { getToken } from '../../services/auth';
import { useNavigate } from 'react-router-dom';

export const AboutYouStoreBusinessForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formSent, setFormSent] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [storeCategory, setStoreCategory] = useState('');

  const handleChangeStoreCategory = (event) => {
    setStoreCategory(event.target.value);
  };

  const branchStoreState = useSelector((state) => state.store);
  const [errorLogo, setErrorLogo] = useState('');
  const [pickupChecked, setPickupChecked] = useState(false);
  const [deliveryChecked, setDeliveryChecked] = useState(false);

  const handleFileChange = () => {
    const fileInput = document.querySelector('input[type="file"]');

    if (fileInput.files[0]) {
      if (
        fileInput.files[0].type === 'image/jpeg' ||
        fileInput.files[0].type === 'image/jpg' ||
        fileInput.files[0].type === 'image/png'
      ) {
        if (fileInput.files[0].size <= 1024 * 1024) {
          setErrorLogo('');
        } else {
          setErrorLogo(t('BusinessForm.uploadYourLogo.helperText.fileSize'));
          document.querySelector('input[type="file"]').value = null;
        }
      } else {
        setErrorLogo(t('BusinessForm.uploadYourLogo.helperText.fileType'));
        document.querySelector('input[type="file"]').value = null;
      }
    } else {
      document.querySelector('input[type="file"]').value = null;
    }
  };

  const handlePickupChange = () => {
    setPickupChecked(!pickupChecked);
  };

  const handleDeliveryChange = () => {
    setDeliveryChecked(!deliveryChecked);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);

    const fileInput = document.querySelector('input[type="file"]');

    let myHeaders = new Headers();
    myHeaders.append('Authorization', `Token ${getToken()}`);

    let formdata = new FormData();
    formdata.append('name', event.target.name.value);
    formdata.append('description', event.target.description.value);
    if (fileInput.files[0]) {
      if (
        fileInput.files[0].type === 'image/jpeg' ||
        fileInput.files[0].type === 'image/png'
      ) {
        formdata.append('logo', fileInput.files[0]);
      } else {
        alert('no jpg');
      }
    }
    formdata.append('store_type_id', event.target.business_category.value);
    formdata.append('store_category_id', event.target.business_type.value);

    let requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/user/branch-stores/${branchStoreState.id}/store/`,
        requestOptions,
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log('error', error));

      await updateBranchStore({
        token: getToken(),
        id: branchStoreState.id,
        params: {
          name: event.target.bsName.value,
          isHq: true,
          has_pickup: event.target.isPickup.value,
          has_delivery: event.target.isDelivery.value,
          is_highligthed: false,
          delivery_minimum_purchase_amount:
            event.target.delivery_minimum_purchase_amount.value,
          pickup_minimum_purchase_amount:
            event.target.pickup_minimum_purchase_amount.value,
          distance_radius: event.target.distance_radius.value,
          temporary_creation: false,
        },
      });

      await addEditScheduleDay({
        token: getToken(),
        id: branchStoreState.store.id,
        params: {
          branch_store_id: branchStoreState.store.id,
          day: 0,
          fromTime:
            event.target.mondayClosed.checked === false
              ? `${event.target.mondayFromHour.value}:${event.target.mondayFromMinutes.value}:00`
              : '00:00:00',
          toTime:
            event.target.mondayClosed.checked === false
              ? `${event.target.mondayToHour.value}:${event.target.mondayToMinutes.value}:00`
              : '00:00:01',
        },
      });
      await addEditScheduleDay({
        token: getToken(),
        id: branchStoreState.store.id,
        params: {
          branch_store_id: branchStoreState.store.id,
          day: 1,
          fromTime:
            event.target.tuesdayClosed.checked === false
              ? `${event.target.tuesdayFromHour.value}:${event.target.tuesdayFromMinutes.value}:00`
              : '00:00:00',
          toTime:
            event.target.tuesdayClosed.checked === false
              ? `${event.target.tuesdayToHour.value}:${event.target.tuesdayToMinutes.value}:00`
              : '00:00:01',
        },
      });
      await addEditScheduleDay({
        token: getToken(),
        id: branchStoreState.store.id,
        params: {
          branch_store_id: branchStoreState.store.id,
          day: 2,
          fromTime:
            event.target.wednesdayClosed.checked === false
              ? `${event.target.wednesdayFromHour.value}:${event.target.wednesdayFromMinutes.value}:00`
              : '00:00:00',
          toTime:
            event.target.wednesdayClosed.checked === false
              ? `${event.target.wednesdayToHour.value}:${event.target.wednesdayToMinutes.value}:00`
              : '00:00:01',
        },
      });
      await addEditScheduleDay({
        token: getToken(),
        id: branchStoreState.store.id,
        params: {
          branch_store_id: branchStoreState.store.id,
          day: 3,
          fromTime:
            event.target.thursdayClosed.checked === false
              ? `${event.target.thursdayFromHour.value}:${event.target.thursdayFromMinutes.value}:00`
              : '00:00:00',
          toTime:
            event.target.thursdayClosed.checked === false
              ? `${event.target.thursdayToHour.value}:${event.target.thursdayToMinutes.value}:00`
              : '00:00:01',
        },
      });
      await addEditScheduleDay({
        token: getToken(),
        id: branchStoreState.store.id,
        params: {
          branch_store_id: branchStoreState.store.id,
          day: 4,
          fromTime:
            event.target.fridayClosed.checked === false
              ? `${event.target.fridayFromHour.value}:${event.target.fridayFromMinutes.value}:00`
              : '00:00:00',
          toTime:
            event.target.fridayClosed.checked === false
              ? `${event.target.fridayToHour.value}:${event.target.fridayToMinutes.value}:00`
              : '00:00:01',
        },
      });
      await addEditScheduleDay({
        token: getToken(),
        id: branchStoreState.store.id,
        params: {
          branch_store_id: branchStoreState.store.id,
          day: 5,
          fromTime:
            event.target.saturdayClosed.checked === false
              ? `${event.target.saturdayFromHour.value}:${event.target.saturdayFromMinutes.value}:00`
              : '00:00:00',
          toTime:
            event.target.saturdayClosed.checked === false
              ? `${event.target.saturdayToHour.value}:${event.target.saturdayToMinutes.value}:00`
              : '00:00:01',
        },
      });
      await addEditScheduleDay({
        token: getToken(),
        id: branchStoreState.store.id,
        params: {
          branch_store_id: branchStoreState.store.id,
          day: 6,
          fromTime:
            event.target.sundayClosed.checked === false
              ? `${event.target.sundayFromHour.value}:${event.target.sundayFromMinutes.value}:00`
              : '00:00:00',
          toTime:
            event.target.sundayClosed.checked === false
              ? `${event.target.sundayToHour.value}:${event.target.sundayToMinutes.value}:00`
              : '00:00:01',
        },
      });

      setTimeout(() => {
        navigate(0);
      }, 3000);
    } catch (err) {
      if (err.response.status === 400) {
        console.log(err.response.data);
        return;
      }
    }
  };

  return (
    <>
      {!formSent ? (
        <div style={{ marginBottom: '120px' }}>
          <BoxAboutYou>
            <TitleAboutYouWrap>
              <TitleAboutYou>{t('BusinessForm.title')}</TitleAboutYou>
            </TitleAboutYouWrap>

            <Form onSubmit={onSubmit}>
              <BusinessFormBox>
                <InnerBusinessFormBox>
                  <InputLabel>{t('BusinessFormBS.name')}</InputLabel>
                  <TextFieldWrapBusinessForm>
                    <TextField
                      type="text"
                      placeholder={t('BusinessFormBS.name')}
                      name="name"
                      variant="outlined"
                      autoComplete="off"
                      value={branchStoreState.store.name}
                      required
                      disabled
                    />
                  </TextFieldWrapBusinessForm>
                </InnerBusinessFormBox>
                <InnerBusinessFormBox>
                  <InputLabel>{t('BusinessFormBS.description')}</InputLabel>
                  <TextFieldWrapBusinessForm>
                    <TextField
                      type="text"
                      placeholder={t('BusinessFormBS.description')}
                      name="description"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  </TextFieldWrapBusinessForm>
                </InnerBusinessFormBox>
              </BusinessFormBox>

              <BusinessFormBox>
                <InnerBusinessFormBox>
                  <InputLabel>{t('BusinessForm.uploadYourLogo')}</InputLabel>
                  <TextFieldWrapBusinessForm>
                    <TextField
                      type="file"
                      accept="image/*"
                      placeholder={t('BusinessForm.uploadYourLogo')}
                      className="uploadYourLogoWrap"
                      name="businessLogo"
                      variant="outlined"
                      onChange={handleFileChange}
                      helperText={errorLogo}
                    />
                  </TextFieldWrapBusinessForm>
                </InnerBusinessFormBox>
                <InnerBusinessFormBox>
                  <BusinessFormBox>
                    <InnerBusinessFormBox className="businessTypeCategoryDiv">
                      <InnerBusinessFormBox className="businessTypeCategoryDiv">
                        <InputLabel>
                          {t('BusinessForm.chooseYourBusinessType')}{' '}
                        </InputLabel>
                        <RadioGroup
                          row
                          className="businessType"
                          aria-label="business_type"
                          defaultValue="top">
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" required />}
                            label={t(
                              'BusinessForm.chooseYourBusinessTypeStore',
                            )}
                            name="business_type"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio color="primary" required />}
                            label={t(
                              'BusinessForm.chooseYourBusinessTypeRestaurant',
                            )}
                            name="business_type"
                          />
                        </RadioGroup>
                      </InnerBusinessFormBox>
                    </InnerBusinessFormBox>
                    <InnerBusinessFormBox className="businessTypeCategoryDiv">
                      <InputLabel>{t('BusinessForm.category')}</InputLabel>
                      <FormControl
                        variant="outlined"
                        className="categorySelect">
                        <Select
                          value={storeCategory}
                          onChange={handleChangeStoreCategory}
                          name="business_category"
                          required>
                          <MenuItem value="Select" disabled>
                            <em>{t('BusinessForm.categorySelect.select')}</em>
                          </MenuItem>
                          <MenuItem value="5">
                            {t('BusinessForm.categorySelect.Breweries')}
                          </MenuItem>
                          <MenuItem value="4">
                            {t('BusinessForm.categorySelect.Pharmacies')}
                          </MenuItem>
                          <MenuItem value="3">
                            {t('BusinessForm.categorySelect.Groceries')}
                          </MenuItem>
                          <MenuItem value="2">
                            {t('BusinessForm.categorySelect.CandyShops')}
                          </MenuItem>
                          <MenuItem value="1">
                            {t('BusinessForm.categorySelect.ButcherShops')}
                          </MenuItem>
                          <MenuItem value="6">
                            {t('BusinessForm.categorySelect.Restaurants')}
                          </MenuItem>
                          <MenuItem value="7">
                            {t('BusinessForm.categorySelect.Bakery')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </InnerBusinessFormBox>
                  </BusinessFormBox>
                </InnerBusinessFormBox>
              </BusinessFormBox>

              <BusinessFormBox>
                <InnerBusinessFormBox>
                  <InputLabel>{t('BusinessFormBS.BSname')}</InputLabel>
                  <TextFieldWrapBusinessForm>
                    <TextField
                      type="text"
                      placeholder={t('BusinessFormBS.BSname')}
                      name="bsName"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  </TextFieldWrapBusinessForm>
                </InnerBusinessFormBox>
                <InnerBusinessFormBox></InnerBusinessFormBox>
              </BusinessFormBox>

              <BusinessFormBox>
                <InnerBusinessFormBox>
                  <InputLabel>
                    {t('BusinessForm.deliveryMethod.title')}
                  </InputLabel>
                  <TextFieldWrapBusinessForm>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <FormControlLabel
                          value={pickupChecked}
                          control={<Checkbox color="primary" />}
                          label={t('BusinessForm.deliveryMethod.pickup')}
                          labelPlacement="end"
                          onChange={handlePickupChange}
                          name="isPickup"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          name="pickup_minimum_purchase_amount"
                          variant="outlined"
                          autoComplete="off"
                          required
                          className="yourDeliveryAreaClass"
                          placeholder="$0.00"
                        />
                      </Grid>
                      <Grid item className="yourDeliveryAreaClassMiles">
                        <b>
                          {t(
                            'BusinessForm.deliveryMethod.minimumPurchaseAmount',
                          )}
                        </b>
                      </Grid>
                    </Grid>
                  </TextFieldWrapBusinessForm>
                  <TextFieldWrapBusinessForm>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <FormControlLabel
                          value={deliveryChecked}
                          control={<Checkbox color="primary" />}
                          label={t('BusinessForm.deliveryMethod.delivery')}
                          labelPlacement="end"
                          onChange={handleDeliveryChange}
                          name="isDelivery"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          name="delivery_minimum_purchase_amount"
                          variant="outlined"
                          autoComplete="off"
                          required
                          className="yourDeliveryAreaClass"
                          placeholder="$0.00"
                        />
                      </Grid>
                      <Grid item className="yourDeliveryAreaClassMiles">
                        <b>
                          {t(
                            'BusinessForm.deliveryMethod.minimumPurchaseAmount',
                          )}
                        </b>
                      </Grid>
                    </Grid>
                  </TextFieldWrapBusinessForm>

                  <InputLabel>
                    {t('BusinessForm.deliveryArea.title')}
                  </InputLabel>
                  <TextFieldWrapBusinessForm>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 0, max: 10 } }}
                          name="distance_radius"
                          variant="outlined"
                          autoComplete="off"
                          required
                          className="yourDeliveryAreaClass"
                          placeholder="0"
                        />
                      </Grid>
                      <Grid item className="yourDeliveryAreaClassMiles">
                        {t('BusinessForm.deliveryArea.milesAround')}
                      </Grid>
                    </Grid>
                  </TextFieldWrapBusinessForm>
                </InnerBusinessFormBox>

                <ScheduleForm />
              </BusinessFormBox>

              <br />
              <br />

              <SignUpButton onSubmit={onSubmit}>
                {t('RegisterForm.button.register')}
              </SignUpButton>
            </Form>
            <p className="alreadyAccount">
              {t('RegisterForm.alreadyAccount.text')}&nbsp;
              <a href="/" className="loginAnchor">
                {t('RegisterForm.alreadyAccount.login')}
              </a>
            </p>
          </BoxAboutYou>
        </div>
      ) : null}
      {formSent ? (
        <ConfirmEmailAddress registeredEmail={registeredEmail} />
      ) : null}
      {openBackdrop ? (
        <Backdrop open className="backdropSignUp">
          <CircularProgress color="primary" size={60} thickness={7} />
        </Backdrop>
      ) : null}
    </>
  );
};
