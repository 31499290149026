import styled from 'styled-components';
import { colors } from '../../constants';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Figure = styled.figure`
  width: 300px;
`;
export const Img = styled.img`
  width: 100%;
`;

export const Title = styled.h1`
  color: ${colors.red};
  font-size: 36px;
`;

export const Subtitle = styled.h2`
  color: ${colors.black};
  font-size: 28px;
  margin: 12px 0;
`;

export const Message = styled.p`
  color: ${colors.gray};
  font-size: 24px;
  a {
    color: ${colors.red};
  }
`;
