import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  appBar: {
    width: 'calc(100% - 50px)',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawer: {
    width: 50,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 50,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
  },
  drawerContainer: {
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconSideBarStyled: {
    color: '#777777',
    fontSize: '1.3rem',
  },
}));
