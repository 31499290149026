import styled from 'styled-components';
import { TextField as Field } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { colors } from '../../constants';
import { Button } from '../../constants/base';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Button} {
    margin-top: 16px;
  }
`;

export const Anchor = styled(Link)`
  color: ${colors.gray};
  margin: 5px 0 10px 0;
  text-align: center;
  display: block;
  font-size: 0.75rem;
  align-self: flex-end;
`;

export const TextField = styled(Field)`
  width: 100%;
  margin: 8px 0 !important;
`;

export const ErrorText = styled.p`
  color: ${colors.red};
  text-align: center;
`;
