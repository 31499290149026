import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { colors, styles } from '../../constants';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonsWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  buttonCancel: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    color: '#000',
    backgroundColor: 'transparent',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
    marginBottom: '10px',
  },
  buttonDelete: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
    padding: '0 21px',
    height: '41px',
    borderRadius: '15px',
    marginRight: '20px',
    marginBottom: '10px',
  },
  editPriceIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: '#c43c42',
    },
  },
}));

export const InvoiceAnchor = styled.a`
  color: ${colors.red};
  &:hover {
    cursor: pointer;
  }
`;

export const ProducThumbImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 120px;
  height: 120px;
  padding: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightgray};
  border-radius: 8px;
  box-shadow: ${styles.shadow};
  margin: auto;
  img {
    max-width: 100%;
    max-height: 100%;
    background-color: transparent;
  }
`;
