import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import { RestaurantOrdersTableTop } from './RestaurantOrdersTableTop';
import {
  useStyles,
  PaginatorContent,
} from './InfoRestaurantOrdersTable.styled';
import { RestaurantOrdersTableContent } from './RestaurantOrdersTableContent';
import { InfoRestaurantOrdersTableHead } from './InfoRestaurantOrdersTableHead';
import { LoadingRestaurantOrdersTable } from './LoadingRestaurantOrdersTable';
import InputSearchRestaurantOrdersTable from './InputSearchRestaurantOrdersTable';

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitedFilter = orderBy.split('.');
    if (a[splitedFilter[0]] === null || b[splitedFilter[0]] === null) {
      return 0;
    }

    if (
      b[splitedFilter[0]][splitedFilter[1]] <
      a[splitedFilter[0]][splitedFilter[1]]
    ) {
      return -1;
    }
    if (
      b[splitedFilter[0]][splitedFilter[1]] >
      a[splitedFilter[0]][splitedFilter[1]]
    ) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const InfoRestaurantOrdersTable = ({ loading, pageChange }) => {
  const classes = useStyles();
  const orders = useSelector((state) => state.orders);
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, page) => {
    event.preventDefault();
    pageChange(page);
  };

  const filteredOrders = orders.results;
  const pageCalc = orders.count / 50;
  const pagesCount = orders.count > 50 ? Math.ceil(pageCalc) : 1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <RestaurantOrdersTableTop />
        <InputSearchRestaurantOrdersTable />
        <TableContainer>
          <Table
            id="RestaurantOrdersTable"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            onRequestSort={handleRequestSort}>
            <InfoRestaurantOrdersTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={orders.results.length}
            />
            {orders.results.length && !loading ? (
              <TableBody>
                {stableSort(filteredOrders, getComparator(order, orderBy)).map(
                  (singleOrder, index) => (
                    <RestaurantOrdersTableContent
                      key={`${singleOrder.reference}-${index}`}
                      index={index}
                      singleOrder={singleOrder}
                    />
                  ),
                )}
              </TableBody>
            ) : (
              <LoadingRestaurantOrdersTable loading={loading} />
            )}
          </Table>
        </TableContainer>
        <PaginatorContent>
          <Pagination count={pagesCount} onChange={handlePageChange} />
        </PaginatorContent>
      </Paper>
    </div>
  );
};

InfoRestaurantOrdersTable.propTypes = {
  loading: PropTypes.bool,
  pageChange: PropTypes.func,
};

InfoRestaurantOrdersTable.defaultProps = {
  loading: false,
  pageChange: () => {},
};
