import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLayout } from '../../shared/BaseLayout/BaseLayout';
import { Container, Square, Left, Right } from './Entry.styled';
import { Title } from '../../constants/base';
import { Login } from '../../components/Login/Login';

export const Entry = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout>
      <Container>
        <Square>
          <Left>
            <Title>{t('Entry.text')}</Title>
          </Left>
          <Right>
            <Login />
          </Right>
        </Square>
      </Container>
    </BaseLayout>
  );
};
