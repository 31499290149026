import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    id: 1,
    title: 'Post Title 1',
    description: 'Lorem ipsun dolor sit amet...',
    createdAt: '2021-03-17T02:15:29.394Z',
    image:
      'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/e35/120064134_3520672017965086_398253438403979934_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=cgXZRoVv3MQAX9S7Ay3&edm=AP_V10EAAAAA&ccb=7-4&oh=88ff734cd3d24a9e6ea7c9f52d437edf&oe=60A1771F&_nc_sid=4f375e',
    links: {
      facebook:
        'https://www.facebook.com/ShapYaUS/photos/a.101760018291628/180399367094359/',
      instagram: 'https://www.instagram.com/p/CLpDjQBnmEu/',
    },
  },
  {
    id: 2,
    title: 'Post Title 2',
    description: 'Lorem ipsun dolor sit amet...',
    createdAt: '2021-03-17T02:15:29.394Z',
    image:
      'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/e35/120064134_3520672017965086_398253438403979934_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=cgXZRoVv3MQAX9S7Ay3&edm=AP_V10EAAAAA&ccb=7-4&oh=88ff734cd3d24a9e6ea7c9f52d437edf&oe=60A1771F&_nc_sid=4f375e',
    links: {
      facebook:
        'https://www.facebook.com/ShapYaUS/photos/a.101760018291628/180399367094359/',
      instagram: 'https://www.instagram.com/p/CLpDjQBnmEu/',
    },
  },
  {
    id: 3,
    title: 'Post Title 3',
    description: 'Lorem ipsun dolor sit amet...',
    createdAt: '2021-03-17T02:15:29.394Z',
    image:
      'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/e35/120064134_3520672017965086_398253438403979934_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=cgXZRoVv3MQAX9S7Ay3&edm=AP_V10EAAAAA&ccb=7-4&oh=88ff734cd3d24a9e6ea7c9f52d437edf&oe=60A1771F&_nc_sid=4f375e',
    links: {
      facebook:
        'https://www.facebook.com/ShapYaUS/photos/a.101760018291628/180399367094359/',
      instagram: 'https://www.instagram.com/p/CLpDjQBnmEu/',
    },
  },
  {
    id: 4,
    title: 'Post Title 4',
    description: 'Lorem ipsun dolor sit amet...',
    createdAt: '2021-03-17T02:15:29.394Z',
    image:
      'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/e35/120064134_3520672017965086_398253438403979934_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=cgXZRoVv3MQAX9S7Ay3&edm=AP_V10EAAAAA&ccb=7-4&oh=88ff734cd3d24a9e6ea7c9f52d437edf&oe=60A1771F&_nc_sid=4f375e',
    links: {
      facebook:
        'https://www.facebook.com/ShapYaUS/photos/a.101760018291628/180399367094359/',
      instagram: 'https://www.instagram.com/p/CLpDjQBnmEu/',
    },
  },
  {
    id: 5,
    title: 'Post Title 5',
    description: 'Lorem ipsun dolor sit amet...',
    createdAt: '2021-03-17T02:15:29.394Z',
    image:
      'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/e35/120064134_3520672017965086_398253438403979934_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=cgXZRoVv3MQAX9S7Ay3&edm=AP_V10EAAAAA&ccb=7-4&oh=88ff734cd3d24a9e6ea7c9f52d437edf&oe=60A1771F&_nc_sid=4f375e',
    links: {
      facebook:
        'https://www.facebook.com/ShapYaUS/photos/a.101760018291628/180399367094359/',
      instagram: 'https://www.instagram.com/p/CLpDjQBnmEu/',
    },
  },
  {
    id: 6,
    title: 'Post Title 6',
    description: 'Lorem ipsun dolor sit amet...',
    createdAt: '2021-03-17T02:15:29.394Z',
    image:
      'https://instagram.fbog3-1.fna.fbcdn.net/v/t51.2885-15/e35/120064134_3520672017965086_398253438403979934_n.jpg?tp=1&_nc_ht=instagram.fbog3-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=cgXZRoVv3MQAX9S7Ay3&edm=AP_V10EAAAAA&ccb=7-4&oh=88ff734cd3d24a9e6ea7c9f52d437edf&oe=60A1771F&_nc_sid=4f375e',
    links: {
      facebook:
        'https://www.facebook.com/ShapYaUS/photos/a.101760018291628/180399367094359/',
      instagram: 'https://www.instagram.com/p/CLpDjQBnmEu/',
    },
  },
];

const digitalPublicationsSlice = createSlice({
  name: 'digitalPublications',
  initialState,
  reducers: {
    setPublications(_, { payload }) {
      return payload;
    },
    createPublication(state, { payload }) {
      return state.concat(payload);
    },
    editPublication(state, { payload }) {
      const publications = [];

      for (let i = 0; i <= state.length - 1; i++) {
        if (state[i].id === payload.id) {
          publications.push(payload);
          continue;
        }
        publications.push(state[i]);
      }
      return publications;
    },
    deletePublication(state, { payload }) {
      const publications = state.filter(
        (publication) => publication.id !== payload,
      );
      return publications;
    },
  },
});

export const {
  setPublications,
  createPublication,
  editPublication,
  deletePublication,
} = digitalPublicationsSlice.actions;

export default digitalPublicationsSlice.reducer;
