import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeSection: 'dashboard',
  features: {
    showPublications: false,
    manageShoppers: true,
    managePickUp: true,
    manageDelivery: true,
    managePublications: false,
    manageSuscription: true,
    manageCoupons: true,
    manageInventory: true,
    manageRestaurantOrders: true,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    changeSection(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    modifyFeatures(state, { payload }) {
      return {
        activeSection: state.activeSection,
        features: {
          ...state.features,
          ...payload,
        },
      };
    },
  },
});

export const { changeSection, modifyFeatures } = uiSlice.actions;

export default uiSlice.reducer;
