import styled from 'styled-components';
import { colors, styles, breakpoints } from '../../constants';

export const SquaresWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const Square = styled.div`
  background-color: ${colors.white};
  box-shadow: ${styles.shadow};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  min-width: 100%;
  margin-bottom: 1rem;
  max-width: 380px;
  width: 100%;
  @media ${breakpoints.tablet} {
    min-width: 200px;
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
  @media ${breakpoints.laptop} {
    min-width: 250px;
    min-height: 100px;
  }
`;

export const SquareTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: bold;
`;

export const SquareContent = styled.p`
  font-size: 1rem;
`;
