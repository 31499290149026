import { getRequest, postRequest, putRequest } from '../services/http';

export const getRestaurantOrders = async (data) => {
  if (data.page) {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/restaurant-orders-simplified/?page=${data.page}`,
      token: data.token,
    });
    return response;
  } else {
    const response = await getRequest({
      url: `user/branch-stores/${data.id}/restaurant-orders-simplified/`,
      token: data.token,
    });

    return response;
  }
};

export const getOrderProductsRestaurant = async (data) => {
  const response = await getRequest({
    url: `user/branch-stores/${data.id}/restaurant-orders/${data.idOrderRest}/`,
    token: data.token,
  });

  return response;
};

export const confirmOrderRestaurant = async (data) => {
  const response = await postRequest({
    url: `user/branch-stores/${data.id}/restaurant-orders/${data.idOrderRest}/`,
    token: data.token,
  });

  return response;
};

export const cancelOrderRestaurant = async (data) => {
  const response = await putRequest({
    url: `user/branch-stores/${data.id}/restaurant-orders/${data.idOrderRest}/`,
    data: data.params,
    token: data.token,
  });

  return response;
};
