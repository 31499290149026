import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import dayjs from 'dayjs';
import CloudDownloadRounded from '@material-ui/icons/CloudDownloadRounded';
import { InvoiceAnchor } from './TableContent.styled';

export const TableContent = ({ singleOrder, index }) => {
  const labelId = `enhanced-table-checkbox-${index}`;
  const symbol = singleOrder.orderCost.currency.symbol;

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" id={labelId} scope="row">
        <strong>{singleOrder.reference}</strong>
      </TableCell>
      <TableCell align="left">
        {dayjs(new Date(singleOrder.orderInitAt)).format('MMMM DD - YYYY')}
      </TableCell>
      <TableCell align="left">{`${symbol} ${singleOrder.orderCost.productsCost.toFixed(
        2,
      )}`}</TableCell>
      <TableCell align="left">{`${symbol} ${singleOrder.orderCost.commission.toFixed(
        2,
      )}`}</TableCell>
      <TableCell align="left">{`${symbol} ${singleOrder.orderCost.tax.toFixed(
        2,
      )}`}</TableCell>
      <TableCell align="left">
        {singleOrder.status && singleOrder.status.description
          ? singleOrder.status.description
          : ''}
      </TableCell>
      <TableCell align="left">{singleOrder.productsCount}</TableCell>
      <TableCell align="left">{singleOrder.orderType}</TableCell>
      <TableCell align="right">
        {singleOrder.orderCost.receipt ? (
          <InvoiceAnchor
            href={singleOrder.orderCost.receipt}
            download
            target="_blank">
            <CloudDownloadRounded />
          </InvoiceAnchor>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

TableContent.propTypes = {
  singleOrder: PropTypes.shape({
    reference: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    orderCost: PropTypes.shape({
      productsCost: PropTypes.number,
      commission: PropTypes.number,
      tax: PropTypes.number,
      currency: PropTypes.shape({
        symbol: PropTypes.string,
      }),
      receipt: PropTypes.string,
    }),
    status: PropTypes.shape({
      description: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    orderInitAt: PropTypes.string,
    productsCount: PropTypes.number,
    orderType: PropTypes.string,
  }),
  index: PropTypes.number,
};
