import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 2,
  name: 'Interfood Plaza Latina - Raleigh',
  store: {
    name: 'Interfood Plaza Latina',
    description: 'Latinos Supermarket',
    logo: 'https://shapyadevelop.s3.amazonaws.com/media/logos/stores/Interfood_1.png',
    storeType: {
      id: 3,
      name: 'Groceries',
      highlighted: true,
      logo: 'https://shapyadevelop.s3.amazonaws.com/media/logos/storetype/Groceries.png',
      color: '#C6CF3D',
    },
  },
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setStore } = storeSlice.actions;

export default storeSlice.reducer;
