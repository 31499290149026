import React from 'react';
import PropTypes, { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  SquaresWrapper,
  Square,
  SquareTitle,
  SquareContent,
} from './OrderOverview.styled';

export const OrderOverview = (props) => {
  const { t } = useTranslation();

  return (
    <SquaresWrapper>
      <Square>
        <SquareTitle>
          {t('OrderConfirmation.OrderOverview.reference')}
        </SquareTitle>
        <SquareContent>{props.reference}</SquareContent>
      </Square>
      <Square>
        <SquareTitle>{t('OrderConfirmation.OrderOverview.client')}</SquareTitle>
        <SquareContent>{`${props.client.user.firstName} ${props.client.user.lastName}`}</SquareContent>
      </Square>
      <Square>
        <SquareTitle>
          {t('OrderConfirmation.OrderOverview.delivery')}
        </SquareTitle>
        {props.deliveryAddress && (
          <SquareContent>
            {`${props.deliveryAddress.address.address} - ${props.deliveryAddress.address.city}, ${props.deliveryAddress.address.state}`}
          </SquareContent>
        )}
      </Square>
      <Square>
        <SquareTitle>
          {t('OrderConfirmation.OrderOverview.pricing')}
        </SquareTitle>
        <SquareContent>{`${
          props.orderCost.currency.symbol
        } ${props.orderCost.productsCostPlusTaxes.toFixed(2)}`}</SquareContent>
      </Square>
      <Square>
        <SquareTitle>{t('OrderConfirmation.OrderOverview.status')}</SquareTitle>
        <SquareContent>{`${props.status.description.replace(
          '_',
          ' ',
        )}`}</SquareContent>
      </Square>
    </SquaresWrapper>
  );
};

OrderOverview.propTypes = {
  reference: PropTypes.string,
  status: PropTypes.shape({
    description: PropTypes.string,
  }),
  client: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  deliveryAddress: PropTypes.shape({
    address: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
    }),
  }),
  orderCost: PropTypes.shape({
    currency: PropTypes.shape({
      symbol: PropTypes.string,
    }),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productsCostPlusTaxes: number,
  }),
};

OrderOverview.defaultProps = {
  reference: '',
  status: {
    description: '',
  },
  client: {
    user: {
      firstName: '',
      lastName: '',
    },
  },
  deliveryAddress: {
    address: {
      address: '',
      city: '',
      state: '',
    },
  },
};
