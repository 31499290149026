import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../constants';

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginLeft: '50px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${colors.lightgray}`,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    display: 'none',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileImgMenuItem: {
    justifyContent: 'center',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mobileLoggedAvatar: {
    maxHeight: '40px',
    width: 'auto',
    display: 'flex',
    alignSelf: 'center',
    userSelect: 'none',
  },
  loggedAvatar: {
    maxHeight: '40px',
    width: 'auto',
    display: 'flex',
    alignSelf: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
}));

export const Figure = styled.figure`
  width: 200px;
`;

export const Img = styled.img`
  width: 100%;
`;
