import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, Divider } from '@material-ui/core';
import { useStyles, Form } from './EditShopperDialog.js.styled';
import { Button } from '../../constants/base';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

export const EditShopperDialog = ({
  onSubmit,
  hideDialog,
  shopperFirstName,
  shopperLastName,
  shopperEmail,
  shopperActive,
  shopperJa,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [jobAssignment, setJobAssignment] = useState(shopperJa);
  const [active, setActive] = useState(shopperActive);
  const [jobAssignmentEdited, setJobAssignmentEdited] = useState(shopperJa);
  const [activeEdited, setActiveEdited] = useState(shopperActive);

  const handleChangeJobAssignment = (event) => {
    setJobAssignmentEdited(event.target.value);
  };

  const handleChangeActive = (event) => {
    setActiveEdited(event.target.value);
  };

  return (
    <Form onSubmit={onSubmit}>
      <DialogTitle id="alert-dialog-slide-title">
        {t('Shoppers.table.editShopper.title')}
      </DialogTitle>

      <DialogContent>
        <Box component="h3">
          <p>
            {shopperFirstName} {shopperLastName}
          </p>
          <p>{shopperEmail}</p>
        </Box>
        <br />
        <Box>
          <Divider />
        </Box>

        <div>
          <h3>{t('Shoppers.shoppersEditDialog.jobAssignment')}</h3>
        </div>
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="jobAssignmentRadioGroup"
              id="jobAssignmentRadioGroup"
              name="jobAssignmentRadioGroup"
              defaultValue={jobAssignment}
              onChange={handleChangeJobAssignment}>
              <FormControlLabel
                value="delivery"
                onClick={(e) => setJobAssignmentEdited(e.target.value)}
                control={
                  <Radio
                    icon={<span className={classes.icon} />}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                  />
                }
                label={
                  <span className={classes.radiolabel}>
                    {t('Shoppers.shoppersModal.delivery')}
                  </span>
                }
              />
              <FormControlLabel
                value="pickup"
                onClick={(e) => setJobAssignmentEdited(e.target.value)}
                control={
                  <Radio
                    icon={<span className={classes.icon} />}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                  />
                }
                label={
                  <span className={classes.radiolabel}>
                    {t('Shoppers.shoppersModal.pickup')}
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div>
          <Divider />
        </div>

        <div>
          <h3>{t('Shoppers.shoppersEditDialog.active')}</h3>
        </div>

        <div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="activeRadioGroup"
              id="activeRadioGroup"
              name="activeRadioGroup"
              defaultValue={active}
              onChange={handleChangeActive}>
              <FormControlLabel
                value="true"
                onClick={(e) => setActiveEdited(e.target.value)}
                control={
                  <Radio
                    color="primary"
                    icon={<span className={classes.icon} />}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                  />
                }
                label={
                  <span className={classes.radiolabel}>
                    {t('Shoppers.shoppersEditDialog.activeTrue')}
                  </span>
                }
              />
              <FormControlLabel
                value="false"
                onClick={(e) => setActiveEdited(e.target.value)}
                control={
                  <Radio
                    color="primary"
                    icon={<span className={classes.icon} />}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                  />
                }
                label={
                  <span className={classes.radiolabel}>
                    {t('Shoppers.shoppersEditDialog.activeFalse')}
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
        </div>

        <Box>
          <Divider />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className={classes.buttoncancel} onClick={hideDialog}>
          {t('Shoppers.shoppersEditDialog.button.cancel')}
        </Button>
        <Button className={classes.buttonupdate} type="submit">
          {t('Shoppers.shoppersEditDialog.button.update')}
        </Button>
      </DialogActions>
    </Form>
  );
};

EditShopperDialog.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  hideDialog: PropTypes.func,
  shopperFirstName: PropTypes.string,
  shopperLastName: PropTypes.string,
  shopperEmail: PropTypes.string,
  shopperActive: PropTypes.bool,
  shopperJa: PropTypes.oneOf(['pickup', 'delivery']),
};
