import styled from 'styled-components';
import { colors, styles } from '../../constants';

export const BtnShape = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  width: auto;
  height: 40px;
  border-radius: 4px;
  background-color: ${colors.red};
  border: none;
  &:hover {
    cursor: pointer;
    background-color: ${colors.lightgray};
    box-shadow: ${styles.shadow};
  }
  &:active {
    background-color: ${colors.gray};
    box-shadow: ${styles.shadow};
  }
  svg {
    color: ${colors.white};
  }
  :disabled {
    opacity: 0.4;
    user-select: none;
    pointer-events: none;
  }
`;

export const BtnText = styled.p`
  color: #fff;
  font-weight: 600;
`;
