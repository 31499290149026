import styled from 'styled-components';

export const ShoppersWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

export const TableWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
`;
