import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  results: [],
};

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setCoupons(_, { payload }) {
      return payload;
    },
    createCoupon(state, { payload }) {
      return {
        ...state,
        results: state.results.concat(payload),
      };
    },
    editCoupon(state, { payload }) {
      const coupons = [];

      for (let i = 0; i <= state.results.length - 1; i++) {
        if (state.results[i].id === payload.id) {
          coupons.push(payload);
          continue;
        }
        coupons.push(state.results[i]);
      }
      return {
        ...state,
        results: coupons,
      };
    },
    deleteCoupon(state, { payload }) {
      const coupons = state.results.filter((coupon) => coupon.id !== payload);
      return {
        ...state,
        results: coupons,
      };
    },
  },
});

export const { setCoupons, createCoupon, editCoupon, deleteCoupon } =
  couponsSlice.actions;

export default couponsSlice.reducer;
